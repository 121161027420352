
import React, { useState } from 'react';
import InputBox from '../../Components/input_box';
import Button from '../../Components/button';
import Sidebar from '../../Components/main_navbar';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import { createProduct } from '../../../Controller/product_service';
import { showToast, Toast } from '../../Components/toast'; // Import the toast functions
import LoaderSpinner from '../../Components/loader_spinner'; // Import the LoaderSpinner component
import DeletePopup from '../../Components/delete_pop_up';
import TextArea from '../../Components/text_area';
import Modal from '../../Components/pdf_opener';

const ProductService = () => {
  const [items, setItems] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const navigate = useNavigate();

  const handleAddProduct = () => {
    if (products.length >= 4) {
      showToast('You can only add up to 4 items at a time.', 'error');
      return;
    }
  
    const trimmedItem = items.trim();
  
    // Check for minimum length of 30 characters
    // if (trimmedItem.length < 30) {
    //   showToast('Product name must be at least 30 characters long.', 'error');
    //   return;
    // }
  
    if (trimmedItem !== '') {
      setProducts([...products, trimmedItem]);
      setItems('');
    } else {
      showToast('Please enter a product name.', 'error');
    }
  };
  

  const handleSave = async () => {
    if (products.length > 0) {
      setLoading(true);
      try {
        const response = await createProduct(products);
        console.log('add response', response);
        setProducts([]);
        localStorage.setItem('productsCompleted', 'true');
        navigate('/product_service_list');
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    } else {
      showToast('Please add at least one product.', 'error');
    }
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setShowPopup(true);
  };

  const handleConfirmDelete = () => {
    const newProducts = products.filter((_, i) => i !== deleteIndex);
    setProducts(newProducts);
    setShowPopup(false);
    showToast('Product removed successfully.', 'success');
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleBackClick = () => {
    navigate('/profile1');
  };

  const handlePdfClick = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="flex flex-col w-full h-full  bg-[#FBFCF8] font-poppins  overflow-auto ">
      <Sidebar />
      <Toast />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5 4k:w-[88%] ">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold  sm:text-lg lg:text-xl">Product / Service</h1>
        </div>
        <img
            src="/Assets/info2.svg"
            alt="Upload"
            title='Sop Document'
            className="cursor-pointer h-8 w-8 lg:mt-0  mr-5"
            onClick={handlePdfClick} 
          />
        {/* <ReportContainer /> */}
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px] bg-[#FBFCF8]"></div>
      <div className="lg:w-4/5  4k:w-[88%] md:w-3/4 lg:mt-10 mt-10 mx-4 overflow-auto ">
        <div className="lg:w-[45%] lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <p className="text-[#535353] lg:text-sm text-[12px] mb-4 font-poppins">
            Enter the name of the product or service you'd like to add (up to 4 items).
          </p>
          <div className="mt-7">
            <InputBox
              value={items}
              onChange={(e) => setItems(e.target.value)}
              placeholder="Product / Service name"
              borderRadius="1px"
              maxLength={50}
            />
            <Button
              onClick={handleAddProduct}
              text="Add"
              borderColor="border-red-600"
              textColor="text-red-600"
            />
          </div>
          <div className="mt-7">
            {products.map((product, index) => (
              <div key={index} className="bg-gray-200 p-2 mb-2 rounded flex justify-between items-center">
                <span className="font-normal poppins lg:text-[16px] text-[12px]">{product}</span>
                <img
                  src="Assets/bin.svg"
                  alt="delete icon"
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => handleDelete(index)}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center mt-40">
            <Button
              text="Save"
              onClick={handleSave}
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              fontWeight="font-bold"
              loading={loading}
            />
          </div>
        </div>
      </div>
      {showPopup && (
        <DeletePopup
          message="Are you sure you want to delete this product?"
          onConfirm={handleConfirmDelete}
          onClose={handleClosePopup}
        />
      )}

<Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="SOP Document">
        <iframe
          src="https://firebasestorage.googleapis.com/v0/b/eswaf-d4041.appspot.com/o/sop_document%2F6.SOP.pdf?alt=media&token=0c55676e-7e43-43c6-b8c9-d7420120f15b"
          title="SOP Document PDF"
          className="w-full h-full"
          frameBorder="0"
        ></iframe>
      </Modal>
    </div>
  );
};

export default ProductService;
