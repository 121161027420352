import React, { useState, useEffect, useCallback, useRef } from 'react';
import CompanyHeader from '../../Components/company_header';
import FilterTable from '../../Components/trade_selection_filter_table';
import CompanyCard from "../../Components/comapny_card";
import Sidebar from '../../Components/main_navbar';
import { get_sub_categories_api } from '../../../Controller/sub_categories_home';
import { useNavigate } from 'react-router-dom';
import { categoryMapping } from '../../Components/category';
import { FaSpinner } from 'react-icons/fa';

const MainPageSearch = ({ category = "Companies", hideLogo = false, hideFilterTable = false, hideSidebar = false }) => {
  const [filter, setFilter] = useState(0);
  const [search, setSearch] = useState('');
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isFilterTableOpen, setIsFilterTableOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  const navigate = useNavigate();
  const observer = useRef();

  const lastCompanyElementRef = useCallback(
    (node) => {
      if (loading || loadingMore || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          handleLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, loadingMore, hasMore]
  );
  

  const fetchCompaniesByCategory = async (selectedCategory, searchKey = '', latitude, longitude, skipCount) => {
    if (skipCount === 0) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    try {
      const response = await get_sub_categories_api(selectedCategory, skipCount, 5, latitude, longitude, searchKey);
      console.log('API Response:', response);
      if (skipCount === 0) {
        setCompanies(response.data);
      } else {
        setCompanies(prevCompanies => [...prevCompanies, ...response.data]);
      }
      setHasMore(response.data.length === 5);
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setSkip(0);
    fetchCompaniesByCategory(filter, search, lat, lon, 0);
  }, [filter, search, lat, lon]);

  const handleLoadMore = () => {
    if (!loadingMore && hasMore) {
      const nextSkip = skip + 5;
      setSkip(nextSkip);
      fetchCompaniesByCategory(filter, search, lat, lon, nextSkip);
    }
  };

  const handleCategorySelection = (selectedCategory) => {
    setFilter(selectedCategory);
    setSkip(0);
  };

  const toggleFilterTable = () => {
    setIsFilterTableOpen(!isFilterTableOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="h-screen flex flex-col bg-[#FBFCF8] font-poppins mt-2">
      <CompanyHeader 
        setSearch={setSearch} 
        hideLogo={hideLogo} 
        onLocationSelect={(latitude, longitude) => {
          setLat(latitude);
          setLon(longitude);
        }} 
      />

      <button className="md:hidden fixed top-2 left-4 z-50" onClick={toggleFilterTable}>
        <img src="./hamburger_icon.svg" alt="Filter Icon" className="h-8 w-8" />
      </button>

       
      <div className="flex-grow flex overflow-hidden">
        <div className="flex-grow flex flex-col md:flex-row w-full">
          <div className="md:w-1/2 flex flex-col p-4 h-full ">
            <h1 className="text-left font-poppins lg:text-xl  font-semibold leading-tight text-custom-red lg:mt-6  ml-4">
              {filter === 0 ? 'All Companies' : categoryMapping[filter]}
            </h1>

            <div className="flex-grow overflow-y-auto mt-4 h-[calc(100vh-200px)] md:h-auto ">
              {loading ? (
                <div className="flex justify-center items-center h-full">
                  <FaSpinner className="animate-spin text-custom-red text-3xl" />
                </div>
              ) : companies.length === 0 ? (
                <div className="flex justify-center items-center h-full">
                  <p className='text-gray-600'>No companies to show!</p>
                </div>
              ) : (
                <>
                  {companies.map((company, index) => (
                    <div
                      key={company.id}
                      className="w-full p-2"
                      onClick={() => navigate(`/profile/${company.id}`)}
                      ref={index === companies.length - 1 ? lastCompanyElementRef : null}
                    >
                      <CompanyCard company={company} />
                    </div>
                  ))}
                  {loadingMore && (
                    <div className="flex justify-center mt-4 mb-8">
                      <FaSpinner className="animate-spin text-custom-red text-2xl" />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {!hideFilterTable && (
            <div className="hidden md:block md:w-1/2 p-4 overflow-y-auto">
              <FilterTable filter={filter} setFilter={handleCategorySelection} />
            </div>
          )}
        </div>

        {!hideSidebar && (
        
            <Sidebar />
          
        )}
      </div>

      {isFilterTableOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 md:hidden">
          <div className="bg-white w-64 p-4 absolute left-0 h-full overflow-auto">
            <button className="text-right mb-4" onClick={toggleFilterTable}>
              <img src="/Assets/Close_round.svg" alt="Close Icon" className="h-6 w-6" />
            </button>
            <FilterTable filter={filter} setFilter={handleCategorySelection} isMobile={true} />
          </div>
        </div>
      )}

      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 md:hidden">
          <div className="bg-white w-64 p-4 absolute left-0 h-full overflow-auto">
            <button className="text-right mb-4" onClick={toggleMobileMenu}>
              <img src="/Assets/Close_round.svg" alt="Close Icon" className="h-6 w-6" />
            </button>
            <Sidebar />
          </div>
        </div>
      )}
    </div>
  );
};

export default MainPageSearch;