import React, { useRef, useState, useEffect } from 'react';
import { showToast } from './toast';
import UploadDocuments from '../../Controller/firebase/firebase_documents';
import { FaSpinner } from 'react-icons/fa';
import Modal from './pdf_opener';

const EditDocumentUpload = ({ label, imgSrc, disabled, existingFile, onFileUpload }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedFileURL, setUploadedFileURL] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  const renderFileName = (fileURL) => {
    try {
      const decodedURL = decodeURIComponent(fileURL);
      const fileName = decodedURL.split('/').pop().split('?')[0];
      return fileName;
    } catch (error) {
      console.error("Error decoding file URL:", error);
      return "Unknown file";
    }
  };

  useEffect(() => {
    if (existingFile) {
      console.log(`Initializing with existing file for ${label}:`, existingFile);
      setUploadedFileURL(existingFile);
      setUploadedFileName(renderFileName(existingFile));
    }
  }, [existingFile, label]);

  const handleImageClick = () => {
    if (!disabled && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChangeInternal = async (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 25 * 1024 * 1024; // 25 MB limit

    if (file) {
      console.log(`Selected file for ${label}:`, file.name);
      if (file.size <= fileSizeLimit && file.type === 'application/pdf') {
        setIsUploading(true);
        setUploadedFileName(file.name);

        try {
          const downloadURL = await UploadDocuments(file);
          console.log(`File uploaded successfully for ${label}:`, downloadURL);
          showToast("File uploaded successfully!", "success");

          // Update local state
          setUploadedFileURL(downloadURL);
          
          // Notify parent component about the new file
          if (onFileUpload) {
            onFileUpload(label, downloadURL);
          }
          
          // Update corresponding localStorage item based on label
          const storageKey = getStorageKeyFromLabel(label);
          if (storageKey) {
            localStorage.setItem(storageKey, downloadURL);
          }
          
        } catch (error) {
          console.error("Error uploading file:", error);
          showToast("Error uploading file. Please try again.", "error");
        } finally {
          setIsUploading(false);
        }
      } else {
        showToast("Please upload a PDF document under 25MB.", "error");
      }
    }
  };

  // Helper function to map labels to localStorage keys
  const getStorageKeyFromLabel = (label) => {
    const keyMap = {
      'Business License': 'trading_license',
      'Tenancy Contract': 'tenancy_contract',
      'Trading Register': 'trading_register'
    };
    return keyMap[label];
  };

  const handleFileClick = (fileURL) => {
    console.log(`Opening file in modal for ${label}:`, fileURL);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    console.log(`Closing modal for ${label}`);
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col mb-4 font-gotham">
      <label className="text-[#989898] mb-2">{label}</label>
      <div
        className={`bg-[#FAFAFA] border border-gray-300 p-8 rounded-lg text-center ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
        onClick={handleImageClick}
      >
        <div className="flex justify-center mb-4">
          {isUploading ? (
            <FaSpinner className="animate-spin text-gray-500 text-2xl" />
          ) : uploadedFileURL ? (
            <div className="flex flex-col items-center">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleFileClick(uploadedFileURL);
                }}
                className="text-[#DB0000] font-bold cursor-pointer flex items-center"
              >
                <img src="Assets/pdf.svg" alt="PDF Icon" className="mr-2 h-5 w-5" />
                {uploadedFileName}
              </span>
            </div>
          ) : (
            <img src={imgSrc} alt="Upload" className="h-10 w-10" />
          )}
        </div>
        <p className="text-base">Tap to view or upload a file</p>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChangeInternal}
          style={{ display: 'none' }}
          accept="application/pdf"
          disabled={disabled}
        />
        <p className="text-sm mt-2 text-gray-500">Single file size limit 25MB. File type: PDF</p>
      </div>

      {uploadedFileURL && (
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <iframe src={uploadedFileURL} frameBorder="0" width="100%" height="100%" title="Uploaded File"></iframe>
        </Modal>
      )}
    </div>
  );
};

export default EditDocumentUpload;