import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa'; // Import FA Spinner
import Button from '../../Components/button';
import CategorySelectList from '../../Components/category_select_list';
import InputBox from '../../Components/input_box';
import { Toast } from '../../Components/toast';
import CenteredImageContainer from '../../Components/black_container.jsx';
import ReportContainer from '../../Components/report.jsx';
import { fetchCategories } from '../../../Controller/sub_category_controller.js';
import { handleSaveCategory } from '../../../Controller/sub_category_controller.js';
import { showToast } from '../../Components/toast';

const CategorySelection = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryMap, setCategoryMap] = useState({});
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState('');
  const [loadingCategories, setLoadingCategories] = useState(true); // Loading for fetching categories
  const [loadingSave, setLoadingSave] = useState(false);
  const [headerText, setHeaderText] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { completed } = location.state || { completed: [] };

  // Fetch categories on mount
  useEffect(() => {
    fetchCategories(setCategories, setCategoryMap, setLoadingCategories); // Fetch categories and handle loading
  }, []);

  // Set header text based on the category in localStorage
  useEffect(() => {
    const category = localStorage.getItem('category_key');
    if (category) {
      setHeaderText(formatCategoryName(category));
    }
  }, []);

  // Format category name
  const formatCategoryName = (category) => {
    return category
      .split('_')
      .map((word) => {
        if (word === 'stones') {
          return 'Stones/';
        } else if (word === 'metals') {
          return 'Metals';
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
      })
      .join(' ');
  };

  // Handle category selection
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setShowOtherInput(option === 'other');

    if (option !== 'other') {
      const selectedId = categoryMap[option];
      if (selectedId) {
        localStorage.setItem('sub_category', selectedId);
      }
    }
  };

  // Handle custom input change
  const handleOtherInputChange = (event) => {
    setOtherInputValue(event.target.value);
  };

  // Handle save button click
  const handleButtonClick = async () => {
    setLoadingSave(true); // Start showing the loading spinner

    if (selectedOption === 'other') {
      if (!otherInputValue.trim()) {
        showToast('Please enter a value for Other', 'error');
        setLoadingSave(false); // Stop the spinner when validation fails
        return;
      }
      localStorage.setItem('sub_category', otherInputValue.trim()); // Save the custom input value
    }

    await handleSaveCategory(
      selectedOption,
      otherInputValue,
      categoryMap,
      navigate,
      setLoadingSave, // Make sure this state setter is correctly passed to handleSaveCategory
      completed
    );
  };

  // Navigate to /trade-selection
  const navigateToTradeSelection = () => {
    navigate('/trade-selection');
  };

  return (
    <div className="h-full w-full flex bg-[#FBFCF8] relative  ">
      <Toast />
      <div className="hidden lg:block w-full lg:w-1/2 h-full relative ">
        <div className="w-4/5 h-full">
          <CenteredImageContainer imgSrc="Assets/category_new.svg" />
        </div>
      </div>
      <div className="absolute top-5 right-6">
        <ReportContainer />
      </div>
      <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins relative ">
        {/* Back Arrow as an Image inside this div */}
        <button
          onClick={navigateToTradeSelection}
          className="absolute lg:top-7 top-5 left-4 lg:-left-24 z-10 flex items-center justify-center bg-transparent"
          aria-label="Go Back"
        >
          <img
            src="/Assets/arrow_back.svg"
            alt="Back"
            className="w-4 h-4 lg:w-5 lg:h-5"
          />
        </button> 
        <div className="p-6 w-full max-w-2/5 overflow-auto" style={{scrollbarWidth:"50px"}}>
          <h1 className="text-2xl font-bold text-black mb-4">{headerText}</h1>
          <p className="text-base lg:text-lg text-gray-600 mb-4">Choose a sub category!</p>

          {loadingCategories ? (
            <div className="flex justify-center items-center h-96">
              <FaSpinner className="animate-spin text-red-600 text-4xl" />
            </div>
          ) : (
            <>
              <CategorySelectList
                onChange={handleOptionChange}
                selectedOption={selectedOption}
                categories={categories}
              />
              {showOtherInput && (
                <div className="mt-7">
                  <InputBox
                    placeholder="Other"
                    padding="14px"
                    value={otherInputValue}
                    onChange={handleOtherInputChange}
                    className="mt-4"
                  />
                </div>
              )}
              <div className="mt-5">
                <Button
                  text="Save"
                  onClick={handleButtonClick}
                  backgroundColor="bg-[#DB0000]"
                  textColor="text-white"
                  fontWeight="font-bold"
                  loading={loadingSave}
                  className="w-full mt-6"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategorySelection;
