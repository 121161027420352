


import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { getCompanyReviews, deleteReview } from '../../../Controller/reviews';
import StarRating from '../../Components/star_rating';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import DeletePopup from '../../Components/delete_pop_up';
import ReportContainer from '../../Components/report';
import { FaSpinner } from 'react-icons/fa';
import ImageModal from '../../Components/image_modal';
import Modal from '../../Components/pdf_opener';

const Adding = () => {
  const { uid } = useParams(); // Extract uid from the URL params
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [overallRating, setOverallRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDeleteText, setShowDeleteText] = useState(null);
  const [isMyProfile, setIsMyProfile] = useState(false); // Check if it's the user's own profile
  const [loading, setLoading] = useState(true);
  const [fullImageUrl, setFullImageUrl] = useState(null); 
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
const [confirmationDocumentUrl, setConfirmationDocumentUrl] = useState(null);
const [isFetchingMore, setIsFetchingMore] = useState(false); // Loading state for fetching more reviews
const [skip, setSkip] = useState(0); // Track how many reviews have been fetched

  const loggedInCompanyUid = localStorage.getItem('company_uid'); // Get the logged-in user's company UID from localStorage
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(skip === 0); // Show initial loader only on the first fetch
        setIsFetchingMore(skip !== 0); // Show bottom loader only on subsequent fetches
        let companyUid = uid || loggedInCompanyUid;
        if (!companyUid) throw new Error("Company UID is not available.");

        const res = await getCompanyReviews(companyUid, skip, 10); // Fetch reviews one at a time
        if (res.status_code === 200) {
          const { rating_average, review_count, reviews: newReviews } = res.data;
          setOverallRating(rating_average);
          setReviewCount(review_count);
          setReviews((prevReviews) => [...prevReviews, ...newReviews]);
          setIsMyProfile(!uid);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
        setIsFetchingMore(false);
      }
    };

    fetchReviews();
  }, [uid, loggedInCompanyUid, skip]);

  const loadMoreReviews = () => {
    if (!isFetchingMore && reviews.length < reviewCount) {
      setSkip((prevSkip) => prevSkip + 10);
    }
  };

  // Add uid as a dependency to re-fetch when the URL changes
  const handleImageClick = (imageUrl) => {
    setFullImageUrl(imageUrl); // Set the image URL to display in the modal
  };

  const handleCloseImageModal = () => {
    setFullImageUrl(null); // Close the modal
  };


 
  

  const handleAddReview = () => {
    // Check if uid is available in the URL params or from localStorage
    const companyUid = uid || loggedInCompanyUid;
    
    if (companyUid) {
      // Navigate to add_review_page with the uid as a URL parameter
      navigate(`/add_review_page/${companyUid}`);
    } else {
      console.error("No Company UID found");
    }
  };
  
  const handleBackClick = () => {
    navigate('/');
  };

  const handleConfirmationDocumentClick = (documentUrl) => {
    setConfirmationDocumentUrl(documentUrl);
    setIsConfirmationModalOpen(true);
  };
  

  const handleDeleteClick = (reviewId) => {
    setSelectedReviewId(reviewId);
    setShowDeletePopup(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (selectedReviewId) {
        const res = await deleteReview(selectedReviewId);
        if (res.status_code === 202) {
          setReviews((prevReviews) => prevReviews.filter(review => review.id !== selectedReviewId));
        
        }
      }
    } catch (error) {
      console.error("Error deleting review:", error);
   
    } finally {
      setShowDeletePopup(false);
      setSelectedReviewId(null);
    }
  };

  const handleClosePopup = () => {
    setShowDeletePopup(false);
    setSelectedReviewId(null);
  };

  const handleThreeDotsClick = (reviewId) => {
    if (showDeleteText === reviewId) {
      setShowDeleteText(null);
    } else {
      setShowDeleteText(reviewId);
    }
  };
  const handleprofile = (companyId) => {
    navigate(`/profile/${companyId}`); // Navigate to the profile page with company_id
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
         
          <h1 className="text-black font-semibold text-xl">Reviews</h1>
        </div>
        {/* <ReportContainer /> */}
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col md:flex-row mt-4 mx-4 h-[calc(100vh-100px)]">
        <div className="bg-[#FFFFFF] lg:p-6 rounded-lg  w-full md:w-[300px]   flex flex-col items-center justify-center text-center mb-4 md:mb-0">
          <h1 className="text-6xl font-bold font-techna">{overallRating}</h1>
          <div className="stars mt-2">
            <StarRating rating={overallRating} />
          </div>
          <p className="text-[#263238] font-techna font-normal lg:text-[18px] sm:text-[16px] mt-2">
    {reviewCount === 0
      ? "Based on 0 Review"
      : `Based on ${reviewCount} ${reviewCount === 1 ? "Review" : "Reviews"}`}
  </p>

          {/* Hide "Add Review" button if it's not the user's own profile */}
          {!isMyProfile && (
            <Button
              text="Add Review"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleAddReview}
            />
          )}
        </div>

        <div className="mx-6 border-r-2 border-gray-200 hidden md:block"></div>

        <div className="bg-[#FFFFFF] lg:p-6 rounded-lg  w-full md:w-[600px] 4k:w-[50%] overflow-y-auto">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <FaSpinner className="animate-spin text-[#DB0000]" size={30} />
            </div>
          ) : (
            <>
              {reviews.length > 0 ? (
                reviews.map((review, index) => (
                  <div key={index} className="mb-6 border-b pb-4 flex items-start relative">
                    <img
                      src={review.reviewer_profile_image || 'default-avatar.png'}
                      alt="Reviewer"
                      className="w-12 h-12 rounded-full mr-4"
                      onClick={() => handleprofile(review.reviewer_company_id)}
                    />
                    <div className="flex-1 flex flex-col">
                      <div className="flex justify-between">
                        <div>
                          <h3 className="font-normal font-techna lg:text-[18px] sm:text-[14px] text-[#263238]">
                            {review.reviewer_company_name}
                          </h3>
                          <p className="text-sm text-[#232323]">{review.title}</p>
                        </div>
                      </div>
                      <p className="mt-2 text-[#232323] text-[12px] font-poppins font-normal lg:whitespace-normal lg:break-words lg:max-w-[450px]">
                        {review.description}
                      </p>
                      {review.image_url && review.image_url.length > 0 && (
                        <div className="mt-2">
                          {review.image_url.map((img, imgIndex) => (
                            <img
                              key={imgIndex}
                              src={img}
                              alt="Review"
                              className="w-20 h-20 object-cover mr-2 inline-block cursor-pointer"
                              onClick={() => handleImageClick(img)}
                            />
                          ))}
                        </div>
                      )}
                      {/* {review.confirmation_document && (
                        <div className="mt-2">
                          <p
                            onClick={() => handleConfirmationDocumentClick(review.confirmation_document)}
                            className="text-red-600 underline text-xs font-gotham cursor-pointer"
                          >
                            View Confirmation Document
                          </p>
                        </div>
                      )} */}

{(isMyProfile || review.reviewer === loggedInCompanyUid) && review.confirmation_document && (
  <div className="mt-2">
    <p
      onClick={() => handleConfirmationDocumentClick(review.confirmation_document)}
      className="text-red-600 underline text-xs font-gotham cursor-pointer"
    >
      View Confirmation Document
    </p>
  </div>
)}

                      <div className="mt-2 flex justify-between">
                        <StarRating rating={review.rating} />
                        <span className="lg:text-[13px] text-[10px] text-[#232323] self-end">
                          {new Date(review.updated_on).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    {review.reviewer === loggedInCompanyUid && (
                      <div className="absolute top-0 right-0">
                        <img
                          src="/Assets/dots.svg"
                          alt="Options"
                          className="cursor-pointer h-5 w-5"
                          onClick={() => handleThreeDotsClick(review.id)}
                        />
                        {showDeleteText === review.id && (
                          <div className="absolute right-0 mt-2 p-2 bg-white  border rounded">
                            <p className="cursor-pointer text-red-600" onClick={() => handleDeleteClick(review.id)}>
                              Delete
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p className="text-center">No reviews to show.</p>
                </div>
              )}
              
              {isFetchingMore && (
            <div className="flex justify-center items-center my-4">
              <FaSpinner className="animate-spin text-[#DB0000]" size={24} />
            </div>
          )}
          {!loading && reviews.length < reviewCount && (
             <div className="flex justify-center items-center ">
            <button onClick={loadMoreReviews} className="mt-4 p-2 bg-[#DB0000] text-white rounded ">
              Load More
            </button>
            </div>
          )}

            </>
          )}
        </div>
      </div>

      {fullImageUrl && <ImageModal imageUrl={fullImageUrl} onClose={handleCloseImageModal} />}
      {showDeletePopup && (
        <DeletePopup
          message="Are you sure you want to delete this review?"
          onConfirm={handleConfirmDelete}
          onClose={handleClosePopup}
        />
      )}
      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title="Confirmation Document"
      >
        <iframe
          src={confirmationDocumentUrl}
          className="w-full h-full"
          title="Confirmation Document"
        />
      </Modal>
    </div>
    
  );
};

export default Adding;

