import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GoldSupplier from '../../Components/gold_supplier';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import { get_subscriptions_api, post_user_payments_api } from '../../../Controller/subscriptions';
import { FaSpinner } from 'react-icons/fa';
import { showToast } from '../../Components/toast';
const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  let product_id = state?.product_id || sessionStorage.getItem('product_id');
  const backgroundColor = state?.backgroundColor || "#DB0000";

  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState(false);

  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        const response = await get_subscriptions_api();
        if (response.status === 200) {
          const selectedPlan = response.data.data.find(p => p.product_id === product_id);
          setPlan(selectedPlan);
        }
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        showToast('Failed to fetch subscription details.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchPlanDetails();
  }, [product_id]);

  const handlePayNowClick = async () => {
    if (!product_id) {
      // Show toast notification if product_id is not available
      showToast('Product ID not found. Please select a valid product.', 'error');
      return;
    }

    setPaying(true);
    try {
      const paymentResponse = await post_user_payments_api(product_id);
      if (paymentResponse.status === 200) {
        const checkoutUrl = paymentResponse.data.data.url;
        window.location.href = checkoutUrl;
      } else {
        console.error('Failed to post user payment');
      }
    } catch (error) {
      console.error('Error posting user payment:', error);
    } finally {
      setPaying(false);
    }
  };

  // Render loading spinner
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="text-xl animate-spin" />
      </div>
    );
  }

  // Determine text color based on plan name
  const isMonthlySupplier = plan?.name?.trim().toLowerCase() === 'monthly';
  const textColor = isMonthlySupplier ? '#171717' : '#F9F9F9';

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
    <Sidebar />
    <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
      <div className="w-full flex justify-start items-center space-x-2">
        <img
          src="/Assets/arrow_back.svg"
          alt="Back"
          className="cursor-pointer mr-2 h-6 w-6"
          onClick={() => navigate('/subscription_plans')}
        />
        <h1 className="text-black font-bold sm:text-lg lg:text-xl">Subscription</h1>
      </div>
    </div>
    <div className="border-b border-black w-full mt-4 md:mt-[25px] "></div>
  
    <div className="flex justify-center w-full mx-auto mt-7 overflow-auto">
      <div className="w-4/5 md:w-[550px]">
        <div className="bg-[#F0F0F0] p-4">
        {plan && (
  <GoldSupplier
    backgroundColor="#DB0000"
    textColor={textColor}
    title={plan.name || "Plan Name Not Available"} // Display placeholder if name is missing
    subtitle=""
    price={`${plan.price || 0}.0 $`} // Default price to 0 if missing
    height="150px"
    width="full"
  />
)}

  
          {/* Subscription Features */}
          <ul className="font-normal mt-4 ml-7">
            {[
              'Exclusive Discounts',
              'Free Shipping',
              'Early Access to Sales',
              'Personalized Recommendations',
              'Dedicated Customer Support',
              'Ad-Free Experience',
              'Content Access',
              'Early Product Testing',
            ].map((feature, index) => (
              <li key={index} className="mb-3 flex items-center">
                <img
                  src="/Assets/crown.svg"
                  alt="Crown"
                  className="w-4 h-4 mr-2"
                />
                {feature}
              </li>
            ))}
          </ul>
  
        
        </div>
  
        {/* Pay Now Button */}
        <div className="mt-5">
          <Button
            text="Pay Now"
            backgroundColor="bg-[#DB0000]"
            textColor="text-white"
            onClick={handlePayNowClick}
            loading={paying}
            className="w-full"
          />
        </div>
      </div>
    </div>
  </div>
  

  );
};

export default SubscriptionDetails;
