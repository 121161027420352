

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import LogoUploader from '../../Components/circle_avatar';
import ReportContainer from '../../Components/report';
import { getmembersbyuid, deleteManagementMember } from '../../../Controller/management';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaEdit, FaPlus } from 'react-icons/fa';
import DeletePopup from '../../Components/delete_pop_up';
import { FaSpinner } from 'react-icons/fa';
import { useRef } from 'react';


const ViewManagement = () => {
  const { uid } = useParams(); // Get the uid from the URL params if available
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [isMyProfile, setIsMyProfile] = useState(false); // Determine if viewing own profile
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const limit = 5; // Changed to 5 as per requirement
  const observerRef = useRef(null);
  

  // // Fetch members based on uid or company_uid
  // useEffect(() => {
  //   const fetchMembers = async () => {
  //     try {
  //       const company_uid = localStorage.getItem('company_uid'); // Get company_uid from local storage
        
  //       // Determine if viewing own profile or another's
  //       if (uid) {
  //         setIsMyProfile(uid === company_uid); // If uid in URL matches company_uid, it's the user's own profile
  //       } else {
  //         setIsMyProfile(true); // No uid in URL, viewing own profile
  //       }

  //       const targetUid = uid || company_uid; // Use uid from URL or fallback to company_uid
  //       const response = await getmembersbyuid(targetUid, skip, limit); // Fetch members by the determined uid
  //       console.log('API Response:', response);
        
  //       if (response && response.data && Array.isArray(response.data)) {
  //         setProfiles(response.data); // Update profiles
  //       } else {
  //         console.error('Unexpected response format:', response);
  //         setProfiles([]);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching members:', error);
  //     }
  //     finally {
  //       setIsLoading(false); // Stop loading once data is fetched
         

  //     }
  //   };

  //   fetchMembers();
  // }, [skip, limit, uid]);

  // const handleEdit = (profile) => {
  //   navigate('/add_management', { state: { profile } });
  // };

  // const handleDeleteClick = (profileId) => {
  //   setProfileToDelete(profileId);
  //   setShowDeletePopup(true);
  // };

  // const handleDeleteConfirm = async () => {
  //   try {
  //     await deleteManagementMember(profileToDelete);
  //     setProfiles(profiles.filter(profile => profile.id !== profileToDelete)); // Remove deleted profile from list
  //     setShowDeletePopup(false);
  //     setProfileToDelete(null);
  //   } catch (error) {
  //     console.error('Error deleting member:', error);
  //   }
  // };

  // const handleDeleteCancel = () => {
  //   setShowDeletePopup(false);
  //   setProfileToDelete(null);
  // };

  // const handleAddNew = () => {
  //   navigate('/add_management');
  // };


  const fetchMembers = async (skipValue) => {
    try {
      const company_uid = localStorage.getItem('company_uid');
      
      if (uid) {
        setIsMyProfile(uid === company_uid);
      } else {
        setIsMyProfile(true);
      }

      const targetUid = uid || company_uid;
      const response = await getmembersbyuid(targetUid, skipValue, limit);
      
      if (response && response.data && Array.isArray(response.data)) {
        if (skipValue === 0) {
          setProfiles(response.data);
        } else {
          setProfiles(prev => [...prev, ...response.data]);
        }
        setHasMore(response.data.length === limit);
      } else {
        console.error('Unexpected response format:', response);
        if (skipValue === 0) {
          setProfiles([]);
        }
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching members:', error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
      setIsFetchingMore(false);
    }
  };

  useEffect(() => {
    fetchMembers(0);
  }, [uid]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading && !isFetchingMore) {
          setIsFetchingMore(true);
          setSkip(prev => prev + limit);
          fetchMembers(skip + limit);
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [hasMore, isLoading, isFetchingMore, skip]);

  const handleEdit = (profile) => {
    navigate('/add_management', { state: { profile } });
  };

  const handleDeleteClick = (profileId) => {
    setProfileToDelete(profileId);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteManagementMember(profileToDelete);
      setProfiles(profiles.filter(profile => profile.id !== profileToDelete));
      setShowDeletePopup(false);
      setProfileToDelete(null);
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
    setProfileToDelete(null);
  };

  const handleAddNew = () => {
    navigate('/add_management');
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8] font-poppins overflow-auto">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-black font-semibold text-xl">Management</h1>
        </div>
        {/* <ReportContainer /> */}
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="grid grid-cols-2 md:grid-cols-6 gap-2 mt-7 lg:w-4/5  w-full  ">
        {isLoading ? (
          <div className="flex  justify-center items-center w-screen h-screen">
            <FaSpinner className="animate-spin text-gray-500 text-2xl " /> {/* Spinner */}
          </div>
        ) : (
          profiles.map((profile) => (
            <div key={profile.id} className="flex flex-col items-center relative mt-4">
              {/* Always show the LogoUploader for all profiles */}
              <LogoUploader 
                logo={profile.logo || profile.image_url}
                isEditable={false} // Allow editing only if it's the user's own profile
                showEditIcon={isMyProfile} // Show edit icon only for own profile
              />
            <p className="text-center text-black mt-2 text-[12px] lg:text-[14px] lg:w-44 w-36 overflow-hidden text-ellipsis whitespace-nowrap">
    {profile.name}<br />
    <span className="block overflow-hidden text-ellipsis whitespace-nowrap ">{profile.position}</span>
  </p>

               
            
  <div className="flex space-x-2 mt-2">
                {profile.linkedin_url && (
                  <button 
                    className="bg-[#DB0000] p-2 rounded"
                    onClick={() => window.open(profile.linkedin_url, '_blank')}
                  >
                    <img src="/Assets/linkedin_icon.svg" alt="LinkedIn" className="w-5 h-5" />
                  </button>
                )}
                {isMyProfile && (
                  <button 
                    className="bg-[#DB0000] p-2 rounded"
                    onClick={() => handleEdit(profile)}
                  >
                   <img src="/Assets/post_request.svg" alt="LinkedIn" className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>
          ))
        )}
       {isMyProfile && !isLoading && (
          <div className="flex flex-col items-center justify-center cursor-pointer" onClick={handleAddNew}>
            <div className="w-28 h-28 rounded-full bg-gray-200 flex items-center justify-center mb-20">
              <FaPlus className="text-gray-500 w-4 h-4" />
            </div>
          </div>
        )}
      </div>

      {isFetchingMore && (
        <div className="flex justify-center my-4">
          <FaSpinner className="animate-spin text-gray-500 text-2xl" />
        </div>
      )}
      
      <div ref={observerRef} style={{ height: '1px' }} />

      {showDeletePopup && (
        <DeletePopup 
          message="Are you sure you want to delete this management member?" 
          onConfirm={handleDeleteConfirm} 
          onClose={handleDeleteCancel} 
        />
      )}
    </div>
  );
};

export default ViewManagement;
