
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Steps = ({ number, text, destination, backgroundColor, hoverColor }) => {
  return (
    <Link 
      to={destination} 
      className="flex items-center border-b p-3 lg:p-6 font-poppins font-normal rounded-lg text-[#232323] min-h-[80px]"
      style={{
        backgroundColor,
        transition: 'background-color 0.3s ease', // Smooth transition effect
      }}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverColor} // Change background color on hover
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = backgroundColor} // Revert background color when not hovered
    >
      <div className="flex-shrink-0 text-4xl font-bold text-[#DB0000]">{number}</div>
      <div className="flex-grow mx-4">
        <p className="text-[#232323] font-semibold lg:text-[16px] text-[14px]">{text}</p>
      </div>
      <div className="flex-shrink-0">
        <img 
          src="Assets/arrow_forward.svg" 
          alt="Arrow Icon" 
          className="w-6 h-6 cursor-pointer" 
        />
      </div>
    </Link>
  );
};

Steps.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string, // Prop for background color
  hoverColor: PropTypes.string, // Prop for hover color
};

Steps.defaultProps = {
  backgroundColor: '#F9F9F9', // Default background color
  hoverColor: '#D9D9D9', // Default hover color
};

export default Steps;
