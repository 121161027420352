import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import './preloader.css';
import EswafFont from './Eswaffont.svg'; 
import Eswaflargefont from './Eswaflargefont.svg'; 


const Eswaf = () => {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const handlePageLoad = () => {
      const images = document.querySelectorAll('img');
      const promises = Array.from(images).map((img) => {
        return new Promise((resolve) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = img.onerror = resolve;
          }
        });
      });

      Promise.all(promises).then(() => {
        // All assets are loaded, hide the preloader
        setLoading(false);
      });
    };

    // Call when the component mounts
    handlePageLoad();
  }, []);
  
  return (

    <>
    {/* Inline Preloader CSS */}
  

    {/* Preloader */}
     {loading && (
        <div className="loader-container">
          <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}


    
    <div className="relative h-full overflow-y-auto scroll-snap-type-y-mandatory">
  {/* Default (Small Screen) Background */}
  <div
    className="h-full w-full bg-cover bg-center"
    style={{
      backgroundImage: `url('/Assets/Mobile view.svg')`, //
    }}
  >
    {/* Header for Small Screens */}
   
    {/* <header className="md:hidden flex bg-transparent py-6 px-4 justify-between items-center ">
  <div className="flex items-center">
    <img src="/Assets/logo eswaf.svg" alt="ESWAF Logo" className="h-10" />
  </div>
  <div className="flex space-x-4 font-gotham">
    <a href="/login" className="text-[#DC0101] font-bold font-gotham mt-1">Log In</a>
    <a href="/sign-up" className="bg-gradient-to-b from-[#A10303] to-[#DB0000] text-white rounded-full px-4 py-2 text-xs flex items-center">
      Sign Up
      <img src="/Assets/Arrow-eswaf.svg" alt="Arrow Icon" className="w-4 h-4 ml-2" />
    </a>
  </div>
</header> */}
{/* Small Screen Header */}
<header className="md:hidden flex bg-transparent py-4 px-4 justify-between items-center">
  <img src="/Assets/logo eswaf.svg" alt="ESWAF Logo" className="h-8" /> {/* Reduced height */}
  <div className="flex space-x-4">
    <a href="/login" className="text-[#DC0101] font-semibold font-gotham ">Log In</a>
    <a href="/sign-up" className="bg-gradient-to-b from-[#A10303] to-[#DB0000] text-white rounded-full px-3 py-1 text-xs flex items-center">
      Sign Up
      <img src="/Assets/Arrow-eswaf.svg" alt="Arrow Icon" className="w-3 h-3 ml-2" /> {/* Smaller arrow */}
    </a>
  </div>
</header>



    {/* Header for Large Screens */}
    <header className="hidden md:flex bg-[#D9D9D9] bg-opacity-100 py-6 px-12 justify-between items-center relative z-20 w-full">
  <div className="flex items-center">
    <img src="/Assets/logo eswaf.svg" alt="ESWAF Logo" className="h-10 w-30 mr-3" />
  </div>

  <div className="flex space-x-12 font-gotham">
    <a href="/login">
      <button className="text-[#DC0101] font-bold font-gotham mt-2">
        LOG IN
      </button>
    </a>
    <a href="/sign-up">
      <button className="bg-gradient-to-b from-[#A10303] to-[#DB0000] text-white rounded-full px-8 py-3 text-sm flex items-center">
        Sign Up
        <img src="/Assets/Arrow-eswaf.svg" alt="Arrow Icon" className="w-7 h-4 ml-4" />
      </button>
    </a>
  </div>
</header>


    {/* Main Content for Small Screens */}
    <main className="flex md:hidden flex-col items-center justify-center px-8 h-screen text-center scroll-snap-align-start ">
    <div className="mb-28">
    <img src={EswafFont} alt="Eswaf Heading" className="w-full max-w-[200px] mx-auto mb-1" />
   
    <h2 className="text-[16px] font-metropolis font-[1000] text-[#DB0000] mt-1" style={{ lineHeight: '41px' }}>Everything Electronically</h2>
  <p className="text-[14px] font-metropolis font-[50] mt-1" style={{ lineHeight: '17px', letterSpacing: '1px' }}>
  Connect Your Business to the World.  </p>
  <div className="mt-6 flex justify-center items-center">
  <button
  onClick={() =>
    window.open(
      "https://onelink.to/sa477q",
      "_blank",
      "noopener,noreferrer"
    )
  }
  className="px-20 py-2 border border-[#A10303] text-[#DB0000] rounded-full text-sm flex items-center whitespace-nowrap"
>
  GET THE APP NOW
  <img src="/Assets/red-arrow.svg" alt="Arrow Icon" className="ml-2 w-6 h-6" />
</button>


  </div>
  </div>
</main>




    {/* Large Screen Background */}
    <div
            className="hidden md:block absolute inset-0"
            style={{
              backgroundImage: `url('/Assets/Eswwafpage.svg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              zIndex: 0
            }}
          />



    {/* Main Content for Large Screens */}
    <main className="hidden md:flex flex-col md:flex-row items-center justify-between px-8 min-h-screen scroll-snap-align-start relative z-10">
  {/* Left Content Section */}
  <div className="flex flex-col justify-center text-center md:text-left max-w-lg w-full lg:ml-20 ">
    {/* ESWAF Heading */}
    <img 
      src={Eswaflargefont} 
      alt="Eswaf Heading" 
      className="w-full max-w-[300px] mx-auto md:mx-0" 
    />

    {/* Tagline */}
    <h2 className="md:text-[30px] text-[24px] text-[#DB0000] font-metropolis font-bold mt-4">
      Everything Electronically
    </h2>

    {/* Subheading */}
    <p className="text-[18px] md:text-[18px] font-metropolis text-[#606060] font-light leading-[32px] mt-4">
    Connect Your Business to the World.    </p>

    {/* "Get the App" Button */}
    <div className="mt-8 flex justify-center md:justify-start">
    <button
  onClick={() =>
    window.open(
      "https://play.google.com/store/apps/details?id=com.emartapp.eswaf",
      "_blank",
      "noopener,noreferrer"
    )
  }
  className="px-28 py-3 border border-[#DB0000] text-[#DB0000] rounded-full text-[16px] font-bold font-gotham md:text-[18px] flex items-center justify-between"
>
  GET THE APP NOW
  <img
    src="/Assets/Arrow-eswaf.svg"
    alt="Arrow Icon"
    className="ml-auto w-5 h-5"
  />
</button>

    </div>
  </div>

  <div className="hidden md:flex justify-center items-center md:w-1/2 h-full relative">
    
  </div>
</main>    
  </div>
  <div 
  className="hidden md:flex absolute inset-y-0 right-28 justify-end items-center z-10"
  style={{ transform: 'translateY(5%)' }}
>
  <img 
    src="/Assets/hexagon_landingpage.png" 
    alt="Mobile App View" 
    className="w-auto h-auto max-w-[370px] md:max-w-[370px] 4k:max-w-[600px]"
  />
</div>



<section className="py-16 flex items-center min-h-screen scroll-snap-align-start bg-gradient-to-r from-[#EAEAEA] to-[#848484]">
  {/* Large Screen Design */}
  <div className="hidden md:flex flex-col items-center justify-center text-center w-full px-8 gap-y-8">
    {/* Text Section */}
    <div className="flex flex-col items-center max-w-3xl">
      <img
        src="/Assets/intro_eswaf1.svg"
        alt="ESWAF Logo"
        className="w-auto h-auto mb-4"
      />
      <p className="text-[18px] sm:text-[18px] font-metropolis text-[#313030] font-[200] mt-4" style={{ lineHeight: '22px' }}>
        eSwaF is a Business Focused platform that connects your Brand, Service, or Business to the World.
        Explore, Connect, Grow : Limitless!
      </p>
    </div>

    {/* Button Section */}
    <div>
    <button
  onClick={() =>
    window.open(
      "https://play.google.com/store/apps/details?id=com.emartapp.eswaf",
      "_blank",
      "noopener,noreferrer"
    )
  }
  className="pl-6 pr-6 py-2 border border-[#DB0000] text-[14px] font-gotham text-white font-bold bg-[#DB0000] rounded-full flex items-center"
>
  <span>DOWNLOAD THE APP NOW</span>
  <div className="ml-4 flex items-center justify-center w-10 h-10 bg-[#DB0000] rounded-full">
    <img src="/Assets/e.svg" alt="Arrow Icon" className="w-8 h-8" />
  </div>
</button>

    </div>
  </div>

  {/* Small Screen Design */}
  <div className="md:hidden flex flex-col items-start w-full px-8 justify-center gap-y-4">
    {/* Image Section */}
    



    <div className="flex justify-center items-center w-full h-full">
  <div className="w-3/4 h-auto"> {/* Use a container with controlled width */}
    <img
      src="/Assets/hexagon_landingpage.png"
      alt="Phone Mockup"
      className="w-full h-auto object-contain"
    />
  </div>
</div>  

    {/* Text Section */}
    <div className="flex flex-col justify-start text-left max-w-lg w-full">
      {/* Replace heading with image */}
      <img
        src="/Assets/introduction-new-small.svg"
        alt="Introduction of ESWAF"
        className="self-start w-auto h-[60px] mb-4"
      />

      {/* Paragraph */}
      <p className="text-[12px] font-metropolis text-[#313030] font-[200] leading-[26px] mb-4" style={{ lineHeight: '15px' }}>
        eSwaF is a Business Focused platform that connects your Brand, Service, or Business to the World.
        Explore, Connect, Grow : Limitless!
      </p>

      {/* Button */}
      <div className="mt-4">
      <button
  onClick={() =>
    window.open(
      "https://onelink.to/sa477q",
      "_blank",
      "noopener,noreferrer"
    )
  }
  className="px-4 py-2 font-gotham font-normal border border-[#DB0000] text-white bg-[#DB0000] rounded-full text-sm flex justify-between items-center"
>
  DOWNLOAD THE APP NOW
</button>

      </div>
    </div>
  </div>
</section>


      {/* Section 3 - Similar to the two screenshots */}
      <section
  className="py-16 min-h-screen flex flex-col scroll-snap-align-start"
  style={{
    backgroundImage: `url('/Assets/Eswafpage.svg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
>
{/* Large Screen Design */}
  <div className="hidden md:flex flex-col w-full px-16 md:px-[150px] gap-y-16">

  {/* 1st Section - Image on Right */}
  <div className="flex md:flex-row-reverse items-center justify-between">
    <div className="md:w-1/2">
      <img
        src="/Assets/market-place-web.svg"
        alt="Verified Marketplace"
        className="h-auto w-auto mx-auto"
      />
    </div>
    <div className="flex flex-col justify-center text-left max-w-lg w-full md:w-1/2">
      <img src="/Assets/Reach.svg" alt="Verified Marketplace" className="h-40 w-80" />
      <p className="text-[18px] sm:text-[18px] font-metropolis text-[#151515] leading-[22px] ">
      Connect with veriﬁed businesses, ensuring trust 
      and authenticity in every interaction. 
      </p>
    </div>
  </div>

  {/* 2nd Section - Image on Left */}
  <div className="flex md:flex-row items-center justify-between">
    <div className="md:w-1/2">
      <img
        src="/Assets/simplified.svg"
        alt="Simplified User Experience"
        className="h-auto w-auto mx-auto"
      />
    </div>
    <div className="flex flex-col justify-center text-left max-w-lg w-full md:w-1/2 mr-10">
      <img src="/Assets/simplifieduser.svg" alt="Simplified User Experience" className="h-40 w-80" />
      <p className="text-[18px] sm:text-[18px] font-metropolis text-[#313030] leading-[22px] ">
      Effortlessly   explore   ,   communicate   and collaborate through our intuitive platform.       </p>
    </div>
  </div>

  {/* 3rd Section - Image on Right */}
  <div className="flex md:flex-row-reverse items-center justify-between">
    <div className="md:w-1/2">
      <img
        src="/Assets/organic.svg"
        alt="Organic Growth Opportunities"
        className="h-auto w-auto mx-auto"
      />
    </div>
    <div className="flex flex-col justify-center text-left max-w-lg w-full md:w-1/2">
      <img src="/Assets/Limitless.svg" alt="Organic Growth Opportunities" className="h-40 w-82" />
      <p className="text-[18px] sm:text-[18px] font-metropolis text-[#313030] leading-[22px] ">
      Unlock   endless   possibilities   for   growth   and collaboration with veriﬁed partners.       </p>
    </div>
  </div>

  {/* 4th Section - Image on Left */}
  <div className="flex md:flex-row items-center justify-between">
    <div className="md:w-1/2">
      <img
        src="/Assets/market-insights.svg"
        alt="Valuable Market Insights"
        className="h-auto w-auto mx-auto"
      />
    </div>
    <div className="flex flex-col justify-center text-left max-w-lg w-full md:w-1/2 mr-10">
      <img src="/Assets/Valuable.svg" alt="Valuable Market Insights" className="h-40 w-80" />
      <p className="text-[18px] sm:text-[18px] font-metropolis text-[#313030] leading-[22px] ">
      Gain   data-driven   regional   insights   to   make informed decisions and drive growth.       </p>
    </div>
  </div>
</div>


  {/* Small Screen Design */}
  <div className="md:hidden flex flex-col items-center w-full px-8 justify-center gap-y-8">
  {/* Verified Marketplace Section */}
  <div className="w-full flex flex-col items-center mb-4">
    <img src="/Assets/small-market-place.svg" alt="Verified Marketplace" className="h-auto w-auto" />
    <div className="flex flex-col items-start text-left max-w-lg w-full mt-4">
      <img src="/Assets/Reach-mob.svg" alt="Verified Marketplace" className="w-auto h-auto" />
      <p className="text-[12px] font-metropolis text-[#313030] leading-[15px] mt-4">
      Connect with veriﬁed businesses, ensuring trust 
      and authenticity in every interaction. 
      </p>
    </div>
  </div>

  {/* Simplified User Experience Section */}
  <div className="w-full flex flex-col items-center mb-4">
    <img src="/Assets/simplified-mobile.svg" alt="Verified Marketplace" className="h-auto w-auto" />
    <div className="flex flex-col items-start text-left max-w-lg w-full mt-4">
    <img src="/Assets/Simplifiedmobile view.svg" alt="Verified Marketplace" className="w-auto h-auto" />
      <p className="text-[12px] font-metropolis text-[#313030] leading-[15px] mt-4">
      Effortlessly   explore   ,   communicate   and collaborate through our intuitive platform. 
      </p>
    </div>
  </div>

  {/* Organic Growth Opportunities Section */}
  <div className="w-full flex flex-col items-center mb-4">
    <img src="/Assets/small-organic.svg" alt="Organic Growth Opportunities" className="h-auto w-auto" />
    <div className="flex flex-col items-start text-left max-w-lg w-full mt-4">
      <img src="/Assets/Limitless-small.svg" alt="Organic Growth Opportunities" className="w-auto h-auto" />
      <p className="text-[12px] font-metropolis text-[#313030] leading-[15px] mt-4">
      Unlock   endless   possibilities   for   growth   and collaboration with veriﬁed partners      </p>
    </div>
  </div>

  {/* Valuable Market Insights Section */}
  <div className="w-full flex flex-col items-center mb-4">
    <img src="/Assets/small-market-insights.svg" alt="Valuable Market Insights" className="h-auto w-auto" />
    <div className="flex flex-col items-start text-left max-w-lg w-full mt-4">
      <img src="/Assets/Valuablemobile.svg" alt="Valuable Market Insights" className="w-auto h-auto" />
      <p className="text-[12px] font-metropolis text-[#313030] leading-[15px] mt-4">
      Gain   data-driven   regional   insights   to   make informed decisions and drive growth.       </p>
    </div>
  </div>
</div>




</section>




{/* Footer for Large Screens */}
<footer className="hidden md:flex w-full bg-gradient-to-r from-[#EAEAEA] to-[#848484] flex-col">
  {/* Top Section */}
  <div className="flex justify-between items-center px-20 py-12">
    {/* Left Section - Logo */}
    <div className="flex items-center">
      <img src="/Assets/logo eswaf.svg" alt="ESWAF Logo" className="h-12" />
    </div>

    {/* Middle Section - Links */}
    <div className="flex space-x-20 font-normal text-[16px]" style={{ lineHeight: '36px' }}>
      <div className="flex flex-col space-y-5">
        <a href="/terms_policies" className="text-[#1F1F1F] text-[16px] font-semibold font-metropolis">Terms & Conditions</a>
        <a href="/privacy_Policy" className="text-[#1F1F1F] text-[16px] font-semibold font-metropolis">Privacy & Security</a>
      </div>
      <div className="flex flex-col space-y-5">
        <a href="/about_us" className="text-[#1F1F1F] text-[16px] font-semibold font-metropolis"> About <span className="text-[#DB0000]">eSwaF</span></a>
        <a href="/help_support" className="text-[#1F1F1F] text-[16px] font-semibold font-metropolis">Help & Support</a>
      </div>
    </div>

    {/* Right Section - Download Buttons */}
    <div className="flex flex-col items-end space-y-4">
      <div className="flex space-x-4">
        <a href="https://play.google.com/store/apps/details?id=com.emartapp.eswaf&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
          <img src="/Assets/new-play-store.svg" alt="Google Play" className="h-12" />
        </a>
        <a href="https://apps.apple.com/in/app/eswaf/id6736904034" target="_blank" rel="noopener noreferrer">
          <img src="/Assets/appstore.svg" alt="App Store" className="h-12" />
        </a>
      </div>
      <button
  onClick={() =>
    window.open(
      "https://play.google.com/store/apps/details?id=com.emartapp.eswaf",
      "_blank",
      "noopener,noreferrer"
    )
  }
  className="bg-[#DB0000] text-white rounded-full px-6 py-2 text-[14px] font-semibold font-gotham"
>
  DOWNLOAD THE APP NOW
</button>

    </div>
  </div>

  {/* Bottom Section - Social Media and Copyright */}
  <div className="w-full border-t-2 border-[#DB0000] py-6 flex justify-between items-center px-12">
    {/* Left Section - Copyright */}
    <div className="text-[#1F1F1F] text-[16px] font-medium font-metropolis" style={{ lineHeight: '36px' }}>
      Copyright 2024 All Rights Reserved <span className="text-[#DB0000]"> eSwaF</span>
    </div>

    {/* Right Section - Social Media Links */}
    <div className="flex justify-center space-x-4 font-metropolis font-medium text-[#DB0000] text-[16px]" style={{ lineHeight: '32px' }}>
      <a href="https://www.instagram.com/emartapp.co">Instagram</a>
      <span>|</span>
      <a href="https://www.linkedin.com/in/emartapp">LinkedIn</a>
      <span>|</span>
      <a href="https://www.facebook.com/emartapp.org">Facebook</a>
      <span>|</span>
      <a href="https://x.com/emartapp">X</a>
    </div>
  </div>
</footer>




{/* Footer for Small Screens */}
<footer className="md:hidden w-full bg-gradient-to-r from-[#EAEAEA] to-[#848484]">
  {/* Top Section */}
  <div className="flex flex-col items-center px-4 py-4">
    <img src="/Assets/logo eswaf.svg" alt="ESWAF Logo" className="h-8 mb-4" />

    {/* Download Buttons */}
    <div className="flex space-x-4 mb-4">
      <a href="https://play.google.com/store/apps/details?id=com.emartapp.eswaf&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
        <img src="/Assets/new-play-store.svg" alt="Google Play" className="h-8" />
      </a>
      <a href="https://apps.apple.com/in/app/eswaf/id6736904034" target="_blank" rel="noopener noreferrer">
        <img src="/Assets/appstore.svg" alt="App Store" className="h-8" />
      </a>
    </div>

    <button
  onClick={() =>
    window.open(
      "https://onelink.to/sa477q",
      "_blank",
      "noopener,noreferrer"
    )
  }
  className="bg-[#DB0000] font-gotham text-white rounded-full px-10 py-2 text-[8px] font-normal mb-4"
>
  DOWNLOAD THE APP
</button>


    {/* Links */}
    <div className="grid grid-cols-2 divide-x divide-gray-400 text-center w-full text-[12px] relative gap-y-4">
      <div className="flex flex-col space-y-2 font-medium text-[#1F1F1F] font-metropolis">
        <a href="/terms_policies" className="font-semibold">Terms & Conditions</a>
        <a href="/privacy_policy" className="font-semibold">Privacy & Security</a>
      </div>
      <div className="flex flex-col space-y-2 font-medium text-[#1F1F1F] font-metropolis pl-4">
        <a href="/about_us" className="font-semibold">About<span className="text-[#DB0000]"> eSwaF</span></a>
        <a href="/help_support" className="font-semibold">Help & Support</a>
      </div>
    </div>
  </div>

  {/* Bottom Section - Social Media and Copyright */}
  <div className="w-full py-4">
    <div className="flex flex-col items-center">
      <div className="flex justify-center space-x-4 text-[#DB0000] text-[12px] font-metropolis font-semibold">
        <a href="https://www.instagram.com/emartapp.co">Instagram</a>
        <span>|</span>
        <a href="https://www.linkedin.com/in/emartapp">LinkedIn</a>
        <span>|</span>
        <a href="https://www.facebook.com/emartapp.org">Facebook</a>
        <span>|</span>
        <a href="https://x.com/emartapp">X</a>
      </div>
      <div className="text-center text-[#1F1F1F] font-bold font-metropolis text-[12px] mt-4">
        © 2024 All Rights Reserved <span className="text-[#DB0000]"> eSwaF</span>
      </div>
    </div>
  </div>
</footer>




    </div>
    </>
  );
};

export default Eswaf;
