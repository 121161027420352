// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// import Sidebar from '../../Components/main_navbar';
// import ReportContainer from '../../Components/report';

// const Inbox = () => {
//   const navigate = useNavigate();

//   const handleNavigation = (path) => {
//     navigate(path);
//   };

//   return (
//     <div className="flex flex-col md:flex-row w-screen h-screen bg-[#FFFFFF] font-poppins overflow-auto">
//       <Sidebar />
//       <div className="flex flex-col w-full">
//         <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5 ">
//           <div className="w-full flex justify-start items-center space-x-2">
//             <h1 className="text-black font-semibold text-xl">Inbox</h1>
//           </div>
//           <div className='mr-7'>
//           {/* <ReportContainer /> */}
//           </div>
//         </div>
//         <div className="border-b border-black mt-4 md:mt-[25px]"></div>
//         <div className="flex justify-center h-full lg:w-4/5 w-full lg:ml-6  4k:w-[88%] mt-7 ">
//           <div className=" flex flex-col items-center mt-6">
//             <div className="w-full h-full 4k:h-60   cursor-pointer" onClick={() => handleNavigation('/direct_message')}>
//               <img src="/Assets/direct_msg.svg" alt="Direct Message" className=" w-60  h-40  object-cover" />
//             </div>
//             <div className="w-full h-full 4k:h-60  cursor-pointer" onClick={() => handleNavigation('/important_message')}>
//               <img src="/Assets/important_msg.svg" alt="Important Message" className="  w-60 h-40 object-cover" />
//             </div>
//             <div className="w-full h-full 4k:h-60  lg:mb-28 cursor-pointer" onClick={() => handleNavigation('/follow_up')}>
//               <img src="/Assets/follow_up.svg" alt="Follow Up" className="w-60 h-40 object-cover" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Inbox;


import React from 'react';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../../Components/main_navbar';
import ReportContainer from '../../Components/report';

const Inbox = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col md:flex-row w-screen h-screen bg-[#FFFFFF] font-poppins overflow-auto">
      <Sidebar />
      <div className="flex flex-col w-full">
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5 ">
          <div className="w-full flex justify-start items-center space-x-2">
            <h1 className="text-black font-semibold text-xl">Inbox</h1>
          </div>
          <div className='mr-7'>
          {/* <ReportContainer /> */}
          </div>
        </div>
        <div className="border-b border-black mt-4 md:mt-[25px]"></div>
        <div className="flex justify-center h-full lg:w-4/5 w-full lg:ml-6  4k:w-[88%] mt-2  sm:mx-4 mx-0 ">
          <div className=" flex flex-col items-center ">
            <div className="lg:w-full  w-60 lg:h-52 h-full 4k:h-60   cursor-pointer" onClick={() => handleNavigation('/direct_message')}>
              <img src="/Assets/direct_msg.svg" alt="Direct Message" className="   h-full  object-cover" />
            </div>
            <div className="lg:w-full  w-60 lg:h-52 h-full 4k:h-60  cursor-pointer" onClick={() => handleNavigation('/important_message')}>
              <img src="/Assets/important_msg.svg" alt="Important Message" className="   h-full object-cover" />
            </div>
            <div className="lg:w-full  w-60 lg:h-52 h-full  4k:h-60   cursor-pointer" onClick={() => handleNavigation('/follow_up')}>
              <img src="/Assets/follow_up.svg" alt="Follow Up" className=" h-full object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inbox;



