import React, { useState } from 'react';
import CenteredImageContainer from '../../Components/black_container.jsx';
import PasswordInputField from '../../Components/password-input-field.jsx';
import Button from '../../Components/button.jsx';
import { useNavigate } from 'react-router-dom';
import { FaCircleExclamation, FaCheck } from 'react-icons/fa6';
import { validatePassword } from '../../../Controller/validation/password_validation.js';
import { showToast, Toast } from '../../Components/toast.jsx';
import { set_password } from '../../../Controller/auth.js';
import { handleLogin } from '../../../Controller/login_controller.js';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase_service.js';
import { useEffect } from 'react';
import ReportContainer from '../../Components/report.jsx';

const ConfirmPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationMessages, setValidationMessages] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    match: false,
  });

  const navigate = useNavigate();

  // Define handleBackButton function
  const handleBackToPhoneDetails = () => {
    navigate('/phone_details', { replace: true });
  };

  // Handle the confirmation alert for navigation to login
  const handleBackButton = (e) => {
    e.preventDefault();
    const confirmResult = window.confirm('Are you sure you want to go back? You will be redirected to the login page.');
    if (confirmResult) {
      navigate('/login', { replace: true });
    } else {
      window.history.pushState(null, '', window.location.pathname);
    }
  };

  useEffect(() => {
    // Redirect to phone-details on back navigation
    const handlePhoneDetailsBack = (e) => {
      e.preventDefault();
      handleBackToPhoneDetails();
    };

    window.history.pushState(null, '', window.location.pathname); // Initial history setup
    if (window.location.pathname === "/confirm-password" ) {
      window.addEventListener('popstate', handlePhoneDetailsBack);
    }

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePhoneDetailsBack);
    };
  }, [navigate]);

  const validatePasswordLocal = (password) => {
    const validations = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setValidationMessages((prev) => ({ ...prev, ...validations }));
    return validations;
  };

  const validateConfirmPassword = (password, confirmpassword) => {
    const match = password === confirmpassword;
    setValidationMessages((prev) => ({ ...prev, match }));
    return match;
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePasswordLocal(e.target.value);
    validateConfirmPassword(e.target.value, confirmpassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validateConfirmPassword(password, e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    setLoading(true);

    const validations = validatePasswordLocal(password);
    const passwordsMatch = validateConfirmPassword(password, confirmpassword);

    if (!Object.values(validations).every(Boolean) || !passwordsMatch) {
      showToast('Please enter a valid password and make sure they match.', 'error');
      setLoading(false);
      return;
    }

    try {
      const companyName = localStorage.getItem('company_name');
      const email = localStorage.getItem('email');
      const secret = localStorage.getItem('secret');

      const payload = {
        email,
        secret,
        password,
        company_name: companyName,
      };

      const response = await set_password(payload);
      if (response.status === 202) {
        await handleLogin(password, email, navigate, setLoading);

        navigate('/location-selection', { replace: true });

        window.history.pushState(null, '', window.location.pathname);
        if (window.location.pathname === "/confirm-password" ) {
        window.addEventListener('popstate', handleBackButton);
      }
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="h-full w-full flex bg-[#FBFCF8]">
    <Toast />
    <div className="absolute top-6 right-6">
      <ReportContainer />
      </div>
    <div className="hidden lg:block w-full lg:w-1/2 h-full relative">
      <div className='w-4/5 h-full'> 
        <CenteredImageContainer imgSrc="Assets/confirm-password.svg" />
      </div>
    </div>
    <div className="w-full lg:w-2/5 h-full flex flex-col justify-center items-start space-y-4 font-poppins">
      <div className="p-6 w-full max-w-2/5">
        <div className="flex items-center mb-6">
          <img src="Assets/logo-eswaf-2.svg" alt="Eswaf Logo" />
        </div>
        <h2 className="text-2xl font-semibold text-black mb-2">Create Password</h2>
        {/* <p className="text-sm font-medium lg: text-lg text-[#6B6B6B]  font-gotham mb-6">
          Password must contain a minimum of 8 characters, lowercase letter, uppercase letter, number, and a special character.
        </p> */}
        <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
      
          {/* Create Password Label and Input */}
          <div className="flex flex-col space-y-2">
            <label htmlFor="new-password" className="text-xs font-medium text-[#545454]">
              Password
            </label>
            <PasswordInputField
              id="new-password"
              placeholder="Create Password"
              name="new-password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
            />
          </div>

          {/* Confirm Password Label and Input */}
          <div className="flex flex-col space-y-2">
            <label htmlFor="confirm-new-password" className="text-xs font-medium text-[#545454]">
              Confirm Password
            </label>
            <PasswordInputField
              id="confirm-new-password"
              placeholder="Confirm Password"
              name="confirm-new-password"
              value={confirmpassword}
              onChange={handleConfirmPasswordChange}
              autoComplete="new-password"
            />
          </div>

          {/* Password Validation Messages */}
          <div className="grid grid-cols-2 gap-x-6 gap-y-2 mt-2 text-sm text-[#545454] font-poppins font-normal">
            <div className="text-[12px] flex items-center space-x-2 ">
              {validationMessages.length ? <FaCheck className="text-green-500" /> : <FaCircleExclamation className="text-[#DB0000]" />}
              <span>At least 8 characters</span>
            </div>
            <div className="text-[12px] flex items-center space-x-2">
              {validationMessages.lowercase ? <FaCheck className="text-green-500" /> : <FaCircleExclamation className="text-[#DB0000]" />}
              <span>Lowercase letter</span>
            </div>
            <div className="text-[12px] flex items-center space-x-2">
              {validationMessages.uppercase ? <FaCheck className="text-green-500" /> : <FaCircleExclamation className="text-[#DB0000]" />}
              <span>Uppercase letter</span>
            </div>
            <div className="text-[12px] flex items-center space-x-2">
              {validationMessages.number ? <FaCheck className="text-green-500" /> : <FaCircleExclamation className="text-[#DB0000]" />}
              <span>Number</span>
            </div>
            <div className="text-[12px] flex items-center space-x-2">
              {validationMessages.special ? <FaCheck className="text-green-500" /> : <FaCircleExclamation className="text-[#DB0000]" />}
              <span>Special character</span>
            </div>
          </div>
          
          {/* Submit Button */}
          <div className="mt-8">
            <Button
              text="Log in"
              type="submit"  // Changed type to submit
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              fontWeight="font-bold"
              loading={loading}
              className="w-full mt-6"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
  );
};

export default ConfirmPassword;