

import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import FilePicker from '../../Components/file_picker';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/button';
import ReportContainer from '../../Components/report';
import { Toast } from '../../Components/toast';
import { saveCompanyDocuments } from '../../../Controller/company_document';
import Modal from '../../Components/pdf_opener';

const CompanyDocs = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState({
    'Business License': '',
    'Tenancy Contract': '',
    'Certificate of Incorporation/Trading Register': '',
  });
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [modalTitle, setModalTitle] = useState(''); // Add state for modal title
  

  const handleFileChange = (label, url) => {
    setDocuments(prevState => ({
      ...prevState,
      [label]: url
    }));
  };

  const handleSave = async () => {
    saveCompanyDocuments(documents, navigate, setLoading);
    localStorage.setItem('COMPANY_DETAILS_DOCUMENTS_COMPLETED', 'true'); // Call the controller function
  };

  const openDocument = (title, url) => {
    setModalTitle(title); // Set the title for the modal based on the document
    setSelectedDocument(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDocument('');
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-semibold">
      <Sidebar />
      <Toast />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5 4k:w-[88%]">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl font-poppins">Company Documents</h1>
        </div>
        <ReportContainer />
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 md:w-3/4 4k:w-[88%] lg:mt-2 mt-2 p-2 overflow-auto">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="flex flex-col font-semibold font-poppins">
            {/* Business License */}
            <div className="mb-4">
              <h2 className="font-semibold text-[#171717] text-left text-[12px] lg:text-[15px]">
                Business License <span className="text-[#DB0000]">(Required)</span>
              </h2>
              <FilePicker 
                label="Business License" 
                width="w-70" 
                height="h-20" 
                onFileChange={handleFileChange} 
                hideLabel={true} 
                required 
                openDocument={(url) => openDocument('Business License', url)} 
              />
              {documents['Business License'] && (
                <p className="text-xs text-gray-500 mt-1">Please tap the card to select a different business license.</p>
              )}
            </div>

            {/* Tenancy Contract */}
            <div className="mb-4">
              <h2 className="font-semibold text-[#171717] text-left text-[12px] lg:text-[15px]">
                Tenancy Contract <span className="text-[#DB0000]">(Required)</span>
              </h2>
              <p className="text-[11px] lg:text-[12px] text-[#3f3f3f] mb-2">(Matching your official address)</p>
              <FilePicker 
                label="Tenancy Contract" 
                width="w-70" 
                height="h-20" 
                onFileChange={handleFileChange} 
                hideLabel={true} 
                required 
                openDocument={(url) => openDocument('Tenancy Contract', url)} // Pass title and URL
              />
              {documents['Tenancy Contract'] && (
                <p className="text-xs text-gray-500 mt-1">Please tap the card to select a different tenancy contract.</p>
              )}
            </div>

            {/* Trading Register */}
            <div className="mb-4">
              <h2 className="font-semibold text-[#171717] text-left text-[12px] lg:text-[15px]">
                Trading Register
              </h2>
              <p className="text-[11px] lg:text-[12px] text-[#3f3f3f] mb-2">(Certificate of Incorporation)</p>
              <FilePicker 
                label="Certificate of Incorporation/Trading Register" 
                width="w-70" 
                height="h-20" 
                onFileChange={handleFileChange} 
                hideLabel={true} 
                openDocument={(url) => openDocument('Certificate of Incorporation/Trading Register', url)} // Pass title and URL
              />
              {documents['Certificate of Incorporation/Trading Register'] && (
                <p className="text-xs text-gray-500 mt-1">Please tap the card to select a different trading register.</p>
              )}
            </div>
          </div>
          <div className="flex justify-center items-center mt-6">
            <Button
              text="Save"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </div>
      </div>

      {/* Modal for PDF viewing */}
      <Modal isOpen={isModalOpen} onClose={closeModal} title={modalTitle}>
        {selectedDocument && (
          <iframe 
            src={selectedDocument} 
            title="Document Viewer" 
            className="w-full h-full"
          />
        )}
      </Modal>
    </div>
  );
};

export default CompanyDocs;
