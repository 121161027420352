
//commented original code above
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';
import ChatList from '../../Components/chatlist_ex';
import ChatWindow from '../../Components/chatmsg_ex';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase_service';
import { FaSpinner } from 'react-icons/fa';

const DirectMessage = () => {
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Add state to check screen size
  const [loading, setLoading] = useState(true); // Add loading state for spinner

  const { chatId } = useParams(); // Extract chatId from URL params
  const navigate = useNavigate();
  

  // Fetch the current user's chat messages from the chats collection
  useEffect(() => {
    const fetchChats = async () => {
      const companyUid = localStorage.getItem('company_uid'); // Get logged-in user's UID
      console.log('Fetching chats for companyUid:', companyUid); // Debug log

      if (companyUid) {
        setCurrentUserUid(companyUid); // Store the UID for future use

        const q = query(
          collection(db, 'chats'),
          where('participants', 'array-contains', companyUid) // Only check for participants, no sorting
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const fetchedMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setMessages(fetchedMessages); // No sorting needed
          setLoading(false); // Stop showing the spinner after loading
        });

        return () => unsubscribe(); // Clean up the snapshot listener
      } else {
        console.error('No companyUid found in localStorage.');
        // Handle missing localStorage value
      }
    };

    fetchChats();
  }, []);

  // Handle resizing of the window to detect if it is mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ADD THIS USEEFFECT TO HANDLE INITIAL CHAT SELECTION
  useEffect(() => {
    if (chatId && messages.length > 0) {
        const selectedMessage = messages.find(msg => msg.id === chatId);

        if (selectedMessage) {
            setSelectedUser({
                id: selectedMessage.receiverUid === currentUserUid ? selectedMessage.senderUid : selectedMessage.receiverUid,
                userName: selectedMessage.receiverUid === currentUserUid ? selectedMessage.senderName : selectedMessage.receiverName,
                userImage: selectedMessage.receiverUid === currentUserUid ? selectedMessage.senderImage : selectedMessage.receiverImage,
            });
            setSelectedMessageId(selectedMessage.id);
        }
    }
}, [chatId, messages, currentUserUid]);

  
  // const filteredMessages = messages.filter(
  //   (msg) =>
  //     !(msg.isImportantForSender && msg.senderUid === currentUserUid) &&
  //     !(msg.isImportantForReceiver && msg.receiverUid === currentUserUid)
  // );
  

  // Handle selecting a message from the chat list
  const handleSelectMessage = (chat) => {
    setSelectedUser({
      id: chat.receiverUid === currentUserUid ? chat.senderUid : chat.receiverUid,
      userName: chat.receiverUid === currentUserUid ? chat.senderName : chat.receiverName,
      userImage: chat.receiverUid === currentUserUid ? chat.senderImage : chat.receiverImage,
    });
    setSelectedMessageId(chat.id);
    navigate(`/direct_message/chat/${chat.id}`);
  };

  const selectedMessage = messages.find(msg => msg.id === chatId);

  // Conditionally render based on screen size
  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins ">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          {/* Show Back Arrow on Small Screens */}
          {/* <img
  src="/Assets/arrow_back.svg"
  alt="Back"
  className="cursor-pointer mr-2 h-6 w-6 md:hidden" // Add 'md:hidden' to hide on larger screens
  onClick={() => setSelectedMessageId(null)} // Set selectedMessageId to null to navigate back to the chat list
/> */}

{selectedMessageId && (
      <img
        src="/Assets/arrow_back.svg"
        alt="Back"
        className="cursor-pointer mr-2 h-6 w-6 md:hidden mt-4 lg:mt-0" // Add 'md:hidden' to hide on larger screens
        onClick={() => {
          setSelectedMessageId(null); // Deselect the chat
          navigate('/direct_message'); // Navigate back to the chat list
        }}
      />
    )}

          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl mt-4 lg:mt-0">Direct Message</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col md:flex-row w-full flex-grow overflow-hidden"> {/* Add flex-grow and overflow-hidden */}
        <div className={`w-full md:w-1/4 h-full bg-[#FFFFFF] border-r overflow-hidden flex flex-col ${selectedMessageId && isMobile ? 'hidden' : 'block'}`}> {/* Add overflow-hidden and flex flex-col */}
          <div className="flex-grow overflow-y-auto"> {/* Change to flex-grow and keep overflow-y-auto */}
          {loading ? ( // Show spinner while loading
              <div className="flex items-center justify-center h-full text-gray-500">
                <FaSpinner className="animate-spin text-xl" />
              </div>
            ) : messages.length === 0 ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                No messages available
              </div>
            ) : (
              <ChatList
                messages={messages}
                currentUserUid={currentUserUid}
                onSelectMessage={handleSelectMessage}
                selectedMessageId={selectedMessageId || chatId}
                loading={loading}
              />
            )}
          </div>
        </div>

        {selectedMessage && (
          <div className={`w-full md:w-3/4 h-full ${!selectedMessageId && isMobile ? 'hidden' : 'block'}`}>
            <ChatWindow
              message={selectedMessage}
              selectedUser={selectedUser}
              currentUserUid={currentUserUid}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectMessage;






