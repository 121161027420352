
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SettingsSidebar from "./settings";
import ConfirmationPopup from './pop_up';
import { useLocation } from 'react-router-dom';
import { useSidebar } from './sidebar_context';
import { doc,getDoc,updateDoc } from 'firebase/firestore';
import { db } from '../../firebase_service';


const Sidebar = ({ sidebarType = 'main', onCloseSidebar }) => {
    const location = useLocation();
 
    const currentPath = location.pathname;
     
    const [isOpen, setIsOpen] = useState(false);
    const [isMoreExpanded, setIsMoreExpanded] = useState(false);
    const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [newChatNotification, setNewChatNotification] = useState(false); // State for red dot
    const navigate = useNavigate();
 

    const isCurrentPath = (path) => {
        return location.pathname === path;
    };

    // Retrieve verification status from localStorage when component mounts
    useEffect(() => {
        const getVerificationStatus = () => {
            const status = localStorage.getItem('verificationStatus') === 'true';
            setVerificationStatus(status);
        };

        getVerificationStatus();
        window.addEventListener('storage', getVerificationStatus);

        return () => {
            window.removeEventListener('storage', getVerificationStatus);
        };
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            const userId = localStorage.getItem("company_uid"); // Adjust as needed to get the current user's ID
            if (!userId) return;

            try {
                const userDocRef = doc(db, "users", userId);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setVerificationStatus(data.verificationStatus || null);
                    setNewChatNotification(data.newChatNotification || false);
                } else {
                    console.log("User document does not exist!");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();

        // Optionally, add a listener for real-time updates
    }, []);

    useEffect(() => {
        if (currentPath === '/direct_message' || currentPath === '/important_message') {
          // Reset newChatNotification to false
          setNewChatNotification(false);
      
          // Optionally, update Firestore
          const userId = localStorage.getItem("company_uid");
          if (userId) {
            const userDocRef = doc(db, "users", userId);
            updateDoc(userDocRef, { newChatNotification: false })
              .catch((error) => {
                console.error("Error updating newChatNotification:", error);
              });
          }
        }
      }, [currentPath]);

    const buttonClass = (path) => {
        let baseClass = "flex items-center py-4 px-6 w-full rounded-lg hover-group  ";
        if (isCurrentPath(path)) {
            return baseClass + "bg-[#232323] text-[#DB0000] active-tab";
        }
        return baseClass + "hover:bg-[#232323] hover:text-[#DB0000]";
    };


    const handleNavigation =async (path) => {
        const currentStatus = localStorage.getItem('verificationStatus');
        console.log('Current verification status:', currentStatus); // Debugging log

        if (currentStatus === 'VERIFICATION_COMPLETED') {
            navigate(path); // Allow navigation directly if verification is completed
        } else if (path === '/home' && currentStatus !== 'VERIFICATION_PENDING'  && currentStatus !== 'VERIFICATION_REJECTED') {
            // Navigate to details page only when path is '/home' and status is NOT VERIFICATION_PENDING
            navigate('/details_page');
            


           // Navigate to the details page when clicking "Home" if status is not completed
            // const notificationResetPaths = ['/direct_message', '/important_messages'];

        //     if (notificationResetPaths.includes(path)) {
        //       // Reset newChatNotification to false when navigating to specified pages
        //       try {
        //         const userId = localStorage.getItem("company_uid"); // Adjust as needed to get the current user's ID
        //         if (userId) {
        //           const userDocRef = doc(db, "users", userId);
        //           await updateDoc(userDocRef, { newChatNotification: false });
        //           setNewChatNotification(false); // Update local state as well
        //         }
        //       } catch (error) {
        //         console.error("Error updating newChatNotification:", error);
        //       }
        //     }
        //   
    } else if (currentStatus === 'VERIFICATION_PENDING' || currentStatus === 'VERIFICATION_REJECTED') {
            if (path === '/video' || path === '/inbox_page' || path === '/posts' || path === '/search-page' || path === '/discover-page') {
                setShowPopup(true); // Show popup only for restricted paths when pending or rejected
            } else {
                navigate(path); // Allow navigation for other paths
            }
        } else {
            setShowPopup(true); // Show popup if verification status is unknown or null
        }
    };
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const openSettingsSidebar = () => {
        setShowSettingsSidebar(true); // Open the settings sidebar when clicking on Settings
    };

    // const closeSettingsSidebar = () => {
    //     setShowSettingsSidebar(false);
    // };

    const toggleMore = () => {
        // Toggle the expanded state of the "More" section
        setIsMoreExpanded(!isMoreExpanded);
    };
    
    const handleCloseSidebar = () => {
        setShowSettingsSidebar(false);
        if (onCloseSidebar) {
          onCloseSidebar();
        }
      };
    const closePopup = () => {
        setShowPopup(false);
    };
    if (sidebarType === 'settings') {
        return <SettingsSidebar closeSettings={handleCloseSidebar} />;
    }

    
    
    return (
        <div className="flex font-poppins font-normal">
            {!showSettingsSidebar && (
                <button
                    className={`fixed top-4 right-4 md:hidden text-2xl z-50 ${isOpen ? 'hidden' : 'block'}`}
                    onClick={toggleSidebar}
                >
                    {/* &#9776; */}
                    <div className="w-8 h-7 bg-[#ffffff] rounded-lg flex items-center justify-center">
                        <span className="text-2xl">&#9776;</span>
                    </div>
                </button>
            )}
            {!showSettingsSidebar && (
                <nav
                    className={`fixed top-0 right-0 w-64 h-full bg-[#171717] text-white transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 md:translate-x-0 z-40 flex flex-col overflow-y-auto font-normal`}
                >
                    <button
                        className="absolute top-4 left-4 text-3xl md:hidden"
                        onClick={toggleSidebar}
                    >
                        &times;
                    </button>
                    <div className="mt-10 space-y-2 flex-grow font-normal">
                    <button className={buttonClass('/home')} onClick={() => handleNavigation('/home')}>
                            <span className="lg:text-[18px] text-sm">Home</span>
                            <img src="/Assets/home.svg" alt="Home Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button>
                        <button className={buttonClass('/search-page')} onClick={() => handleNavigation('/search-page')}>
                            <span className="lg:text-[18px] text-sm">Search</span>
                            <img src="/Assets/search-normal.svg" alt="Search Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button>
                        {/* <button className={buttonClass('/home')} onClick={() => handleNavigation('/home')}>
                            <span className="lg:text-[18px] text-sm">Home</span>
                            <img src="/Assets/home.svg" alt="Home Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button> */}
                        <button className={buttonClass('/video')} onClick={() => handleNavigation('/video')}>
                            <span className="lg:text-[18px] text-sm">Explore</span>
                            <img src="/Assets/searchnew.svg" alt="Explore Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button>
                        <button className={buttonClass('/discover-page')} onClick={() => handleNavigation('/discover-page')}>
                            <span className="lg:text-[18px] text-sm">Discover</span>
                            <img src="/Assets/discover_new.svg" alt="Discover Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button>
                        <button className={buttonClass('/posts')} onClick={() => handleNavigation('/posts')}>
                            <span className="lg:text-[18px] text-sm">Posts</span>
                            <img src="/Assets/post_request.svg" alt="Post Request Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button>
                        <button
                            className={`${buttonClass('/inbox_page')} flex items-center justify-between`}
                            onClick={() => handleNavigation('/inbox_page')}
                            >
                            <span className="lg:text-[18px] text-sm">Message</span>
                            <div className="relative">
                                <img
                                src="/Assets/message.svg"
                                alt="Message Icon"
                                className="w-6 h-6 nav-icon"
                                />
                                {newChatNotification && (
                                <span className="absolute top-0 right-0 w-2 h-2 bg-red-600 rounded-full"></span>
                                )}
                            </div>
                            </button>

                        <button className={buttonClass('/profile1')} onClick={() => handleNavigation('/profile1')}>
                            <span className="lg:text-[18px] text-sm">Profile</span>
                            <img src="/Assets/profile.svg" alt="Profile Icon" className="w-6 h-6 ml-auto nav-icon" />
                        </button>
                        

                      
                    </div>

                    <div className="mt-auto mb-2 w-full">
                        <button c
                            onClick={openSettingsSidebar}
                            className="flex items-center py-4 px-6 w-full hover:bg-[#232323] hover:text-[#DB0000] rounded-lg hover-group "
                        >
                            <span className="lg:text-[18px] text-sm hover:text-[#DB0000]">Settings</span>
                            <img 
                                src="/Assets/settings_icon2.svg" 
                                alt="More Icon" 
                                className="w-6 h-6 ml-auto nav-icon" 
                            />
                        </button>
                    </div>
                </nav>
            )}
      {showSettingsSidebar && <SettingsSidebar closeSettings={handleCloseSidebar} />}

            {showPopup && (
                <ConfirmationPopup
                    onClose={closePopup}
                    show={showPopup}
                />
            )}

            <style jsx>{`
               .hover-group:hover .nav-icon,
                .active-tab .nav-icon {
                    filter: brightness(0) saturate(100%) invert(12%) sepia(88%) saturate(5735%) hue-rotate(357deg) brightness(96%) contrast(115%);
                }
            `}</style>
        </div>
    );
};

export default Sidebar;