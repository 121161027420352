import React from 'react';
// import { Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatMessages from './View/Components/chatmsg_ex.jsx';
import MainPageSearch from './View/Pages/Home_module/main_search_page.jsx';
import TradeRolePage from './View/Pages/Onboarding_module/trade_role_page.ui.jsx';
import SpashScreen from './View/Pages/Onboarding_module/spash_screen_ui';
import MainContentLogin from './View/Pages/Onboarding_module/login_page.jsx';
import EmailVerificationPage from './View/Pages/Onboarding_module/email_otp_validation_page.jsx';
import MainEmailVerified from './View/Pages/Onboarding_module/confirm_email_verified.jsx';
import MainAreaOfOperation from './View/Pages/Onboarding_module/select_area_of_operation.jsx';
import './App.css'; 
import HexGrid from './View/Components/trade.jsx';
import AddReviews from "./View/Pages/Reviews_module/add_review_page.jsx";
import Reviews from "./View/Pages/Reviews_module/reviews_page.jsx";
import CompanyPhotosPage from './View/Pages/compnany_photos_module/company_photos_page.jsx';
import AccountPage from "./View/Pages/Setting_module/account_details";
import Subscription from "./View/Pages/Setting_module/my_subscription";
import SubscriptonPlans from "./View/Pages/Setting_module/subscription_plans";
import SubscriptionDetails from "./View/Pages/Setting_module/subscription_details";
import Paymentmethod from "./View/Pages/Setting_module/payment_method";
import PaymentSuccessful from "./View/Pages/Setting_module/payment_successful";
import Security from "./View/Pages/Setting_module/security_page";
import PrivacyPolicy from "./View/Pages/Static_page_module/privacy_policy";
import Rate from "./View/Components/star_rating.jsx";
import ForgotPassword from "./View/Pages/Setting_module/forgot_password.jsx";
import NewPassword from "./View/Pages/Setting_module/new_password";
import ReviewAdd from './View/Pages/Reviews_module/add_review_page.jsx';
import Adding from './View/Pages/Reviews_module/reviews_page.jsx';
import CompanyDetails from './View/Pages/Company_details_module/details_page.jsx';
import CompanyLogo from './View/Pages/Company_details_module/company_logo.jsx';
import CompanyAccount_Managed from './View/Pages/Company_details_module/account_manager.jsx';
import CompanyAddress from './View/Pages/Company_details_module/address.jsx';
import CompanyDocs from './View/Pages/Company_details_module/company_docs.jsx';
import CompanyDetailsPhone from './View/Pages/Company_details_module/phone_details.jsx';
import CompanyPhoneVerified from './View/Pages/Company_details_module/phone_verification.jsx';
import Linkedin from './View/Pages/Company_details_module/linkedin_page.jsx';
import LinkedinVerify from './View/Pages/Company_details_module/linkedin_verification.jsx';
import AddCatalog from './View/Pages/Company_details_module/catalog.jsx';
import ProductService from './View/Pages/Company_details_module/product_service.jsx';
import SampleFormat from './View/Pages/Company_details_module/view_sample_format.jsx';
import ViewManagement from './View/Pages/management_module/view_management.jsx';
import ProductList from './View/Pages/Company_details_module/product_service_list.jsx';
import Complaints from './View/Pages/Complaints/complaints.jsx';
import FeedbackView from './View/Pages/feedback.jsx';
import ImageGallery from './View/Pages/Photos_module/view_photos.jsx';
import ViewPhotos from './View/Pages/Photos_module/view_photos.jsx';
import ShowcaseDetail from './View/Pages/Photos_module/detailed_view.jsx';
import DetailedView from './View/Pages/Photos_module/detailed_view.jsx';
import PhotoSlider from './View/Pages/Photos_module/company_detailed.jsx';
import CompanyProduct from './View/Pages/Photos_module/add_product.jsx';
import SwiperGallery from './View/Pages/Photos_module/company_detailed.jsx';
import SelectDropDownRegion from './View/Pages/Onboarding_module/select_drop_down_region.jsx';
import CategorySelection from './View/Pages/Onboarding_module/category_selection.jsx';
import VideoPlayer from './View/Pages/Discover-explore/discover_video_player.jsx';
import VideoCard from './View/Components/discover_screen.jsx';
import DiscoverExplore from './View/Pages/Discover-explore/discover_explore_page.jsx';
import ProfilePage from './View/Pages/Profile_module/profile_visitors_view.jsx';
import Selection from './View/Pages/Onboarding_module/select_drop_down_region.jsx';
import SignUp from './View/Pages/Onboarding_module/sign_up_page.jsx';
import MainHome from './View/Pages/Onboarding_module/home_page.jsx';
import CategoryPage from './View/Pages/Home_module/category_page.jsx';
import Feedback from './View/Pages/feedback.jsx';
import TradeRolePageSelection from './View/Pages/Onboarding_module/trade_role_selection.jsx';
import UploadVideo from './View/Pages/Video_module/upload_video.jsx';
import UploadedVideo from './View/Pages/Video_module/uploaded_video.jsx';
import AddManagement from './View/Pages/management_module/add_management.jsx';
import CompanyPhotoManagement from './View/Pages/compnany_photos_module/company_photos_management.jsx';
import PostRequest from './View/Pages/Discover-explore/post_request_page.jsx';
import ConfirmPassword from './View/Pages/Onboarding_module/confirm_password_page.jsx';
import VerificationOfProfile from './View/Pages/profile_verification_module/verified_profile_page.jsx';
import VerifiedSubscriptionPlans from './View/Pages/profile_verification_module/verified_profile_subscription.jsx';
import VerificationWithOTP from './View/Pages/profile_verification_module/verication_with_otp.jsx';
import VerificationSuccess from './View/Pages/profile_verification_module/verification_successful.jsx';
import ProductCatalog from './View/Pages/Company_details_module/view_product_catalog.jsx';
import Verified from './View/Pages/Company_details_module/verified.jsx';
import EditProfile from './View/Pages/edit_profile_page.jsx';
import ReportPage from './View/Components/report_page.jsx';
import ProtectedRoute from './View/Components/protected_route.jsx';
import { Toast } from './View/Components/toast.jsx';
import ForgotPasswordPage from './View/Pages/Onboarding_module/forgot_password_page.jsx';
import ResetPassword from './View/Pages/Onboarding_module/reset_password_page.jsx';
import ResetOtpPassword from './View/Pages/Onboarding_module/reset_otp_page.jsx';
import Inbox from './View/Pages/Chat_module/inbox_page.jsx';
import DirectMessage from './View/Pages/Chat_module/direct_message.jsx';
import SubscriptionPayment from './View/Pages/Setting_module/subscription_payment_method.jsx';
import VerificationPage from './View/Pages/verification_page.jsx';

import ImportantMessages from './View/Pages/Chat_module/important_message.jsx';
import UpdateForm from './View/Pages/update_documents_page.jsx';
import ProfilePage2 from './View/Pages/Profile_module/other_profile.jsx';
import ComplaintSubmission from './View/Pages/Complaints/add_complaints.jsx';
import HelpSupport from './View/Pages/Static_page_module/help_support.jsx';
import TermsAndConditions from './View/Pages/Static_page_module/terms_policies.jsx';
import EditForm from './View/Pages/edit_documents.jsx';
import ProfileVisitorsView from './View/Pages/Profile_module/profile_visitors_view.jsx';
import FollowUp from './View/Pages/Chat_module/follow_up.jsx';
import CompanyDocsView from './View/Pages/Company_details_module/company_docs_view.jsx';
import AboutUs from './View/Pages/Static_page_module/about_us.jsx';
import AddComplaints from './View/Pages/Complaints/add_complaints.jsx';
import OtherProfileVisitorsView from './View/Pages/Profile_module/other_profile.jsx';
// import PhotosDetails from './View/Pages/Photos_module/photos_details.jsx';
import CompanyReport from './View/Components/company_report.jsx';
import PostList from './View/Pages/Post_Request/post_request_list.jsx';
import Video from './View/Pages/Discover-explore/explore-all-video.jsx';
import Eswaf from './View/Pages/eswaf-website/eswaf_website.jsx';
import MyPostsComponent from './View/Pages/Post_Request/my_posts.jsx';
import ProfileRedirector from './View/Components/profile_redirect.jsx';
import FailurePage from './View/Pages/Setting_module/failure.jsx';
import SocialLinks from './View/Pages/Profile_module/social_links.jsx';
import About from './View/Pages/Setting_module/about_us_settings.jsx';
import Notifications from './View/Pages/notification_page.jsx';
import TermsPolicies from './View/Pages/Setting_module/terms_policies_settings.jsx';
import PrivacyPolicySettings from './View/Pages/Setting_module/privacy_policy_settings.jsx';
import HelpandSupport from './View/Pages/Setting_module/help_support_settings.jsx';
import MapDialog from './View/Components/map.jsx';
import NoRefundPolicy from './View/Pages/no_refund_page.jsx';
const stripePromise = loadStripe('pk_test_51Oqj9IJI3AyIcueOZlXsVob324A19wJMInhlkcEnYclAQfiHoLHDcpgQvV3cr4Be7KAHSjcHCKvXnM1SD05vu5Ks00xArfrvJ3');


const categories = [
  'Logistics', 
  'Hand Craft', 
  'Financial Institutions', 
  'Start-Up', 
  'Precious-Stones-Metals', 
  'Seller', 
  'Buyer', 
  'Investor', 
  'Projects', 
  'MarketPlace',
  'Legal'
];
function App() {
  return (
    <>
     
      <Toast />
      <Elements stripe={stripePromise}>
        
        <Routes>
        <Route path="/refund_policy" element={<NoRefundPolicy />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                  <Route path="/help_support" element={<HelpSupport />} />
          <Route path="/terms_policies" element={<TermsAndConditions/>} />
          <Route path="/about_us" element={<AboutUs />}/>
          <Route path="/home" element={<ProtectedRoute page={<MainHome />} />} />
          <Route path="/email-verification" element={<EmailVerificationPage />} />
          <Route path="/phone_details" element={<CompanyDetailsPhone />} />
          <Route path="/phone_verification" element={<CompanyPhoneVerified />} />
          <Route path="/confirm-password" element={<ConfirmPassword />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/report_page" element={<ReportPage />} />

          <Route path="/" element={<Eswaf />} />
          <Route path="/failure" element={<ProtectedRoute page={<FailurePage />} />} />
          <Route path="/login" element={<MainContentLogin/>}  />

          {/* <Route path= "/login" element={<ProtectedRoute page={<MainContentLogin />} /> */}
          <Route path="/area-of-operation" element={<ProtectedRoute page={<MainAreaOfOperation />} />} />
      
    <Route path="/category-listings" element={<ProtectedRoute page={< CategorySelection/>} />} />
          <Route path="/selection" element={<ProtectedRoute page={< Selection/>} />} />
          <Route path="/email-verified" element={<ProtectedRoute page={<MainEmailVerified /> } />}  />
          <Route path="/trade" element={<ProtectedRoute page={<HexGrid />}  />}/>
          <Route path="/trade-selection" element={<ProtectedRoute page={<TradeRolePageSelection />} />}/>
          <Route path="/location-selection" element={<ProtectedRoute page={<SelectDropDownRegion />} />}/>
          {/* <Route path="/video" element={<ProtectedRoute page={<VideoCard />} /> */}
          <Route path="/forgot-password-page" element={<ProtectedRoute page={<ForgotPasswordPage />} />} />
          <Route path="/reset-password-page" element={<ProtectedRoute page={<ResetPassword />} />} />
          <Route path="/reset-password-otp-page" element={<ProtectedRoute page={<ResetOtpPassword />} />} />
          <Route path="/search-page" element={<ProtectedRoute page={<MainPageSearch />} />} />
          <Route path="/discover-page" element={<ProtectedRoute page={<DiscoverExplore />} />} />
          <Route path="/video_player" element={<ProtectedRoute page={<VideoPlayer />} />} />
          <Route path="/video/:id?" element={<ProtectedRoute page={<Video />} />} />
          {/* <Route path="/video/:id?" element={<ProtectedRoute page={<Video />} /> */}


{/* 
          {categories.map(category => (
        <Route 
          key={category} 
          path={/${category.replace(/\s+/g, '_').replace(/-/g, '_').toLowerCase()}} 
          element={<ProtectedRoute page={<CategoryPage category={category} />} 
        />
      ))} */}
    {categories.map(category => (
  <Route 
    key={category.id} 
    path={`/:categoryId`} // This will match routes with numeric IDs
    element={<ProtectedRoute page={<CategoryPage /> } />}  // No need to pass category prop, as we will read from params
  />
))}

          <Route path="/inbox_page" element={<ProtectedRoute page={<Inbox />} />} />
          <Route path="/direct_message" element={<ProtectedRoute page={<DirectMessage />} />}/>
          <Route path="/direct_message/:uid" element={<ProtectedRoute page={<DirectMessage />} />} />

          <Route path="/direct_message/chat/:chatId" element={<ProtectedRoute page={<DirectMessage />} />}/>
          <Route path="/important_message" element={<ProtectedRoute page={<ImportantMessages />} />} />
          <Route path="/account_details" element={<ProtectedRoute page={<AccountPage />} />} />
          <Route path="/my_subscription" element={<ProtectedRoute page={<Subscription />} />}/>
          <Route path="/subscription_plans" element={<ProtectedRoute page={<SubscriptonPlans />} />}/>
          <Route path="/subscription_details" element={<ProtectedRoute page={<SubscriptionDetails />} />} />
          <Route path="/payment_method" element={<ProtectedRoute page={<Paymentmethod />} />}/>
          <Route path="/success" element={<ProtectedRoute page={<PaymentSuccessful />} />} />
          <Route path="/security_page" element={<ProtectedRoute page={<Security />} />}/>
          <Route path="/forgot_password" element={<ProtectedRoute page={< ForgotPassword />} />} />
          <Route path="/new_password" element={<ProtectedRoute page={< NewPassword />} />} />
          {/* <Route path="/privacy_policy" element={<ProtectedRoute page={<PrivacyPolicy />} />}/> */}
          <Route path="/add_review_page/:uid?" element={<ProtectedRoute page={<ReviewAdd />} />}/>
          <Route path="/reviews_page/:uid?" element={<ProtectedRoute page={<Adding />}  />}/>
          <Route path="/details_page" element={<ProtectedRoute page={<CompanyDetails />} />}/>
          <Route path="/company_logo" element={<ProtectedRoute page={<CompanyLogo />} />}/>
          <Route path="/account_manager" element={<ProtectedRoute page={<CompanyAccount_Managed />} />} />
          <Route path="/address" element={<ProtectedRoute page={<CompanyAddress />} />} />
          <Route path="/company_docs" element={<ProtectedRoute page={<CompanyDocs />}/>} /> 
          <Route path="/company_docs_view/:company_id?" element={<ProtectedRoute page={<CompanyDocsView />}/>} />
         
 
          {/* <Route path="/linkedin_page" element={<ProtectedRoute page={<Linkedin />} />
          <Route path="/linkedin_verification" element={<ProtectedRoute page={<LinkedinVerify />} /> */}
          <Route path="/add_management" element={<ProtectedRoute page={<AddManagement />} />} />
          <Route path="/catalog" element={<ProtectedRoute page={<AddCatalog />} />}/>
          <Route path="/product_service" element={<ProtectedRoute page={<ProductService />} />} />
          <Route path="/view_sample_format" element={<ProtectedRoute page={<SampleFormat />} />}/>


          <Route path="/company_report/:reporting_uid?" element={<ProtectedRoute page={<CompanyReport />} />} />


          {/* <Route path="/view-management-owner" element={<ProtectedRoute page={<ViewManagement isOwner={true} />} /> */}
          <Route path="/view-management-user" element={<ProtectedRoute page={<ViewManagement isOwner={false} />} />} />
          <Route path="/product_service_list/:uid?" element={<ProtectedRoute page={<ProductList />} />} />
          <Route path="/complaints-received" element={<ProtectedRoute page={<Complaints owner={true} />} />} />
          <Route path="/complaints-submitted" element={<ProtectedRoute page={<Complaints owner={false} />} />} />
          <Route path="/add_complaints" element={<ProtectedRoute page={<ComplaintSubmission/>} />} /> 
          <Route path="/view_photos/:uid?" element={<ProtectedRoute page={<ViewPhotos />} />} />
          {/* <Route path="/photos_details" element={<ProtectedRoute page={<PhotosDetails/>} /> */}
          <Route path="/detailed_view/:id" element={<ProtectedRoute page={<DetailedView />} />} />
          <Route path="/company_detailed_view" element={<ProtectedRoute page={<SwiperGallery />} />} />
          <Route path="/add_product" element={<ProtectedRoute page={<CompanyProduct />} />}/>
          <Route path="/verification_page" element={<ProtectedRoute page={<VerificationPage />} />} />
          <Route path="/view-management/:uid?" element={<ProtectedRoute page={<ViewManagement />} />} />

          {/* <Route
            path="/feedback/owner"
            element={<ProtectedRoute page={<FeedbackView owner={true} />}
          />
          <Route
            path="/feedback/user"
            element={<ProtectedRoute page={<FeedbackView owner={false} />}
          /> */}
        <Route path="/feedback/owner/:uid?" element={<ProtectedRoute page={<FeedbackView />} />} />

          <Route path="/upload_video" element={<ProtectedRoute page={<UploadVideo />} />} />
          <Route path="/uploaded_video/:company_id?" element={<ProtectedRoute page={<UploadedVideo />} />} />
          <Route path="/company_photos" element={<ProtectedRoute page={<CompanyPhotosPage />} />} />
          <Route
  path="/photo-signboard"
  element={
    <ProtectedRoute 
      page={
        <CompanyPhotoManagement
          headingofcontent="Photo of the Company Signboard (Exterior)"
          paragraph="For official Documentation Purpose."
          nextPath="/company_photos"
          verificationType="outside"
        />
      }
    />
  }
/>

<Route
  path="/photo-premises"
  element={
    <ProtectedRoute 
      page={
        <CompanyPhotoManagement
          headingofcontent="Interior Photo of the Company"
          paragraph="For official Documentation Purpose."
          nextPath="/company_photos"
          verificationType="inside"
        />
      }
    />
  }
/>

<Route
  path="/photo-crew"
  element={
    <ProtectedRoute 
      page={
        <CompanyPhotoManagement
          headingofcontent="Group Photo of the Team"
          paragraph="For official Documentation Purpose."
          nextPath="/company_photos"
          verificationType="crew"
        />
      }
    />
  }
/>

<Route
  path="/photo-ceo"
  element={
    <ProtectedRoute 
      page={
        <CompanyPhotoManagement
          headingofcontent="CEO's Office photo"
          paragraph="For official Documentation Purpose."
          nextPath="/view_photos"
          verificationType="ceo"
        />
      }
    />
  }
/>
          <Route path="/post_request" element={<ProtectedRoute page={<PostRequest />} />} />
          
          <Route path="/profile/:company_id" element={<ProtectedRoute page={<ProfileRedirector />} />} />
          <Route path="/profile1/:company_id?" element={<ProtectedRoute page={<ProfileVisitorsView editable={true} />} />} />

          <Route path="/profile2/:company_id?" element={<ProtectedRoute page={<OtherProfileVisitorsView />} />}/>

          <Route path="/profile-verification/:uid?" element={<ProtectedRoute page={<VerificationOfProfile />} />} />
          <Route path="/verified-profile" element={<ProtectedRoute page={<VerificationOfProfile />} />}/>
          <Route path="/verified-subscription" element={<ProtectedRoute page={<VerifiedSubscriptionPlans />} />} />
          <Route path="/otp-verification" element={<ProtectedRoute page={<VerificationWithOTP />} />} />
          <Route path="/verification-success" element={<ProtectedRoute page={<VerificationSuccess />} />}/>
          <Route path="/view_product_catalog" element={<ProtectedRoute page={<ProductCatalog />} />}/>
          <Route path="/view_management" element={<ProtectedRoute page={<ViewManagement />} />}/>
          <Route path="/verified" element={<ProtectedRoute page={<Verified />} />} />
          <Route path="/edit-profile" element={<ProtectedRoute page={<EditProfile />} />} />
          <Route path="/subscription-payment" element={<ProtectedRoute page={<SubscriptionPayment />} />}/>
          <Route path="/chat_ex" element={<ProtectedRoute page={<ChatMessages />} />} />
          <Route path="/update-form" element={<ProtectedRoute page={<UpdateForm/>}  />}/>
          <Route path="/other_profile/:company_id?" element={<ProtectedRoute page={<ProfilePage2/>} />}/>
          <Route path="/follow_up" element={<ProtectedRoute page={<FollowUp/>} />} />
          {/* <Route path="/help_support" element={<ProtectedRoute page={<HelpSupport />} />} />
          <Route path="/terms_policies" element={<ProtectedRoute page={<TermsAndConditions />} />} />
          <Route path="/about_us" element={<ProtectedRoute page={<AboutUs />} />}/> */}
          <Route path="/edit_form" element={<ProtectedRoute page={<EditForm />}  />}/>
          <Route path="/add-complaints" element={<ProtectedRoute page={<AddComplaints />} />}/>
          <Route path="/posts/:company_uid?" element={<ProtectedRoute page={<PostList />} />}/>
          <Route path="/my-posts" element={<ProtectedRoute page={<MyPostsComponent />} />}/>
          <Route path="/hex-grid" element={<ProtectedRoute page={<HexGrid />} />} />
          <Route path="/social_links" element={<ProtectedRoute page={<SocialLinks />} />}/>
          <Route path="/notifications" element={<ProtectedRoute page={<Notifications />} />} />
          <Route path="/privacy_policies" element={<ProtectedRoute page={<PrivacyPolicySettings/>} />} />
          <Route path="/terms_conditions" element={<ProtectedRoute page={<TermsPolicies/>} />} />
          <Route path="/help" element={<ProtectedRoute page= {<HelpandSupport />} />} />
          <Route path="/about" element={<ProtectedRoute page= {<About />} />} />
          <Route path="/maps" element={<ProtectedRoute page= {<MapDialog/>} />} />

        </Routes>
      </Elements>
    
    </>
  );
}

export default App;