
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Sidebar from '../../Components/main_navbar';
import StarRating from '../../Components/star_rating';
import { get_company_bookmarks } from '../../../Controller/bookmarks.js'; // Adjust the import as per your structure
import { FaSpinner } from 'react-icons/fa';

const FollowUp = () => {
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    const fetchBookmarks = async () => {
      try {
        const companyUid = localStorage.getItem('company_uid'); // Fetch company_uid from localStorage
        console.log('Fetching bookmarks for companyUid:', companyUid);
        const response = await get_company_bookmarks(companyUid); // Fetch data using company_uid
        console.log('API Response:', response.data);
        if (response.data && Array.isArray(response.data.data)) {
          setBookmarks(response.data.data); // Set the bookmarks
        }
      } catch (error) {
        console.error('Error fetching bookmarks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookmarks();
  }, []);

  const handleSelectMessage = (id) => {
    setSelectedMessageId(id);
  };

  const handleNavigateToProfile = (companyId) => {
    navigate(`/profile2/${companyId}`); // Updated route to match your profile route
  };

  if (loading) {
    return <div className="flex justify-center items-center h-full">
    <FaSpinner className="animate-spin text-gray-500" size={30} />
  </div>
  }

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">Follow Up</h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 justify-items-center">
        <div className="mt-5 lg:w-3/4 mx-2 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 lg:ml-32">
          {bookmarks.length === 0 ? (
            <div className="flex items-center justify-center w-full h-screen col-span-3">
              <p className="text-gray-500 text-xl">No data to show.</p>
            </div>
          ) : (
            bookmarks.map((bookmark) => (
              <div
                key={bookmark.company_id}
                className={`w-full h-60 content-evenly rounded-lg shadow-sm bg-[#F2F2F2] border ${
                  selectedMessageId === bookmark.company_id ? 'border-red-500' : ''
                } cursor-pointer`}
                onClick={() => handleNavigateToProfile(bookmark.company_id)} // Navigate to profile page
              >
                <div className="flex flex-col items-center">
                  {/* Display the company logo */}
                  <img
                    src={bookmark.logo}
                    alt={bookmark.company_name}
                    className="h-16 w-16 rounded-full mb-4"
                  />
                  {/* Display company name */}
                  <h2 className="font-semibold text-center">{bookmark.company_name}</h2>
                  {/* Display company ID */}
                  <p className="text-sm text-gray-600 text-center">ID: {bookmark.company_id}</p>
                  {/* Display rating */}
                  <div className="flex mt-2">
                    <StarRating rating={bookmark.rating} />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default FollowUp;
