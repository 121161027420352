

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReportContainer from "../../Components/report";
import Modal from "../../Components/pdf_opener";

const categories = [
  { id: 1, key: 'logistics', smallUrl: './logisticsmobile.svg', largeUrl: '/Assets/test.svg' },
  { id: 2, key: 'legal', smallUrl: './legal_mobile.svg', largeUrl: 'Assets/legalll.svg' },
  { id: 3, key: 'finance', smallUrl: './finance_small.svg', largeUrl: '/Assets/financeesvg.svg' },
  { id: 4, key: 'start_up', smallUrl: './start-up-mobile.svg', largeUrl: '/Assets/start-up-new-3.svg' },
  { id: 5, key: 'jewelry', smallUrl: './jewelry_small.svg', largeUrl: 'Assets/jewelryy.svg' },
  { id: 6, key: 'seller', smallUrl: './seller-mobile.svg', largeUrl: 'Assets/seller-new-7.svg' },
  { id: 7, key: 'investor', smallUrl: './investor-mobile.svg', largeUrl: './new-investor.svg' },
  { id: 8, key: 'buyer', smallUrl: './buyermobile35827.svg', largeUrl: 'Assets/buyer-new-9.svg' },
  { id: 9, key: 'market_place', smallUrl: './marketplacemobile.svg', largeUrl: '/Assets/market-place-new-8.svg' },
  { id: 10, key: 'projects', smallUrl: './projects(2).svg', largeUrl: './new-projects.svg' }
];

const TradeRolePageSelection = () => {
  const [imageUrls, setImageUrls] = useState(() => {
    const initialUrls = {};
    categories.forEach(category => {
      initialUrls[category.key] = window.innerWidth <= 768 ? category.smallUrl : category.largeUrl;
    });
    return initialUrls;
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { completed } = location.state || { completed: [] };

  useEffect(() => {
    const handleResize = () => {
      const newUrls = {};
      categories.forEach(category => {
        newUrls[category.key] = window.innerWidth <= 768 ? category.smallUrl : category.largeUrl;
      });
      setImageUrls(newUrls);
    };


    const handleBackNavigation = (event) => {
      event.preventDefault(); // Prevent the default back navigation behavior
      const confirmRedirect = window.confirm("Are you sure you want to redirect to the login page?");
      if (confirmRedirect) {
        navigate("/login"); // Navigate to the login page if the user confirms
      } else {
        window.history.pushState(null, null, window.location.pathname); // Keep the user on the same page
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [completed]);

  const handleNavigation = (key) => {
    const selectedCategory = categories.find(category => category.key === key);
    if (selectedCategory) {
      localStorage.setItem('category', selectedCategory.id); // Store category ID
      localStorage.setItem('category_key', selectedCategory.key);
      navigate('/category-listings', { state: { completed } });
    }
  };

  const handlePdfClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="bg-[#FBFCF8] min-h-screen p-4 overflow-y-auto">
      {/* Updated Header Section */}
      <div className="ml-5 mt-4">
        <h1 className="text-2xl font-bold text-black">Select Trade Role</h1>
        <p className="text-gray-600 mt-2">Choose a category </p>
      </div>
      <div className="absolute top-5 right-6 flex items-center">
        {/* New Image for PDF Link */}
      
        <img 
          src="/Assets/info2.svg" 
          alt="info Icon"
          title="Trade Form"
          className="cursor-pointer mr-2 lg:mr-6 h-6 w-6 lg:h-12 lg:w-10" 
          onClick={handlePdfClick} 
        />
      
        <ReportContainer />
      </div>
      <div className="p-0 mt-10">
        <div className="hex-grid-container-wrapper">
          <div className="hex-grid-container">
            {categories.map((category) => (
              <div
                key={category.key}
                className={`hex-item ${category.key}`}
                onClick={() => handleNavigation(category.key)}
              >
                <img src={imageUrls[category.key]} alt={category.key} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal for PDF Display */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="Trade Form">
        <iframe
          src="https://firebasestorage.googleapis.com/v0/b/eswaf-d4041.appspot.com/o/tradeRoleFrom%2FTrade-Form%20(4).pdf?alt=media&token=c7ae9916-f205-4b1e-bc48-7c98e9949a09"
          title="Trade Form PDF"
          className="w-full h-full"
          frameBorder="0"
        ></iframe>
      </Modal>
    </div>
  );
};

export default TradeRolePageSelection;

