


import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchMediaImages, deleteMediaById } from '../../Controller/company_products';
import DeletePopup from './delete_pop_up';
import { FaSpinner } from 'react-icons/fa';

const ShowcaseGrid = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0); // Initialize skip for pagination
  const navigate = useNavigate();
  const { uid } = useParams();
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [showDeleteOption, setShowDeleteOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);
  const observerRef = useRef();
  const ITEMS_PER_PAGE = 6; // Set the limit to fetch 6 items at a time

  // Fetch images function
  const fetchImages = async (uidToUse, skipValue) => {
    try {
      setLoadingMore(true); // Show loading spinner when loading more images
      const data = await fetchMediaImages(uidToUse, skipValue); // Fetching with skip
      if (Array.isArray(data) && data.length > 0) {
        setItems((prevItems) => [...prevItems, ...data]); // Append new items
        setSkip((prevSkip) => prevSkip + ITEMS_PER_PAGE); // Increment skip value
        setHasMore(data.length === ITEMS_PER_PAGE); // If we received less than 6, stop further loading
      } else {
        setHasMore(false); // No more data to load
      }
    } catch (error) {
      console.error('Error fetching media images:', error);
    } finally {
      setLoading(false); // Hide initial loading spinner
      setLoadingMore(false); // Hide loading spinner for loading more images
    }
  };

  // Load more images on scroll (infinite scrolling)
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loadingMore) {
        fetchImages(uid || localStorage.getItem('company_uid'), skip);
      }
    });

    if (observerRef.current) {
      observer.observe(observerRef.current); // Start observing
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current); // Clean up observer on unmount
      }
    };
  }, [hasMore, loadingMore, skip, uid]);

  useEffect(() => {
    const checkAndFetchProfileImages = async () => {
      const company_uid = localStorage.getItem('company_uid');
      const status = localStorage.getItem('verificationStatus'); 
      setVerificationStatus(status);

      if (uid) {
        setIsMyProfile(false);
        fetchImages(uid, 0); // Fetch initial images for other profile
      } else if (company_uid) {
        setIsMyProfile(true);
        fetchImages(company_uid, 0); // Fetch initial images for own profile
      } else {
        console.error('No uid in URL and company_uid not found in localStorage');
        setLoading(false);
      }
    };

    checkAndFetchProfileImages();
  }, [uid]);

  const handleUploadClick = () => {
    navigate('/add_product');
  };

  const handleDotClick = (index) => {
    setShowDeleteOption(index === showDeleteOption ? null : index);
  };

  const handleDeleteClick = (id) => {
    setSelectedPhotoId(id);
    setShowPopup(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteMediaById(selectedPhotoId);
      if (response.status === 200) {
        console.log(response);
        setItems((prevItems) => prevItems.filter(item => item.id !== selectedPhotoId));
        setShowPopup(false);
        setShowDeleteOption(null);
      } else {
        console.error('Error deleting media:', response);
      }
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedPhotoId(null);
  };

  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return `${title.slice(0, maxLength)}...`;
    }
    return title;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full w-full overflow-auto">
        <FaSpinner className="animate-spin text-gray-500" size={20} />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 font-poppins">
      <div className="flex justify-between items-center mb-2">
        <h2 className="lg:text-lg text-sm font-poppins font-semibold">Product Showcase</h2>
        {isMyProfile && verificationStatus !== 'VERIFICATION_PENDING' &&  verificationStatus !== 'VERIFICATION_REJECTED' && (
          <img
            src="/Assets/review.svg"
            alt="Upload"
            className="cursor-pointer h-5 w-5"
            onClick={handleUploadClick}
          />
        )}
      </div>
      
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-2">
        {items.length > 0 ? (
          items.map((item, index) => (
            <div key={index} className="relative">
              <Link to={`/detailed_view/${item.id}`} state={{ item }}>
                <div className="border rounded-lg shadow-lg">
                  {item.image_url && item.image_url.length > 0 ? (
                    <img
                      src={item.image_url[0]}
                      alt={`Showcase ${index}`}
                      className="w-full h-40 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="w-full h-40 flex items-center justify-center bg-gray-200">
                      No Image Available
                    </div>
                  )}
                  <p className="text-center text-sm rounded" style={{
                    background: 'linear-gradient(0deg, #000000 58.85%, rgba(255, 255, 255, 0) 100%)',
                    color: 'white', padding: '5px'
                  }}>
                    {truncateTitle(item.title, 12)}
                  </p>
                </div>
              </Link>
              {isMyProfile && (
                <>
                  <img
                    src="/Assets/dots.svg"
                    alt="More Options"
                    className="absolute top-2 right-2 w-3 h-3 cursor-pointer"
                    onClick={() => handleDotClick(index)}
                  />
                  {showDeleteOption === index && (
                    <div
                      className="absolute top-8 right-2 bg-white text-red-600 p-2 rounded-lg shadow-md cursor-pointer"
                      onClick={() => handleDeleteClick(item.id)}
                    >
                      Delete
                    </div>
                  )}
                </>
              )}
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-500 w-full lg:mt-10 lg:w-[80vw] mt-16">No Photos to show !</div>
        )}
      </div>

      {loadingMore && (
        <div className="flex justify-center items-center mt-4">
          <FaSpinner className="animate-spin text-gray-500" size={20} />
        </div>
      )}

      {hasMore && <div ref={observerRef} style={{ height: '20px' }} />}

      {showPopup && (
        <DeletePopup
          message="Are you sure you want to delete this item?"
          onConfirm={handleConfirmDelete}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default ShowcaseGrid;
