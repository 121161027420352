import React from 'react';

import { useNavigate } from 'react-router-dom';

const NoRefundPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8]">
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] lg:w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] text-[16px] lg:text-xl font-poppins font-semibold">
            No Refund Policy
          </h1>
        </div>
      </div>
     
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col gap-4 md:gap-[25px] p-6 items-center w-full overflow-y-auto">
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full max-w-[750px] mt-6">
          <h2 className="text-black font-bold text-lg">No Refund Policy</h2>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Thank you for choosing our services. We want to ensure complete transparency regarding our policies.
          </p>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Please note that <strong>all subscriptions are  non-refundable</strong>. Once a subscription is purchased, no refunds  will be processed under any circumstances.
          </p>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            By subscribing to our service, you acknowledge and agree to this No Refund Policy.
          </p>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            For any questions or concerns, feel free to contact our support team at <strong>support@eSwaF.com</strong>.
          </p>
          <p className="text-[#171717] mt-2 text-[14px] lg:text-[16px]">
            Thank you for your understanding and support.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoRefundPolicy;
