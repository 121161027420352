
// import React, { useState } from 'react';
// import Sidebar from '../../Components/main_navbar';
// import CompanyPhotos from '../../Components/company_photos';
// import ShowcaseGrid from '../../Components/showcase';
// import { useNavigate } from 'react-router-dom';
// import VerificationPhotos from '../../Components/verification_photos';

// const ViewPhotos = () => {
//     const navigate = useNavigate();

//     const handleBackClick = () => {
//       navigate('');
//     };
  
//   return (
//     <div className="flex flex-col w-full h-full bg-[#FFFFFF] font-poppins">
//     <Sidebar />
//     <div className="flex justify-start items-center ml-8 mt-4 md:mt-[40px]">
//       <div className="flex items-center space-x-2">
        
//        <h1 className="text-black font-semibold sm:text-lg lg:text-xl">Post</h1>  
//         </div>
//       </div>
//     <div className="border-b border-black mt-4 md:mt-[25px]"></div>
   

//     <div className=' '>
//     <div className="lg:w-2/5 mb-8">
//           <CompanyPhotos />
//         </div>
//       {/* <div className=" w-full md:w-3/4 "> */}
//       <div className="w-full lg:w-3/4 h-[calc(100vh-200px)] overflow-y-auto">
//         <ShowcaseGrid />
//       </div>
//     </div>
//   </div>
//   );
// };

// export default ViewPhotos;

import React from 'react';
import Sidebar from '../../Components/main_navbar';
import CompanyPhotos from '../../Components/company_photos';
import ShowcaseGrid from '../../Components/showcase';
import { useNavigate } from 'react-router-dom';

const ViewPhotos = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full bg-white font-poppins">
      <Sidebar />
      
      {/* Header */}
      <div className="flex-none">
        <div className="flex justify-start items-center ml-8 mt-4 md:mt-10">
          <h1 className="text-black font-semibold sm:text-lg lg:text-xl">Post</h1>
        </div>
        <div className="border-b border-black mt-4 md:mt-6"></div>
      </div>

      {/* Main Content Area */}
      <div className="flex flex-col flex-1 overflow-hidden">
        {/* Company Photos - Fixed Height */}
        <div className="flex-none lg:w-2/5 ">
          <CompanyPhotos />
        </div>

        {/* Showcase Grid - Scrollable */}
        <div className="flex-1 overflow-y-auto  ">
          <div className="w-full md:w-[80%] 4k:w-[88%] ">
            <ShowcaseGrid />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPhotos;