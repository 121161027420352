
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../Controller/auth";
import DeletePopup from "./delete_pop_up";
import { FaSpinner } from 'react-icons/fa'; // If needed for loading icons
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase_service'; // Ensure your Firebase service is imported
import { useLocation } from "react-router-dom";

const SettingsSidebar = ({ closeSettings }) => {
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(""); // State for verification status
  const navigate = useNavigate();
  const location = useLocation();

  // Fetch verificationStatus from localStorage on component mount
  useEffect(() => {
    const status = localStorage.getItem('verificationStatus');
    setVerificationStatus(status);
  }, []);

  // Function to update user status to 'Offline' in Firestore
  const updateUserStatusToOffline = async () => {
    const currentUserUid = localStorage.getItem('company_uid');
    if (currentUserUid) {
      try {
        const userRef = doc(db, 'users', currentUserUid);
        await updateDoc(userRef, {
          status: 'Offline',
          lastOnlineAt: serverTimestamp(),
        });
      } catch (error) {
        console.error('Error updating user status to Offline:', error);
      }
    }
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await updateUserStatusToOffline();
      const response = await logout();
      if (response.status === 200) {
        localStorage.clear();
        navigate("/login");
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoutClick = () => {
    setShowLogoutPopup(true);
  };

  const confirmLogout = () => {
    handleLogout();
  };

  const closePopup = () => {
    setShowLogoutPopup(false);
  };

  const handleCloseSettings = () => {
    setShowLogoutPopup(false);
    closeSettings();
  };

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };


  const handleSubscriptionNavigation = () => {
    const isActiveSubscriber = localStorage.getItem('active_subscriber') === 'true';
    if (isActiveSubscriber) {
      navigate("/my_subscription");
    } else {
      navigate("/subscription_plans");
    }
  };

  return (
    <div className="fixed top-0 right-0 h-full w-64 bg-[#171717] text-white transition-width duration-300 z-20 font-poppins" >
      <button onClick={closeSettings} className="text-3xl m-4">
        &times;
      </button>
      <div className="flex flex-col items-center ml-5 mb-5 mt-5 mr-5 space-y-4 font-normal">
        
        <div className="w-full px-4 bg-[#F0F0F0] text-[#000000] rounded-lg p-4">
          <Link to="/account_details" className="flex items-center">
            <div className={`hover-group w-full ${isCurrentPath('/account_details') ? 'text-[#DB0000]' : ''}`}>
              <button
                className={`w-full flex items-center text-left ${isCurrentPath('/account_details') ? 'text-[#DB0000]' : 'hover:text-[#DB0000]'}`}
              >
                <img 
                  src="/Assets/account.svg" 
                  alt="Account Icon" 
                  className={`w-6 h-6 inline-block mr-4 settings-icon ${isCurrentPath('/account_details') ? 'filter-red' : ''}`} 
                />
                Account
              </button>
            </div>
          </Link>

          {/* Conditionally render Subscription based on verificationStatus */}
          {verificationStatus === "VERIFICATION_COMPLETED" && (
            <button onClick={handleSubscriptionNavigation} className="flex items-center mt-3 w-full">
            <div className={`hover-group w-full ${isCurrentPath('/my_subscription') ? 'text-[#DB0000]' : ''}`}>
              <button
                className={`w-full flex items-center text-left ${isCurrentPath('/my_subscription') ? 'text-[#DB0000]' : 'hover:text-[#DB0000]'}`}
              >
                <img 
                  src="/Assets/subscription_icon.svg" 
                  alt="Subscription Icon" 
                  className={`w-5 h-5 inline-block mr-4 settings-icon ${isCurrentPath('/my_subscription') ? 'filter-red' : ''}`} 
                />
                Subscription
              </button>
            </div>
          </button>
          )}
        </div>

        <div className="w-full px-4 bg-[#F0F0F0] text-[#000000] rounded-lg p-4">
          <Link to="/privacy_policies" className="flex items-center">
            <div className={`hover-group w-full ${isCurrentPath('/privacy_policies') ? 'text-[#DB0000]' : ''}`}>
              <button
                className={`w-full flex items-center text-left ${isCurrentPath('/privacy_policies') ? 'text-[#DB0000]' : 'hover:text-[#DB0000]'}`}
              >
                <img 
                  src="/Assets/privacy_policy.svg" 
                  alt="Privacy Policy Icon" 
                  className={`w-5 h-5 inline-block mr-4 settings-icon ${isCurrentPath('/privacy_policies') ? 'filter-red' : ''}`} 
                />
                Privacy Policy
              </button>
            </div>
          </Link>

          <Link to="/help" className="flex items-center mt-4">
            <div className={`hover-group w-full ${isCurrentPath('/help') ? 'text-[#DB0000]' : ''}`}>
              <button
                className={`w-full flex items-center text-left ${isCurrentPath('/help') ? 'text-[#DB0000]' : 'hover:text-[#DB0000]'}`}
              >
                <img 
                  src="/Assets/help_support.svg" 
                  alt="Help & Support Icon" 
                  className={`w-5 h-5 inline-block mr-4 settings-icon ${isCurrentPath('/help') ? 'filter-red' : ''}`} 
                />
                Help & Support
              </button>
            </div>
          </Link>

          <Link to="/terms_conditions" className="flex items-center mt-4">
            <div className={`hover-group w-full ${isCurrentPath('/terms_conditions') ? 'text-[#DB0000]' : ''}`}>
              <button
                className={`w-full flex items-center text-left ${isCurrentPath('/terms_conditions') ? 'text-[#DB0000]' : 'hover:text-[#DB0000]'}`}
              >
                <img 
                  src="/Assets/terms_policies.svg" 
                  alt="Terms & Policies Icon" 
                  className={`w-5 h-5 inline-block mr-4 settings-icon ${isCurrentPath('/terms_conditions') ? 'filter-red' : ''}`} 
                />
                Terms & Policies
              </button>
            </div>
          </Link>

          <Link to="/about" className="flex items-center mt-4">
            <div className={`hover-group w-full ${isCurrentPath('/about') ? 'text-[#DB0000]' : ''}`}>
              <button
                className={`w-full flex items-center text-left ${isCurrentPath('/about') ? 'text-[#DB0000]' : 'hover:text-[#DB0000]'}`}
              >
                <img 
                  src="/Assets/about_us_icon.svg" 
                  alt="About Us Icon" 
                  className={`w-5 h-5 inline-block mr-4 settings-icon ${isCurrentPath('/about') ? 'filter-red' : ''}`} 
                />
                About-Us
              </button>
            </div>
          </Link>
        </div>

        <div className="w-full px-4 bg-[#F0F0F0] text-[#000000] rounded-lg p-4">
          <Link to="/report_page"  state={{ heading: "Contact Support" }} className="flex items-center">
            <div className={`hover-group w-full ${isCurrentPath('/report_page') ? 'text-[#DB0000]' : ''}`}>
              <button
                className={`w-full flex items-center text-left ${isCurrentPath('/report_page') ? 'text-[#DB0000]' : 'hover:text-[#DB0000]'}`}
              >
                <img 
                  src="/Assets/report_issue.svg" 
                  alt="Report a Problem Icon" 
                  className={`w-5 h-5 inline-block mr-4 settings-icon ${isCurrentPath('/report_page') ? 'filter-red' : ''}`} 
                />
                Contact Support
              </button>
            </div>
          </Link>

          <div className="flex items-center mt-4">
            <div className={`hover-group w-full ${isCurrentPath('/logout') ? 'text-[#DB0000]' : ''}`}>
              <button
                onClick={handleLogoutClick}
                className={`w-full flex items-center text-left ${isCurrentPath('/logout') ? 'text-[#DB0000]' : 'hover:text-[#DB0000]'}`}
              >
                <img 
                  src="/Assets/logout.svg" 
                  alt="Log Out Icon" 
                  className={`w-5 h-5 inline-block mr-4 settings-icon ${isCurrentPath('/logout') ? 'filter-red' : ''}`} 
                />
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>

      {showLogoutPopup && (
        <DeletePopup
          message="Are you sure you want to log out?"
          onConfirm={confirmLogout}
          onClose={closePopup}
          isLoading={isLoading}
        />
      )}

      <style jsx>{`
        .settings-icon {
          transition: filter 0.2s;
        }
        .filter-red {
          filter: brightness(0) saturate(100%) invert(12%) sepia(88%) saturate(5735%) hue-rotate(357deg) brightness(96%) contrast(115%);
        }
        .hover-group:hover .settings-icon {
          filter: brightness(0) saturate(100%) invert(12%) sepia(88%) saturate(5735%) hue-rotate(357deg) brightness(96%) contrast(115%);
        }

        
      `}</style>
    </div>
  );
};

export default SettingsSidebar;
