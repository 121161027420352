// locationController.js
import { showToast } from '../View/Components/toast';
import { get_country_code } from './auth';
import { postLocation } from './auth';
export const fetchCountries = async (continent, setLoading, setCountries) => {
  setLoading(true);
  try {
    const response = await get_country_code(continent);
    console.log(response)

    if (response.status === 200) {
      setCountries(response.data.data);
    } else {
      // showToast('Error fetching countries', 'error');
    }
  } catch (error) {
    console.error('Error fetching countries:', error);
    // showToast('Error fetching countries', 'error');
  } finally {
    setLoading(false);
  }
};

// export const saveLocation = async (navigate, completed, setLoading) => {
//   const storedContinent = localStorage.getItem('continent');
//   const storedCountry = localStorage.getItem('country');

//   if (!storedCountry) {
//     showToast('Please select a country.', 'error');
//     return;
//   }

//   setLoading(true);

//   const payload = {
//     continent: storedContinent,
//     country: storedCountry
//   };

//   try {
//     const response = await postLocation(payload);
//     if (response.status === 202) {
//       navigate('/trade-selection', { state: { completed } });
//     } else {
//       // showToast('Error saving location. Please try again.', 'error');
//     }
//   } catch (error) {
//     console.error('Error saving location:', error);
//     // showToast('Error saving location. Please try again.', 'error');
//   } finally {
//     setLoading(false);
//   }
// };
export const saveLocation = async (navigate, completed, setLoading) => {
  const storedContinent = localStorage.getItem('continent');
  const storedCountry = localStorage.getItem('country');

  if (!storedCountry) {
    showToast('Please select a country.', 'error');
    return;
  }

  setLoading(true);

  const payload = {
    continent: storedContinent,
    country: storedCountry
  };

  try {
    const response = await postLocation(payload);
    if (response.status === 202) {
      // First navigation to trade-selection
      navigate('/trade-selection', { state: { completed }, replace: true });

      // Setup back button handler after navigation
      const handleBackButton = async (e) => {
        // Prevent the default back action
        window.history.pushState(null, '', window.location.pathname);
        
        const confirmResult = window.confirm('Are you sure you want to go back. ');
        if (confirmResult) {
          // Remove listener before navigating to prevent multiple triggers
          window.removeEventListener('popstate', handleBackButton);
          navigate('/login', { replace: true });
        }
      };

      // Add initial history state
      window.history.pushState(null, '', window.location.pathname);
      
    } else {
      showToast('Error saving location. Please try again.', 'error');
    }
  } catch (error) {
    console.error('Error saving location:', error);
    showToast('Error saving location. Please try again.', 'error');
  } finally {
    setLoading(false);
  }
};