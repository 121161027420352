


import React, { useState } from 'react';
import Sidebar from '../../Components/main_navbar';
import CatalogPicker from '../../Components/catalog_picker';
import Button from '../../Components/button';
import { useNavigate, useLocation } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import UploadDocuments from '../../../Controller/firebase/firebase_documents';
import { showToast, Toast } from '../../Components/toast';
import { update_catalogue_api } from '../../../Controller/company_details';
import { FiEdit2 } from 'react-icons/fi'; // Import the edit icon
import LoaderSpinner from '../../Components/loader_spinner';
import Modal from '../../Components/pdf_opener';

const AddCatalog = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Get the location object
  const [catalogURL, setCatalogURL] = useState(location.state?.catalogueURL || '');
  const [uploading, setUploading] = useState(false); // State for file uploading
  const [loading, setLoading] = useState(false); // State for save button loading
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
const [pdfUrl, setPdfUrl] = useState(null);




  const handleFileSelect = async (file) => {
    // Ensure only PDF files can be uploaded
    if (file.type !== 'application/pdf') {
      showToast('Only PDF files are allowed.', 'error');
      return;
    }

    setUploading(true); // Show spinner while uploading
    try {
      const downloadURL = await UploadDocuments(file);
      console.log('File uploaded successfully:', downloadURL);
      setCatalogURL(downloadURL);
      showToast('File uploaded successfully.', 'success');
    } catch (error) {
      console.error('Error uploading file:', error);
      showToast('Error uploading file.', 'error');
    } finally {
      setUploading(false); // Hide spinner after upload
    }
  };

  const handleSaveClick = async () => {
    if (!catalogURL) {
      showToast('Please upload a catalog file before saving.', 'error');
      return;
    }

    setLoading(true); // Set loading state to true when save process starts
    const payload = {
      catalogue: catalogURL,
    };

    try {
      const response = await update_catalogue_api(payload);
      console.log('Documents updated successfully:', response);
      showToast('Catalog saved successfully.', 'success');
      navigate('/profile1');
    } catch (error) {
      console.error('Error updating documents:', error);
      showToast('Error saving catalog.', 'error');
    } finally {
      setLoading(false); // Reset loading state after save process completes
    }
  };

  const handleViewClick = () => {
    setPdfUrl(catalogURL);
    setIsPdfModalOpen(true);
  };
  

  const handleReuploadClick = () => {
    // Simulate file selection again without showing the initial upload screen
    document.getElementById('catalog-file-input').click();
  };

  return (
    <div className="flex flex-col w-full h-full min-h-screen bg-[#FBFCF8] font-poppins">
      <Sidebar />
      <Toast />
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">
            {catalogURL ? 'Edit Catalogue' : 'Catalogue'}
          </h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex flex-col 4k:w-[88%] lg:w-4/5 md:w-3/4 h-full pb-8">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-2 rounded-lg mt-7">
          {!catalogURL ? (
            <div className="flex justify-center items-center flex-grow">
              <CatalogPicker height="460px" width="460px" onFileSelect={handleFileSelect} loading={uploading} />
            </div>
          ) : (
            <div className="relative bg-[#F2F2F2] lg:p-8 p-8 rounded-lg">
              <div className="flex items-center">
                <img src="/Assets/pdf.svg" alt="PDF Icon" className="w-8 h-8 mr-2" />
                <span className="text-black font-semibold ml-3">Document</span>
                <button className="text-red-600 ml-auto" onClick={handleReuploadClick}>
                  <FiEdit2 size={20} />
                </button>
                <button className="ml-5 text-red-600 font-semibold underline" onClick={handleViewClick}>
                  View
                </button>
              </div>
              {/* Loader Spinner while re-uploading */}
              <LoaderSpinner visible={uploading} height={20} />
              <input
                type="file"
                id="catalog-file-input"
                className="hidden"
                accept="application/pdf"
                onChange={(e) => handleFileSelect(e.target.files[0])}
              />
            </div>
          )}
        </div>
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-2 mt-10 4k:w-[25%]">
          <Button
            text={catalogURL ? 'Update' : 'Save'}
            borderColor="border-red-600"
            backgroundColor="bg-[#DB0000]"
            textColor="text-white"
            onClick={handleSaveClick}
            loading={loading}
          />
        </div>
        <Modal isOpen={isPdfModalOpen} onClose={() => setIsPdfModalOpen(false)} title="Catalogue">
  <iframe src={pdfUrl} className="w-full h-full" title="Catalogue PDF" />
</Modal>

      </div>
    </div>
  );
};

export default AddCatalog;


