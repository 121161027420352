import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GoldSupplier from '../../Components/gold_supplier';
import Sidebar from '../../Components/main_navbar';
import { get_subscriptions_api } from '../../../Controller/subscriptions';
import { FaSpinner } from 'react-icons/fa';
import { showToast, Toast } from '../../Components/toast';

const SubscriptionPlans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Show toast if paymentFailed state is passed as true
  useEffect(() => {
    if (location.state?.paymentFailed === true) {
      showToast('Payment failed. Please try again.', 'error');
    }
  }, [location.state?.paymentFailed]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await get_subscriptions_api();
        if (response.status === 200) {
          setPlans(response.data.data);
        } else {
          showToast('Failed to fetch subscriptions', 'error');
        }
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        showToast('An error occurred while fetching subscriptions', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleGoldSupplierClick = (product_id, backgroundColor) => {
    navigate('/subscription_details', { state: { product_id, backgroundColor } });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="text-xl animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full bg-[#FFFFFF] overflow-y-auto">
      {/* Toast Component */}
      <Toast />
      
      <div className="flex ml-7 mt-4 w-4/5">
        <Sidebar />
        <div className="w-full flex justify-start items-center space-x-2 font-poppins">
         
          <h1 className="text-black font-bold sm:text-lg lg:text-xl">Subscription Plans</h1>
        </div>
        <div className="lg:mr-5 md:mr-7 pr-5 flex self-end">
          <img
            src="/Assets/crown.svg"
            alt="crown"
            className="h-8 w-8"
          />
        </div>
      </div>

      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex justify-center w-4/5 4k:w-[88%] lg:ml-5 mx-auto overflow-auto p-4">
        <div className='flex flex-col space-y-4 w-full mt-2 md:px-0 max-w-lg'>
          <p className="font-semibold text-base sm:text-lg">Plans</p>
          {plans.map((plan, index) => {
  const isYearlyPlan = plan.name.toLowerCase().includes('yearly'); // Adjust condition if there's a specific field
  const backgroundColor = isYearlyPlan
    ? "#E5E5E5" // Dark gray for yearly plans
    : index % 2 === 0
    ? "#E5E5E5" // Light gray
    : "#A2A2A2"; // Medium gray

  const textColor = isYearlyPlan
    ? "#121212" // White text for dark gray background
    : index % 2 === 0
    ? "#171717"
    : "#F9F9F9";

  return (
    <GoldSupplier
      key={plan.product_id}
      backgroundColor={backgroundColor}
      textColor={textColor}
      title={`${plan.name}`}
      price={`${plan.price}.0 $`}
      height="170px"
      width="full"
      onClick={() =>
        handleGoldSupplierClick(plan.product_id, backgroundColor)
      }
    />
  );
})}

        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
