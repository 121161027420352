// import React from 'react';
// import Sidebar from '../Components/MainNavbar';
//import LeftSidebar from '../Components/left_sidebar';
// import ActivePlans from '../Components/active_plans';

// const Subscription = () => {
//   return (
//     <div className="h-full overflow-hidden">
//     <div className="w-full flex mt-11 ml-12">
//         <h1 className=" text-2xl font-normal leading-[30.14px] text-black">My Subscription</h1>
//     </div>
//     <div className="w-full h-0 mt-6 border-t border-black"></div>
//     <ActivePlans/>
//     <div>
//         <button className="text-red-500 font-bold py-2 px-4 rounded" style={{ width: '420px', height: '60px', top: '641px', left: '198px', gap: '0px', borderRadius: '8px ', border: '1px solid #DB0000' }}>
//           Other Subscription Plans
//         </button>
//         </div>
//     </div>
         
      

//       /*<div className="flex justify-between items-center pt-">
//         <h1 className="font-techna text-[24px] font-normal leading-[30.14px]">
//           My Subscription
//         </h1>
//       </div>
//       <div className="border-b border-black py-6 "></div>
//       {/* Rest of the content */
     
      
      
      
      
    
    
//   );
// };

// export default Subscription;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CurrentPlan from '../../Components/current_plan';
import Sidebar from '../../Components/main_navbar';
import Button from '../../Components/button';
import { getSubscriptionByCompany, deleteplan } from '../../../Controller/subscriptions';
import DeletePopup from '../../Components/delete_pop_up';

const Subscription = () => {
  const [plan, setPlan] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSubscriber, setActiveSubscriber] = useState(true);
  const [isCancelled, setIsCancelled] = useState(false); 
  const [viewPlansLoading, setViewPlansLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();

  useEffect(() => {
    const subscriberStatus = localStorage.getItem('active_subscriber') === 'true';
    console.log('Subscriber Status from localStorage:', subscriberStatus); // Log the subscriber status
    setActiveSubscriber(subscriberStatus);
  
    const fetchCurrentPlan = async () => {
      try {
        const uid = localStorage.getItem('company_uid');
        if (!uid) {
          console.error('UID not found in localStorage');
          return;
        }
  
        // Fetch the current plan data
        const response = await getSubscriptionByCompany(uid);
        console.log('Subscription API response:', response); // Log the response from the API
        if (response?.status_code === 200) {
          const planData = response.data;
          console.log('Plan Data:', planData); // Log the plan data
          setPlan(planData);
          setIsCancelled(planData.is_cancelled); // Set cancellation status from API response
        }
      } catch (error) {
        console.error('Error fetching current plan:', error);
      }
    };
  
    fetchCurrentPlan();
  }, []);
  

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleCancelClick = () => {
    setShowPopup(true);
  };

  const cancelPlan = async () => {
    setIsLoading(true);
    const uid = localStorage.getItem('company_uid');

    if (!uid) {
      console.error('Company UID not found in localStorage');
      setIsLoading(false);
      return;
    }

    try {
      const response = await deleteplan(uid);
      if (response?.status === 200) {
        setIsCancelled(true);
      }
      setShowPopup(false);
      setIsLoading(false);
      navigate('/my_subscription');
    } catch (error) {
      console.error('Error cancelling plan:', error);
      setIsLoading(false);
    }
  };

  const handleViewPlansClick = async () => {
    setViewPlansLoading(true);
    try {
      // Your async operation here
      await new Promise(resolve => setTimeout(resolve, 1000)); // Example delay
      navigate('/subscription_plans');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setViewPlansLoading(false);
    }
  };


  return (
    <div className="flex flex-col w-full h-screen bg-[#F2F3F3]">
  <Sidebar />

  {/* Render simplified UI for inactive subscribers */}
  {!activeSubscriber ? (
    <div className="flex justify-center items-center w-4/5 h-full">
      <div className="flex flex-col justify-center items-center w-1/2">
        <p className="text-gray-500 text-center mb-4">
          Upgrade to premium today for an enhanced experience and unlock exclusive features!
        </p>
        <Button
          text="View Subscription Plans"
          borderColor="border-red-600"
          textColor="text-red-600"
          fontWeight="semibold"
          loading={viewPlansLoading}
          loaderColor="#DB0000"
          onClick={handleViewPlansClick}
        />
      </div>
    </div>
      
      
      ) : (
        // Render full UI for active subscribers
        <>
          <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
            <div className="w-full flex justify-start items-center space-x-2 font-poppins">
             
              <h1 className="text-black font-poppins font-semibold sm:text-lg lg:text-xl">Subscription</h1>
            </div>
          </div>
          <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>
<div className="w-full md:w-[600px] h-auto md:h-[700px] mt-7 p-4 md:ml-64 4k:ml-0 4k:mt-0 4k:absolute 4k:top-1/2 4k:left-1/2 4k:transform 4k:-translate-x-1/2 4k:-translate-y-1/2">
  <p className="font-normal font-poppins text-base lg:text-xl text-sm">Active Plans</p>
  <CurrentPlan plan={plan} />

  {/* Conditionally render the "Cancel Plan" link */}
  {!isCancelled && (
    <div className="text-right mt-7">
      <a
        href="#"
        className="text-red-600 underline mt-2 md:mt-4 text-sm md:text-base lg:text-sm"
        onClick={handleCancelClick}
      >
        Cancel Plan
      </a>
    </div>
  )}

  <div className="mt-12 mx-4 md:mx-20">
    <Button
      text="View Subscription Plans"
      borderColor="border-red-600"
      textColor="text-red-600"
      backgroundColor="bg-white"
      fontWeight="semibold"
      loading={viewPlansLoading}
      loaderColor="#DB0000"
      onClick={handleViewPlansClick}
    />
  </div>
</div>

        </>
      )}

      {showPopup && (
        <DeletePopup
          message="Are you sure you want to cancel this plan?"
          onConfirm={cancelPlan}
          onClose={() => setShowPopup(false)}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default Subscription;

