import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/main_navbar';
import ComplaintCard from '../../Components/complaint_card';
import TextArea from '../../Components/text_area';
import Button from '../../Components/button';
import Tab from '../../Components/tab';
import InputBox from '../../Components/input_box';
import ImageUpload from '../../Components/image_upload';
import AddDocument from '../../Components/add_doc';
import { useNavigate } from 'react-router-dom';
import ReportContainer from '../../Components/report';
import { getComplaintsAddedMyself, postReply, deleteReply, deleteComplaintById } from '../../../Controller/complaints';
import { FaSpinner, FaEdit, FaTrash } from 'react-icons/fa';
import { Toast } from '../../Components/toast';
import { showToast } from '../../Components/toast';
import DeletePopup from '../../Components/delete_pop_up';
import EditReplyPopup from '../../Components/edit_reply_popup';
import { getComplaintById } from '../../../Controller/complaints';
import { updateComplaint } from '../../../Controller/complaints';
import ReviewImageUpload from '../../Components/review_image_add';
import LoaderSpinner from '../../Components/loader_spinner';
import { useRef } from 'react';
import { getComplaintsAgainstCompany } from '../../../Controller/complaints';
import UploadDocuments from '../../../Controller/firebase/firebase_documents';
import uploadImage from '../../../Controller/firebase/firebase_upload_image';
import ImagePopup from '../../Components/image_modal';
import Modal from '../../Components/pdf_opener';
const Complaints = ({ owner }) => {
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [reply, setReply] = useState("");
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');

    const [replies, setReplies] = useState([]);
    const [activeTab, setActiveTab] = useState('received');
        const [showForm, setShowForm] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileDetail, setShowMobileDetail] = useState(false);
    const [receivedComplaints, setReceivedComplaints] = useState([]);
    const [submittedComplaints, setSubmittedComplaints] = useState([]);
    const [canReply, setCanReply] = useState(false);
    const [loading, setLoading] = useState(true); 
    const [page, setPage] = useState(0); 
    const [isEditingReply, setIsEditingReply] = useState(false);
    const [editingReplyIndex, setEditingReplyIndex] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editReplyPopupVisible, setEditReplyPopupVisible] = useState(false);
    const [replyToEdit, setReplyToEdit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [productName, setProductName] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [reviewImages, setReviewImages] = useState([]);
    const [hasReplied, setHasReplied] = useState(false);
    const [showReplyBox, setShowReplyBox] = useState(true); // State to control visibility of the reply box
    const [showDeleteComplaintPopup, setShowDeleteComplaintPopup] = useState(false); // Show/Hide delete popup for complaint
const [complaintToDelete, setComplaintToDelete] = useState(null); // Store the complaint to be deleted
const [uniqueComplaintIds, setUniqueComplaintIds] = useState(new Set());
const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
const [selectedImageUrl, setSelectedImageUrl] = useState('');
const [authorizationFile, setAuthorizationFile] = useState(null);

const [errors, setErrors] = useState({});


    const observerRef = useRef();
    const leftSectionRef = useRef();
    const navigate = useNavigate();
    const company_uid = localStorage.getItem('company_uid'); // Retrieve company_uid from local storage
    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0,
        };

        const observer = new IntersectionObserver(handleIntersection, option);
        if (observerRef.current) observer.observe(observerRef.current);

        return () => {
            if (observerRef.current) observer.unobserve(observerRef.current);
        };
    }, [loading, hasMore]);
    useEffect(() => {
        // Reset everything when tab changes
        setPage(0);
        setUniqueComplaintIds(new Set());
        setReceivedComplaints([]);
        setSubmittedComplaints([]);
        setHasMore(true);
        setSelectedComplaint(null);
        // Don't call fetchComplaints here as it will be called by the page useEffect
    }, [activeTab]);
    const handleIntersection = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && hasMore) {
            setPage(prev => prev + 1);
        }
    };


   
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        fetchComplaints();
    }, [activeTab, company_uid, page]);

    useEffect(() => {
        if (selectedComplaint) {
            fetchComplaintDetails();
            console.log("Replies:", replies); // Debug log
        }
    }, [selectedComplaint]);

    useEffect(() => {
        // Reset everything when tab changes
        setPage(0);
        setUniqueComplaintIds(new Set());
        setReceivedComplaints([]);
        setSubmittedComplaints([]);
        setHasMore(true);
        // Don't call fetchComplaints here as it will be called by the page useEffect
    }, [activeTab]);
    
    useEffect(() => {
        fetchComplaints();
    }, [page, activeTab]);
      
      // Function to handle tab change
      const handleTabChange = (newTab) => {
        setActiveTab(newTab);
      };
      
      // Updated Tab component call
     
      


    const validateForm = () => {
        const newErrors = {};
        if (!productName.trim()) {
            newErrors.productName = 'Please enter the product or service name!';
        }
        if (!title.trim()) {
            newErrors.title = 'Please enter the subject of the complaint!';
        }
        if (!description.trim() || description.trim().length < 30) {
            newErrors.description = 'Please explain the issue a bit more!';
        }
        setErrors(newErrors);
    
        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    };


    const fetchComplaints = async () => {
        if (!hasMore && page !== 0) return;
        setLoading(true);
      
        try {
            let response;
            
            // Fetch complaints based on active tab
            if (activeTab === 'received') {
                response = await getComplaintsAgainstCompany(company_uid, page, 5,true);
            } else if (activeTab === 'submitted') {
                response = await getComplaintsAddedMyself(page, 5);
            }
    
            // Check if response contains data
            if (response?.data?.data) {
                const fetchedComplaints = response.data.data;
                
                // If it's the first page, replace all complaints
                if (page === 0) {
                    if (activeTab === 'received') {
                        setReceivedComplaints(fetchedComplaints);
                    } else {
                        setSubmittedComplaints(fetchedComplaints);
                    }
                    // Reset the unique IDs set with only the IDs from this page
                    setUniqueComplaintIds(new Set(fetchedComplaints.map(c => c.id)));
                } else {
                    // For subsequent pages, filter out any duplicates before adding
                    const newComplaints = fetchedComplaints.filter(
                        complaint => !uniqueComplaintIds.has(complaint.id)
                    );
    
                    if (newComplaints.length > 0) {
                        if (activeTab === 'received') {
                            setReceivedComplaints(prev => {
                                // Create a Set of existing IDs for faster lookup
                                const existingIds = new Set(prev.map(c => c.id));
                                // Only add complaints that don't already exist
                                const uniqueNewComplaints = newComplaints.filter(c => !existingIds.has(c.id));
                                return [...prev, ...uniqueNewComplaints];
                            });
                        } else {
                            setSubmittedComplaints(prev => {
                                const existingIds = new Set(prev.map(c => c.id));
                                const uniqueNewComplaints = newComplaints.filter(c => !existingIds.has(c.id));
                                return [...prev, ...uniqueNewComplaints];
                            });
                        }
                        
                        // Update unique IDs set with new complaint IDs
                        setUniqueComplaintIds(prev => {
                            const newSet = new Set(prev);
                            newComplaints.forEach(complaint => newSet.add(complaint.id));
                            return newSet;
                        });
                    }
                }
    
                // Update hasMore based on whether we received a full page of results
                // and whether there were any new unique complaints
                setHasMore(fetchedComplaints.length === 5);
            } else {
                setHasMore(false);
                if (page === 0) {
                    if (activeTab === 'received') {
                        setReceivedComplaints([]);
                    } else {
                        setSubmittedComplaints([]);
                    }
                    setUniqueComplaintIds(new Set());
                }
            }
        } catch (error) {
            console.error("Error fetching complaints:", error);
            setHasMore(false);
            
            if (page === 0) {
                if (activeTab === 'received') {
                    setReceivedComplaints([]);
                } else {
                    setSubmittedComplaints([]);
                }
                setUniqueComplaintIds(new Set());
            }
        } finally {
            setLoading(false);
        }
    };
      // Function to handle image click
      const handleImageClick = (imageUrl) => {
        setSelectedImageUrl(imageUrl); // Set the clicked image's URL
        setIsImagePopupOpen(true); // Open the image popup
      };
    
    
  
    const handleClosePopup = () => {
        setIsImagePopupOpen(false);  // Close the image popup
    };
    
    

    const fetchComplaintDetails = async () => {
        setLoading(true);
        try {
            const response = await getComplaintById(selectedComplaint.id);
            console.log('feedback by id',response)
            if (response.data) {
                const complaintReplies = response.data.replies || [];  // Ensure replies are an array
                setReplies(complaintReplies);  // Set replies correctly
                setShowReplyBox(!(complaintReplies.length > 0));  // Show reply box only if no replies exist
            }
        } catch (error) {
            console.error("Failed to fetch complaint details:", error);
        } finally {
            setLoading(false);
        }
    };
    
    
    
    useEffect(() => {
        if (selectedComplaint) {
            fetchComplaintDetails();
        }
    }, [selectedComplaint]);
    
    const handleViewComplaint = async (complaint) => {
        try {
            const response = await getComplaintById(complaint.id);
            
            if (response.data && response.data.data && response.data.data.length > 0) {
                const complaintData = response.data.data[0];
                
                // Create a complete complaint object with all necessary fields
                const fetchedComplaint = {
                    ...complaintData,
                    complaint_to_company_id: complaintData.complaint_to_company_id || complaintData.complaint_to_id,
                    complaint_by_company_id: complaintData.complaint_by_company_id,
                    company_id: complaintData.complaint_to_id || complaintData.complaint_by_company_id,
                    id: complaintData.id,
                    complaint_by_name: complaintData.complaint_by_name || 'Name',
                    complaint_to_name: complaintData.complaint_to_name || 'Name',
                    complaint_by_profile_image: complaintData.complaint_by_profile_image || '/profile.webp',
                    title: complaintData.title || 'Complaint Title/Subject',
                    description: complaintData.description || 'Description not provided',
                    created_on: complaintData.created_on || 'Invalid Date',
                    product_name: complaintData.product_name || 'Product Name',
                    image_url: complaintData.image_url || [],
                    confirmation_document: complaintData.confirmation_document || '',
                    reply: complaintData.reply || null,
                    replied_on: complaintData.replied_on || null
                };

                // Update selected complaint
                setSelectedComplaint(fetchedComplaint);
                
                // Update the complaint in the list immediately if it exists
                setSubmittedComplaints(prevComplaints => 
                    prevComplaints.map(c => 
                        c.id === fetchedComplaint.id ? fetchedComplaint : c
                    )
                );
                
                // Set replies
                setReplies(complaintData.reply ? 
                    (Array.isArray(complaintData.reply) ? complaintData.reply : [complaintData.reply]) 
                    : []
                );
                
                setShowForm(false);
                
                setCanReply(activeTab === 'received');
                
                if (isMobile) {
                    setShowMobileDetail(true);
                }
            } else {
                throw new Error("No complaint data found");
            }
        } catch (error) {
            console.error("Error fetching complaint details:", error);
            showToast('Failed to load complaint details.', 'error');
        }
    };

    

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toString() === 'Invalid Date' ? 'No date provided' : date.toLocaleDateString("en-US", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };
    
    
    const handleCloseMobileDetail = () => {
        setSelectedComplaint(null);
        setShowForm(false);
    };

    const handleReplyChange = (event) => {
        setReply(event.target.value);
    };

    const handleSendReply = async () => {
        if (reply && reply.trim() !== "" && selectedComplaint) {
            try {
                const newReply = {
                    text: reply,
                    time: new Date().toLocaleTimeString(),
                    date: new Date().toLocaleDateString(),
                };
        
                const response = await postReply(selectedComplaint.id, newReply.text);
                console.log('Post Reply Response:', response); 
                if (response && response.status === 201) {
                    // Update the replies state and selectedComplaint with the new reply
                    setReplies([...replies, newReply]);
                    setSelectedComplaint(prev => ({
                        ...prev,
                        reply: newReply.text,
                        replied_on: new Date(),
                    }));
                    setReply(""); 
                    setHasReplied(true); // Mark as replied
                    setCanReply(false); // Disable further replies after one is sent
                } else {
                    showToast('Failed to send reply.', 'error');
                }
            } catch (error) {
                console.error("Error posting reply:", error);
                showToast('Failed to send reply.', 'error');
            }
        } else {
            showToast('Please enter a valid reply.', 'error');
        }
    };
    
    const handleEditReply = () => {
        if (selectedComplaint && selectedComplaint.reply) {
            setIsEditingReply(true);
            setReplyToEdit(selectedComplaint.reply);
            setEditReplyPopupVisible(true);
        } else {
            console.error("No reply to edit.");
        }
    };
    
    
    
    
    
    
    
    
    const handleDeleteReply = () => {
        setShowDeletePopup(true);
        setComplaintToDelete(selectedComplaint);
    };
    
    const confirmDeleteReply = async () => {
        if (complaintToDelete && complaintToDelete.id) {
            try {
                const response = await deleteReply(complaintToDelete.id, complaintToDelete.reply);
                if (response && response.status === 201) {
                    setSelectedComplaint({...selectedComplaint, reply: null, replied_on: null});
                } else {
                }
            } catch (error) {
                console.error("Error deleting reply:", error);
            } finally {
                setShowDeletePopup(false);
                setComplaintToDelete(null);
            }
        }
    };

    


    const cancelDelete = () => {
        setShowDeletePopup(false);
        setDeleteIndex(null);
    };
    const handleFileUpload = (file) => {
        setAuthorizationFile(file);
      };
    

    const handleEditReplySubmit = async (updatedReplyText) => {
    try {
        if (selectedComplaint && selectedComplaint.id) {
            // Here you would typically make an API call to update the reply
            // For now, we'll just update the local state
            setSelectedComplaint(prevComplaint => ({
                ...prevComplaint,
                reply: updatedReplyText
            }));
            setEditReplyPopupVisible(false);
            showToast('Reply updated successfully!', 'success');
        } else {
            console.error("No complaint selected or invalid complaint ID.");
            showToast('Failed to update reply.', 'error');
        }
    } catch (error) {
        console.error("Error updating reply:", error);
        showToast('Failed to update reply.', 'error');
    }
};
    
    
    

const handleFormSubmit = async () => {
    if (!validateForm()) {
        showToast('Please fix the errors and submit again.', 'error');
        return;
    }
    
    setFormSubmitting(true);
    try {
        const complaintId = selectedComplaint ? selectedComplaint.id : null;
        if (!complaintId) {
            throw new Error("No complaint ID found for submission.");
        }

        // Handle document upload
        let documentUrl = selectedComplaint.confirmation_document || '';
        if (authorizationFile) {
            try {
                documentUrl = await UploadDocuments(authorizationFile);
            } catch (error) {
                console.error('Document upload error:', error);
                showToast('Error uploading document.', 'error');
                return;
            }
        }

        const complaintData = {
            product_name: productName.trim(),
            title: title.trim(),
            description: description.trim(),
            image_url: reviewImages,
            confirmation_document: documentUrl,
        };

        const response = await updateComplaint(complaintId, complaintData);

        if (response && response.status === 202) {
            // Create updated complaint object with all necessary fields
            const updatedComplaint = {
                ...selectedComplaint,
                ...complaintData,
                edited: true,
                // Preserve existing fields that shouldn't be overwritten
                complaint_to_company_id: selectedComplaint.complaint_to_company_id,
                complaint_by_company_id: selectedComplaint.complaint_by_company_id,
                complaint_to_name: selectedComplaint.complaint_to_name,
                complaint_by_name: selectedComplaint.complaint_by_name,
                complaint_by_profile_image: selectedComplaint.complaint_by_profile_image,
                created_on: selectedComplaint.created_on,
                reply: selectedComplaint.reply,
                replied_on: selectedComplaint.replied_on
            };

            // Update the complaints list immediately
            setSubmittedComplaints(prevComplaints => 
                prevComplaints.map(complaint => 
                    complaint.id === complaintId ? updatedComplaint : complaint
                )
            );
            
            // Update selected complaint immediately
            setSelectedComplaint(updatedComplaint);

            showToast('Complaint updated successfully!', 'success');
            setIsEditing(false);
            setShowForm(false);
            
            // Clear form states
            setProductName('');
            setTitle('');
            setDescription('');
            setReviewImages([]);
            setAuthorizationFile(null);
            setErrors({});
        } else {
            showToast('Failed to update complaint.', 'error');
        }
    } catch (error) {
        console.error('Update error:', error);
        showToast('Failed to submit complaint.', 'error');
    } finally {
        setFormSubmitting(false);
    }
};




  

    
    
    
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    // Function to handle editing a complaint
    const handleEditComplaint = (complaint) => {
        setIsEditing(true);
        setSelectedComplaint(complaint);
        setShowForm(true);
        setProductName(complaint.product_name || '');
        setReviewImages(complaint.image_url || []);
        setSelectedComplaint(complaint);
        setErrors({});

        setTitle(complaint.title || '');
        setDescription(complaint.description || '');
        setImages(complaint.image_url || []);
    };
    const handleViewDocument = (documentUrl) => {
        if (documentUrl) {
            setSelectedDocumentUrl(documentUrl);
            setIsDocumentModalOpen(true);
        } else {
            showToast('Document is not available.', 'error');
        }
    };
    
    
    // Navigation to profile page
    const handleNavigateToProfile = (complaint) => {
        console.log('Raw complaint data:', complaint);
        
        let profileId;
        // For submitted complaints
        if (activeTab === 'submitted') {
            // Try to get company ID from different possible properties
            profileId = complaint.complaint_to_company_id || // Try the direct company ID first
                       complaint.complaint_to_id || // Then try the complaint_to_id
                       (complaint.company_id ? complaint.company_id : null); // Finally try company_id
                       
            console.log('Submitted complaint profileId sources:', {
                complaint_to_company_id: complaint.complaint_to_company_id,
                complaint_to_id: complaint.complaint_to_id,
                company_id: complaint.company_id,
                selected_profileId: profileId
            });
        } 
        // For received complaints
        else {
            profileId = complaint.complaint_by_company_id || // Try direct company ID first
                       complaint.complaint_by_id || // Then try complaint_by_id
                       (complaint.company_id ? complaint.company_id : null); // Finally try company_id
                       
            console.log('Received complaint profileId sources:', {
                complaint_by_company_id: complaint.complaint_by_company_id,
                complaint_by_id: complaint.complaint_by_id,
                company_id: complaint.company_id,
                selected_profileId: profileId
            });
        }
    
        // If we still don't have a profileId, let's try to parse it from the complaint object
        if (!profileId && complaint.complaint_to) {
            // Try to extract company ID from the complaint_to string if it exists
            const matches = complaint.complaint_to.match(/\d+/);
            if (matches) {
                profileId = parseInt(matches[0], 10);
                console.log('Extracted profileId from complaint_to:', profileId);
            }
        }
    
        if (profileId) {
            // Ensure it's a number and valid
            profileId = Number(profileId);
            if (!isNaN(profileId) && profileId > 0) {
                console.log('Navigating to profile:', profileId);
                navigate(`/profile/${profileId}`);
                return; // Exit the function after successful navigation
            }
        }
    
        // If we got here, we couldn't find a valid profile ID
        console.error('Navigation failed - Could not find valid profile ID:', {
            activeTab,
            complaintData: {
                id: complaint.id,
                complaint_to: complaint.complaint_to,
                complaint_by: complaint.complaint_by,
                complaint_to_id: complaint.complaint_to_id,
                complaint_by_company_id: complaint.complaint_by_company_id,
                company_id: complaint.company_id
            },
            complaint_details: {
                from: complaint.complaint_by_name,
                to: complaint.complaint_to_name
            }
        });
        showToast('Profile not available.', 'error');
    };
    

    const handleDeleteComplaint = (complaint) => {
        console.log('Complaint Object before deleting:', complaint); // Log the entire complaint object
        setComplaintToDelete(complaint); // Store the complaint object in state
        setShowDeleteComplaintPopup(true); // Show delete confirmation popup
    };
    
    // Function to confirm and delete the complaint
    const confirmDeleteComplaint = async () => {
        if (complaintToDelete && complaintToDelete.id) {
            try {
                const response = await deleteComplaintById(complaintToDelete.id);
                if (response.status === 202) {
                    
                    // Remove the deleted complaint from the list
                    setSubmittedComplaints((prev) => prev.filter((c) => c.id !== complaintToDelete.id));
                    
                    // Set the active tab to "submitted" and clear selected complaint
                    setSelectedComplaint(null);
                    setActiveTab('submitted');
                    
                    // Navigate to complaints submitted
                    if (isMobile) {
                        setShowMobileDetail(false);
                    }
                } else {
                    showToast('Failed to delete complaint.', 'error');
                }
            } catch (error) {
                console.error('Error deleting complaint:', error);
                showToast('Failed to delete complaint.', 'error');
            } finally {
                setShowDeleteComplaintPopup(false);
                setComplaintToDelete(null);
            }
        }
    };
    
    
    
    // Function to cancel the deletion
    const cancelDeleteComplaint = () => {
        setShowDeleteComplaintPopup(false); // Close the delete confirmation popup
        setComplaintToDelete(null); // Clear the complaint to delete
    };

    // Function to handle deleting a complaint
    // Function to handle deleting a complaint

    
    const tabLabels = {
        received: 'Complaints Received',
        submitted: 'Complaints Submitted'
    };

    const displayedComplaints = activeTab === 'received' ? receivedComplaints : submittedComplaints;

    return (
        <div className="flex flex-col md:flex-row w-full h-full bg-[#FBFCF8] font-poppins ">
            <Sidebar />
            <div className="flex flex-col w-full">
                <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
                    <div className="w-full flex justify-start items-center space-x-2">
                        <h1 className="text-black font-medium text-xl">Complaints</h1>
                    </div>
                    {/* <ReportContainer /> */}
                </div>
                <div className="border-b border-black mt-4 md:mt-[25px]"></div>
                <div className="flex flex-col md:flex-row h-full">
                    {/* <div className={`${isMobile ? 'w-full' : 'md:w-1/3'} border-r border-gray-400`}> */}
                    <div
  className={`md:w-1/3 ${isMobile ? '' : 'border-r border-gray-400'} relative h-full`}
  style={{  overflowY: 'scroll' }}
>                    

                    <Tab
  activeTab={activeTab}
  setActiveTab={handleTabChange}
  labels={tabLabels}
  defaultTab="received"
/>

         <div className="flex flex-col items-center p-3 md:p-9">
                        {loading && page === 0 ? (
                                <FaSpinner className="animate-spin text-red-600" size={24} />
                            ) : (
                                <>
                                    {displayedComplaints.length > 0 ? (
    displayedComplaints.map((complaint, index) => (
        <ComplaintCard
            key={index}
            complaint={complaint}
            onView={handleViewComplaint}
            onDelete={() => handleDeleteComplaint(complaint)}
            onEditReply={() => handleEditReply(index)}
        />
    ))
) : (
    <div className="flex items-center justify-center h-[60vh]">
        <div className="text-center text-black">No Complaints Found</div>
    </div>
)}

                                    {hasMore && (
                                        <div ref={observerRef} className="w-full flex justify-center py-4">
                                            <FaSpinner className="animate-spin text-red-600" size={24} />
                                        </div>
                                    )}
                                </>
                            )}
                           
                        </div>
                    </div>
                    {(!isMobile || (isMobile && showMobileDetail)) && (
                    <div className={`${isMobile ? 'fixed inset-0 z-50 bg-[#F2F3F3] overflow-y-auto' : 'md:w-2/3 lg:w-1/3 md:ml-9'} p-4`}>
                        {/* {isMobile && (
    <button
        onClick={handleCloseMobileDetail}
        className="absolute top-2 right-2 text-2xl z-50"  // Added z-index to bring it on top of all elements
        style={{ top: '10px', right: '10px' }} // Ensure it stays within the boundary
    >
        &times;
    </button>
)} */}

                        {!showForm && selectedComplaint && (
                            <>
                                <div className="bg-gray-200 rounded-lg p-4 shadow-md w-full mb-4">
                                <div className="flex ">
    <div className="flex cursor-pointer  " onClick={() => handleNavigateToProfile(selectedComplaint)}>
        <img
            src={activeTab === 'submitted' 
                ? (selectedComplaint.complaint_to_profile_image || '/profile.webp')
                : (selectedComplaint.complaint_by_profile_image || '/profile.webp')}
            alt="Profile"
            className="rounded-full w-12 h-12 mr-4"
        />
        <div className="flex flex-col ">
            <div className="font-normal text-sm  mt-2">
                {activeTab === 'submitted' 
                    ? (selectedComplaint.complaint_to_name || 'Name') 
                    : (selectedComplaint.complaint_by_name || 'Name')}
            </div>
            <div className="text-gray-500 text-sm">{new Date(selectedComplaint.created_on).toLocaleDateString()}</div>
        </div>
    </div>

    {activeTab === 'submitted' && (
        <div className="ml-auto flex items-center space-x-2 " >
            <button
                className="bg-gray-300 shadow-lg text-gray-600 hover:bg-gray-400 p-2 rounded"
                onClick={() => handleEditComplaint(selectedComplaint)}
            >
            <img src="/Assets/report_icon.svg" alt="Edit" className="w-4 h-4" />
            </button>
            <button
                className="bg-gray-300 shadow-lg text-gray-600 hover:bg-gray-400 p-2 rounded"
                onClick={() => handleDeleteComplaint(selectedComplaint)}
            >
                <img src="/Assets/bin.svg" alt="Delete" className="w-4 h-4" />
            </button>
        </div>
    )}
</div>


            <div className="mt-4">
                <div className="font-semibold text-sm">{selectedComplaint.title || 'Complaint Title/Subject'}</div>
                <div className="text-gray-600 font-semibold mt-2 text-sm">{selectedComplaint.product_name || 'Product Name'}</div>
                <div className="text-sm">{selectedComplaint.description || 'Description not provided'}</div>
                {selectedComplaint.image_url && selectedComplaint.image_url.length > 0 && (
    <img
        src={selectedComplaint.image_url[0]}
        alt="Complaint"
        className="w-16 h-16 rounded-lg mt-4 cursor-pointer"
        onClick={() => handleImageClick(selectedComplaint.image_url[0])}  // Open popup on click
    />
)}
{isImagePopupOpen && (
                <ImagePopup 
                    imageUrl={selectedImageUrl} 
                    onClose={handleClosePopup} 
                />
            )}

               {selectedComplaint.confirmation_document && (
    <div
    className="mt-4 cursor-pointer"
    onClick={() => handleViewDocument(selectedComplaint.confirmation_document)}
>
    <div className="flex items-center justify-between bg-gray-300 hover:bg-gray-200 p-6 rounded-lg">
        <div className="flex items-center">
            <img src="/Assets/pdf.svg" alt="PDF Icon" className="w-10 h-10 mr-2" />
            <span className="text-gray-600">Document</span>
        </div>
        <button className="text-red-600 underline">View</button>
    </div>
</div>
)}

            </div>
        </div>
        <Modal isOpen={isDocumentModalOpen} onClose={() => setIsDocumentModalOpen(false)}>
    <iframe
        src={selectedDocumentUrl}
        className="w-full h-full"
        title="Document Viewer"
    />
</Modal>


        {/* Check if reply exists */}
      
        {selectedComplaint.reply ? (
    <div className="mt-4 bg-[#E1E1E1] p-4 rounded-lg shadow-md">
        <div className="flex justify-between items-center">
            <div className="flex items-center">
                <img
                    src={selectedComplaint.complaint_by_profile_image || '/profile.webp'}
                    alt="Profile"
                    className="rounded-full w-12 h-12 mr-4"
                />
                <div className="flex flex-col">
                    <div className="font-bold">{selectedComplaint.complaint_by_name || 'Name'}</div>
                    <div className="text-gray-500 text-sm">{new Date(selectedComplaint.replied_on).toLocaleDateString()}</div>
                </div>
            </div>
            {activeTab === 'received' && (
                <div className="flex space-x-2">
                    <button
                        className=" text-gray-600 hover:bg-gray-400 p-2 rounded"
                        onClick={() => handleEditReply(selectedComplaint)}
                    >
                        <img src="/Assets/report_icon.svg" alt="Edit" className="w-6 h-6" />
                    </button>
                    <button
                        className="text-gray-600 hover:bg-gray-400 p-2 rounded"
                        onClick={() => handleDeleteReply(selectedComplaint)}
                    >
                        <img src="/Assets/bin.svg" alt="Delete" className="w-6 h-6" />
                    </button>
                </div>
            )}
        </div>
        <div className="mt-2">
            <div>{selectedComplaint.reply}</div>
        </div>
    </div>
) : (!hasReplied && canReply && activeTab === 'received' && (
    <div className="flex flex-col mt-2">
        <TextArea
            value={reply}
            onChange={handleReplyChange}
            placeholder="Reply"
        />
        <div className="w-40 self-end">
            <Button
                text="Send"
                backgroundColor="bg-red-600"
                textColor="text-white"
                borderColor="border-red-600"
                onClick={handleSendReply}
            />
        </div>
    </div>
))}




                                </>
                            )}

                


{owner && selectedComplaint && !showForm && canReply && !hasReplied && (
    <div className="flex flex-col mt-2">
        
    </div>
)}


{(showForm || (selectedComplaint && selectedComplaint.isEditing)) && (
    <div className="p-4 space-y-3 mt-4">
       <div className="mb-4">
            <label className="text-black font-medium block mb-1">Product/Service Name</label>
            <InputBox
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="Enter the product or service name"
              maxLength={100}
            />
            {/* Show error message if validation fails */}
            {errors.productName && <p className="text-red-600 text-xs mt-1">{errors.productName}</p>}
          </div>

          {/* Complaint Subject Field */}
          <div className="mb-4">
            <label className="text-black font-medium block mb-1">Complaint Subject</label>
            <InputBox
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter the subject of the complaint"
              maxLength={120}
            />
            {/* Show error message if validation fails */}
            {errors.title && <p className="text-red-600 text-xs mt-1">{errors.title}</p>}
          </div>

          {/* Description Field */}
          <div className="mb-4">
            <label className="text-black font-medium block mb-1">Description</label>
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe the issue that you've faced"
              maxLength={200}
            />
            {/* Show error message if validation fails */}
            {errors.description && <p className="text-red-600 text-xs mt-1">{errors.description}</p>}
          </div>

          {/* Document Upload */}
          <div className="mb-4">
            <label className="text-black font-medium block mb-1">Document</label>
            <AddDocument
              label="Document"
              onFileUpload={handleFileUpload}
              accept="application/pdf"
            />
          </div>
<div className='mb-4'>
          <ReviewImageUpload
                reviewImages={reviewImages}
                setReviewImages={setReviewImages}
                svgImagePath="/Assets/grey_plus.svg"
                bgColor="#E6E6E6"
                borderRadius="8px"
                width="80px"
                height="90px"
            />
                </div>

          {/* Submit Button */}
          <Button
  text="Submit" // Default text for the button
  backgroundColor="bg-[#DB0000]"  // Red background color
  textColor="text-white"
  borderColor="border-red-600"
  onClick={handleFormSubmit}
  loading={formSubmitting} 

/>

    </div>
)}
                        </div>
                    )}
                </div>
            </div>
            <Toast /> 
            {showDeleteComplaintPopup && (
    <DeletePopup
        message="Are you sure you want to delete this complaint?"
        onConfirm={confirmDeleteComplaint} // Call confirmDeleteComplaint when "Yes" is clicked
        onClose={cancelDeleteComplaint} // Call cancelDeleteComplaint when "No" is clicked
    />
)}

            {editReplyPopupVisible && (
                <EditReplyPopup
                    reply={replyToEdit}
                    onSubmit={handleEditReplySubmit}
                    onClose={() => setEditReplyPopupVisible(false)}
                />
            )}
            {showDeletePopup && (
    <DeletePopup
        message="Are you sure you want to delete this reply?"
        onConfirm={confirmDeleteReply}
        onClose={cancelDelete}
    />
)}

        </div>
    );
};

export default Complaints;