// companyDocsController.js
import { post_documents } from './company_details';
import { showToast } from '../View/Components/toast';

// export const saveCompanyDocuments = async (documents, navigate, setLoading) => {
//   if (!documents['Tenancy Contract']) {
//     showToast('Please add the Tenancy Contract before saving.', 'error');
//     return;
//   }

//   if (!documents['Business License']) {
//     showToast('Please add the Business License before saving.', 'error');
//     return;
//   }

//   setLoading(true);

//   try {
//     const response = await post_documents(
//       documents['Tenancy Contract'], // Map this correctly
//       documents['Certificate of Incorporation/Trading Register'], // This stays the same
//       documents['Business License'] // Map this correctly
//     );

//     const message = response.data.message;

//     if (response.status === 202) {
//       console.log(response);
//       navigate('/details_page');
//     } else {
//       // showToast('Failed to save documents', 'error');
//     }
//   } catch (error) {
//     console.error('Error updating documents:', error);
//     // showToast('Error updating documents', 'error');
//   } finally {
//     setLoading(false);
//   }
// };


export const saveCompanyDocuments = async (documents, navigate, setLoading) => {
  if (!documents['Tenancy Contract']) {
    showToast('Please add the Tenancy Contract before saving.', 'error');
    return;
  }

  if (!documents['Business License']) {
    showToast('Please add the Business License before saving.', 'error');
    return;
  }

  setLoading(true);

  try {
    const response = await post_documents(
      documents['Tenancy Contract'], // Map this correctly
      documents['Certificate of Incorporation/Trading Register'], // This stays the same
      documents['Business License'] // Map this correctly
    );

    if (response.status === 202) {
      showToast(response.data.message || 'Documents saved successfully!', 'success');
      setLoading(false); // Stop loading first
      navigate('/details_page'); // Proceed to the next page
    } else {
      showToast('Failed to save documents. Please try again.', 'error');
    }
  } catch (error) {
    console.error('Error updating documents:', error);
    showToast('Error updating documents. Please try again later.', 'error');
  } finally {
    setLoading(false); // Ensure loading stops even on error
  }
};
