
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase_service';
import { FaSpinner } from 'react-icons/fa';

const ChatList = ({ messages, currentUserUid, onSelectMessage, selectedMessageId ,loading }) => {
  const [userImages, setUserImages] = useState({});

  useEffect(() => {
    const fetchUserImages = async () => {
      try {
        const userIds = new Set();
        messages.forEach(message => {
          userIds.add(message.senderUid);
          userIds.add(message.receiverUid);
        });

        const usersRef = collection(db, 'users');
        const userPromises = Array.from(userIds).map(async uid => {
          const q = query(usersRef, where('companyUid', '==', uid));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            return [uid, userData.profileImage || ''];
          }
          return [uid, ''];
        });

        const userImageResults = await Promise.all(userPromises);
        setUserImages(Object.fromEntries(userImageResults));
      } catch (error) {
        console.error('Error fetching user images:', error);
      }
    };

    if (messages?.length > 0) {
      fetchUserImages();
    }
  }, [messages]);

  if (!Array.isArray(messages) || !currentUserUid) {
    return null;
  }

  // const validMessages = messages.filter(message => message.lastMessage && message.lastMessage.trim() !== '');

  const validMessages = messages
  .filter(message => message.lastMessage && message.lastMessage.trim() !== '')
  .sort((a, b) => {
    const timeA = a.lastMessageTime?.toMillis ? a.lastMessageTime.toMillis() : 0;
    const timeB = b.lastMessageTime?.toMillis ? b.lastMessageTime.toMillis() : 0;
    return timeB - timeA; // Sort in descending order (newest first)
  });

  const handleChatSelect = (message) => {
    const isReceiver = message.lastMessageUid !== currentUserUid;
    onSelectMessage(message, isReceiver);
  };


  return (
    <div className="p-4 h-full flex flex-col">
    {loading ? ( // Check if still loading
      <div className="flex items-center justify-center h-full text-gray-500">
        <FaSpinner className="animate-spin text-xl" />
      </div>
    ) : validMessages.length === 0 ? ( // Check if there are no valid messages after loading
      <div className="flex items-center justify-center flex-grow text-gray-500">
      <p className="text-sm">No Direct Message to show !</p>
    </div>
     
    ) : (
        validMessages.map((message) => {
          const otherUserId = message.participants.find(uid => uid !== currentUserUid);
          const isSelected = selectedMessageId === message.id;
          const displayName = message.receiverUid === currentUserUid ? message.senderName : message.receiverName;
          const displayImage = message.receiverUid === currentUserUid 
            ? userImages[message.senderUid] || message.senderImage
            : userImages[message.receiverUid] || message.receiverImage;

          const trimmedLastMessage = message.lastMessage.length > 30 
            ? `${message.lastMessage.substring(0, 30)}...`
            : message.lastMessage;

          const isImportant = currentUserUid === message.senderUid
            ? message.isImportantForSender
            : message.isImportantForReceiver;

          const hasUnreadMessages = !message.lastMessageSeen && 
            message.lastMessageUid !== currentUserUid;

          const showDoubleTick = 
            message.senderUid === currentUserUid && 
            message.lastMessageSeen === true && 
            message.lastMessageSeenTime !== null;

          return (
            <div
              key={message.id}
              onClick={() => onSelectMessage(message)}
              className={`flex items-center font-poppins space-x-4 p-4 mb-4 rounded-lg shadow-md cursor-pointer w-full 
                ${isSelected ? 'bg-gray-200' : 'bg-gray-100'}
                ${hasUnreadMessages ? '' : ''}`}
              style={{ marginBottom: '10px' }}
            >
              <div className="relative">
                <img
                  src={displayImage || ''}
                  alt={displayName}
                  className="w-12 h-12 rounded-full"
                />
                {hasUnreadMessages && (
                  <div className="absolute -top-1 -right-1 w-3 h-3 bg-red-600 rounded-full"></div>
                )}
              </div>
              
              <div className="flex-grow">
                <div className="flex justify-between items-center">
                  <h3 className={`font-semibold text-[16px] ${hasUnreadMessages ? '' : ''}`}>
                    {displayName}
                  </h3>
                  {message.lastMessageTime && (
                    <span className="text-xs text-gray-500">
                     
                    </span>
                  )}
                </div>
                <p className={`text-sm ${hasUnreadMessages ? 'font-semibold text-gray-900' : 'text-gray-600'}`}>
                  {trimmedLastMessage}
                </p>
              </div>

              <div className="flex items-center space-x-2">
                {showDoubleTick && (
                  <img
                    src="/Assets/double_tick.svg"
                    alt="Seen"
                    className="w-4 h-4"
                  />
                )}

                {isImportant && (
                  <img
                    src="/Assets/important_star.svg"
                    alt="Important"
                    className="w-4 h-4"
                  />
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ChatList;