


import React, { useState, useEffect, useRef } from 'react';
import { getAllMedia } from '../../Controller/company_products';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

const Explore = ({ setLoading: setParentLoading }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10; // Changed to 10 to load complete sets
  const navigate = useNavigate();
  const observerRef = useRef(null);

  const fetchMediaItems = async (skip, limit) => {
    try {
      setLoading(true);
      const res = await getAllMedia(skip, limit);
      
      if (res.status === 200 && res.data.data) {
        const newItems = res.data.data.map(media => ({
          id: media.id,
          imageUrl: Array.isArray(media.image_url) && media.image_url.length ? media.image_url[0] : 'placeholder-image-url',
          description: media.description ? media.description.substring(0, 20) + (media.description.length > 20 ? '...' : '') : 'No description available',
          title: media.title ? media.title.substring(0, 30) + (media.title.length > 30 ? '...' : '') : 'No description available',
          logo: media.logo,
          company_id: media.company_id,
          company_name: media.company_name,
        }));

        setItems(prev => [...prev, ...newItems]);
        setHasMore(newItems.length === limit);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching media items:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMediaItems(0, limit);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          fetchMediaItems(items.length, limit);
        }
      },
      { threshold: 1.0 }
    );
  
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
  
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [hasMore, loading, items.length]);

  const handleImageClick = (id, company_id, company_name, logo) => {
    navigate(`/detailed_view/${id}`, { state: { company_id, company_name, logo } });
  };

  const getItemGroups = () => {
    const groups = [];
    for (let i = 0; i < items.length; i += 10) {
      groups.push(items.slice(i, i + 10));
    }
    return groups;
  };

  return (
    <div className="w-full lg:p-4 p-2">
      {getItemGroups().map((group, groupIndex) => (
        <div key={groupIndex} className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          {/* Left Column */}
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              {group.slice(0, 2).map((item, index) => (
                <div
                  key={`${groupIndex}-${index}`}
                  className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                  style={{ height: '180px' }}
                  onClick={() => handleImageClick(item.id, item.company_id, item.company_name, item.logo)}
                >
                  <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                </div>
              ))}
            </div>
            <div className="flex gap-2">
              {group.slice(2, 5).map((item, index) => (
                <div
                  key={`${groupIndex}-${index + 2}`}
                  className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer lg:h-72 h-40"
                  onClick={() => handleImageClick(item.id, item.company_id, item.company_name, item.logo)}
                >
                  <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                </div>
              ))}
            </div>
          </div>
          
          {/* Right Column */}
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              {group.slice(5, 8).map((item, index) => (
                <div
                  key={`${groupIndex}-${index + 5}`}
                  className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer lg:h-72 h-48"
                  onClick={() => handleImageClick(item.id, item.company_id, item.company_name, item.logo)}
                >
                  <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                </div>
              ))}
            </div>
            <div className="flex gap-2">
              {group.slice(8, 10).map((item, index) => (
                <div
                  key={`${groupIndex}-${index + 8}`}
                  className="bg-white rounded-lg shadow-md relative flex-1 cursor-pointer"
                  style={{ height: '180px' }}
                  onClick={() => handleImageClick(item.id, item.company_id, item.company_name, item.logo)}
                >
                  <img src={item.imageUrl} alt={item.description} className="w-full h-full rounded-lg object-cover" />
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      
      {loading && (
        <div className="flex justify-center my-4">
          <FaSpinner className="animate-spin text-3xl text-gray-500" />
        </div>
      )}
      <div ref={observerRef} style={{ height: '1px' }} />
    </div>
  );
};

export default Explore;