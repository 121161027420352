//development
//  const BASE_URL = "https://eswaf-api-717905028915.us-central1.run.app/app/v1";

//  const BASE_URL = "https://eswaf-api-717905028915.us-central1.run.app/app/v1";

const BASE_URL = "https://eswaf-production-api-717905028915.us-central1.run.app/app/v1";

const LOGIN_URL = `${BASE_URL}/auth/login`;
const SIGNUP_URL = `${BASE_URL}/signup/`;
const REFRESH_URL = `${BASE_URL}/refresh/`;
const CHECK_EMAIL_URL = `${BASE_URL}/auth/verify-email`;
const REGISTER_URL = `${BASE_URL}/register/`;
const SEND_OTP = `${BASE_URL}/auth/verify-otp`;
const RESET_PASSWORD_MAIL = `${BASE_URL}/auth/reset-email`;
const RESET_PASSWORD_OTP = `${BASE_URL}/auth/reset-otp`;
const RESET_PASSWORD = `${BASE_URL}/auth/reset-password`;
const PHONE_VERIFY = `${BASE_URL}/auth/verify-phone`;
const SET_PASSWORD=`${BASE_URL}/auth/set-password`
const GET_COMPANIES_API_URL = `${BASE_URL}/master/sub-company/{id}`;
const LOGOUT_URL = `${BASE_URL}/auth/logout`;


//check phone
const CHECK_PHONE_URL = `${BASE_URL}/auth/check-phone`;

//change password
const CHANGE_PASSWORD_URL = `${BASE_URL}/user/change-password`;

const UPDATE_LOCATION_URL=`${BASE_URL}/company/location`;
const UPDATE_DOCUMENTS_URL= `${BASE_URL}/company/document`;
const UPDATE_ADVANCED_URL=`${BASE_URL}/company/advanced`;
const UPDATE_MEDIA_URL=`${BASE_URL}/company/media`;
const UPDATE_COMPANY_NAME_URL=`${BASE_URL}/company/`;
const GET_SUB_COMPANY_URL = (category) => `${BASE_URL}/company/sub-category/${category}`;

const POST_SUB_COMPANY_URL =`${BASE_URL}/company/request/subcategory`;

const POST_LOCATION_URL=`${BASE_URL}/company/country`;
const POST_ONBOARDING_PRIMARY=`${BASE_URL}/company/onboarding-primary`;
//steps end points
const POST_COMPANY_DETAILS = `${BASE_URL}/company/onboarding/details`;
const POST_MANAGER = `${BASE_URL}/company/onboarding/manager`;
const POST_DOCUMENTS = `${BASE_URL}/company/onboarding/document`;
const POST_LINKEDIN_VERIFICATION=`${BASE_URL}/company/onboarding/linkedin`;
const GET_VERIFICATION=`${BASE_URL}/company/is-verified`;
//report Api
const POST_REPORT=`${BASE_URL}/reports/app`;
const POST_COMPANY_BOOKMARK_URL = (uid) => `${BASE_URL}/company_bookmarks?uid=${uid}`;
const DELETE_COMPANY_BOOKMARK_URL = (uid) => `${BASE_URL}/company_bookmarks?uid=${uid}`;
const GET_COMPANY_BOOKMARKS_URL = (uid) => `${BASE_URL}/company_bookmarks/${uid}?skip={skip}&limit={limit}`;
//company report api
const POST_COMPANY_REPORT = `${BASE_URL}/reports/company_report`;
// Management-related API endpoints
const GET_COMPANY_MEMBERS_URL = `${BASE_URL}/management/`;
const DELETE_COMPANY_MEMBERS_URL = (id) => `${BASE_URL}/media/image/${id}`; 

const CREATE_MANAGEMENT_MEMBER_URL = `${BASE_URL}/management/`;
const GET_MEMBER_URL = (id) => `${BASE_URL}/management/${id}/`;
const UPDATE_MEMBER_URL = (id) => `${BASE_URL}/management/${id}`;
const DELETE_MEMBER_URL = (id) => `${BASE_URL}/management/${id}`;
const GET_MEMBERS_BY_COMPANY_URL = (company_uid, skip, limit) => 
  `${BASE_URL}/management/company/${company_uid}?skip=${skip}&limit=${limit}`;


// Verification-related API endpoints
const GET_VERIFICATION_IMAGE_URL = `${BASE_URL}/verification-image/`;

const CREATE_VERIFICATION_IMAGE_URL = `${BASE_URL}/verification-image/`;
const DELETE_VERIFICATION_IMAGE_URL = (id) => `${BASE_URL}/verification-image/${id}`;
const GET_VERIFICATION_IMAGE_BY_COMPANY_URL = (uid) => 
  `${BASE_URL}/verification-image/company/${uid}`;
const UPDATE_VERIFICATION_IMAGE_URL = (id) => `${BASE_URL}/verification-image/${id}`;

// Company documents API endpoints
const GET_COMPANY_DOCUMENTS_URL = (id) => `${BASE_URL}/company/document/${id}/`;

//product service API
const DELETE_PRODUCT_URL = (id) => `${BASE_URL}/products/${id}`;
const GET_USER_BY_ID_URL = (id) => `${BASE_URL}/products/user/${id}/`;
const GET_PRODUCTS_URL = `${BASE_URL}/products/`;
const CREATE_PRODUCT_URL = `${BASE_URL}/products/`;
const GET_PRODUCT_BY_ID_URL = (id) => `${BASE_URL}/products/${id}/`;
const GET_PRODUCTS_BY_COMPANY_URL = (uid) => `${BASE_URL}/products/company/${uid}`;

//Block
const POST_BLOCK_COMPANY_URL = (uid) => `${BASE_URL}/company/block${uid}`;

const DELETE_BLOCK_COMPANY_URL = (uid) => `${BASE_URL}/company/block${uid}`; // Unblock company
const GET_BLOCKED_COMPANIES_URL = (uid) => `${BASE_URL}/company/block?uid=${uid}`; // Get blocked companies



//SUBCRIPTIONS
const GET_SUBSCRIPTIONS=`${BASE_URL}/subscriptions`
const POST_USER_PAYMENTS=`${BASE_URL}/subscriptions`;
const GET_COMPANY_SUBSCRIPTION=(uid)=> `${BASE_URL}/subscriptions/company/${uid}`
const CANCEL_COMPANY_SUBSCRIPTION=(uid)=> `${BASE_URL}/subscriptions/company/${uid}`

const POST_CONFIRM_SUBSCRIPTION=(session_uid)=> `${BASE_URL}/subscriptions/confirm-subscription/${session_uid}`

//documents get 
const GET_DOCUMENTS_BY_COMPANY_URL = (id) => `${BASE_URL}/company/onboarding/document/${id}`;


//update documents
const UPDATE_DOCUMENTS_RESUBMIT_URL=`${BASE_URL}/company/resubmit`;
//add videos
const POST_VIDEO_URL=`${BASE_URL}/media/video`;


//get videos
const GET_ALL_USERS_VIDEO_URL=(id)=> `${BASE_URL}/media/video/user/${id}`;
const DELETE_VIDEO=(id)=>`${BASE_URL}/media/video/${id}`
const GET_USER_VIDEO_URL=(id)=> `${BASE_URL}/media/video/${id}`;
const GET_All_VIDEO_URL= `${BASE_URL}/media/video`;
////////hefgebcshb
// Media/Image Management 
const POST_MEDIA_IMAGE_URL = `${BASE_URL}/media/image`; // Add Image API (POST)
const GET_ALL_MEDIA_IMAGES_URL = `${BASE_URL}/media/image`; // Get All Images API (GET)
const GET_MEDIA_IMAGE_BY_ID_URL = (id) => `${BASE_URL}/media/image/${id}`; // Get Image By ID API (GET)
const UPDATE_MEDIA_IMAGE_URL = (id) => `${BASE_URL}/media/image/${id}`; // Update Image API (PUT)
const DELETE_MEDIA_IMAGE_URL = (id) => `${BASE_URL}/media/image/${id}`; // Delete Image API (DELETE)
// const GET_MEDIA_IMAGES_BY_USER_URL = (id) => `${BASE_URL}/media/image/user/${id}`; // Get Image By User API (GET)

const GET_MEDIA_IMAGES_BY_USER_URL = (uid, skip, limit) => 
  `${BASE_URL}/media/image/company/${uid}?skip=${skip}&limit=${limit}`;

//profile page
const GET_COMPANY_URL=`${BASE_URL}/company`;
const ADD_PROFILE_IMAGE = `${BASE_URL}/company/profile_image`;
const GET_ANOTHER_COMPANY = (company_id) => `${BASE_URL}/company/${company_id}`;
const GET_COMPANY_ABOUT_API = (company_uid) => `${BASE_URL}/company/about?company_uid=${company_uid}`;

////delete account 
const DELETE_USER_URL = (id) => `${BASE_URL}/user?id=${id}`;
const DELETE_COMPANY_URL = `${BASE_URL}/company`;

//notification
// Add this in your `urls.jsx`
const POST_NOTIFICATION_PREFERENCE_URL = `${BASE_URL}/user/notification`;

//reviews apis
const ADD_REVIEW = `${BASE_URL}/company_reviews`;
// const GET_COMPANY_REVIEWS_URL = `${BASE_URL}/company_reviews`;
const GET_COMPANY_REVIEWS_URL = (company_uid, skip, limit) => 
  `${BASE_URL}/company_reviews/company/${company_uid}?skip=${skip}&limit=${limit}`;
const DELETE_REVIEW_URL = (id) => `${BASE_URL}/company_reviews/${id}`;

const UPDATE_CATALOGUE_URL=`${BASE_URL}/company/catalogue`;

const GET_OTHER_PROFILE=(company_id)=> `${BASE_URL}/company/${company_id}`

const POST_REQUEST=`${BASE_URL}/post`
const UPDATE_COMPLAINT=(id)=>`${BASE_URL}/complaints/${id}`
const GET_COMPLAINT=(id)=> `${BASE_URL}/complaints/${id}`

const GET_COMPLAINTS_ADDED_MYSELF=`${BASE_URL}/complaints`;
const GET_COMPLAINTS_AGAINST_ME=(company_uid)=> `${BASE_URL}/complaints/company/${company_uid}`
const POST_COMPLAINT=`${BASE_URL}/complaints`
const DELETE_COMPLAINT=(id)=> `${BASE_URL}/complaints/${id}`

const GET_USER_FEEDBACK=(company_uid)=> `${BASE_URL}/feedback/company/${company_uid}`

const GET_SPECIFIC_USER_FEEDBACK=`${BASE_URL}/feedback`
const GET_USER_FEEDBACK_ID = `${BASE_URL}/feedback`; // Don't include {id} here
const UPDATE_FEEDBACK=`${BASE_URL}/feedback`
const POST_FEEDBACK=`${BASE_URL}/feedback`
const POST_REPLY=(id) =>`${BASE_URL}/complaints/reply/${id}`
const DELETE_USER_FEEDBACK = (id) => `${BASE_URL}/feedback/${id}`;
const DELETE_REPLY=(id) =>`${BASE_URL}/complaints/reply/${id}`
const GET_ALL_POSTS=`${BASE_URL}/post`
const GET_POST_BY_ID=`${BASE_URL}/post`
const GET_POST_BY_COMPANY_UID=(company_id)=>`${BASE_URL}/post/user/${company_id}`
const GET_SUBCATEGORIES_SEARCH=`${BASE_URL}/search/search`
const DELETE_POST_BY_ID=(id) =>`${BASE_URL}/post/${id}`
const POST_SOCIAL_LINKS=`${BASE_URL}/company/social`
const GET_SOCIAL_LINKS=(id)=>`${BASE_URL}/company/social/${id}`
const GET_NOTIFICATIONS_BY_USER_URL = (uid, skip, limit) => 
  `${BASE_URL}/user/notification/${uid}?skip=${skip}&limit=${limit}`;
export { 
  
  GET_SOCIAL_LINKS,
  POST_SOCIAL_LINKS,
  CANCEL_COMPANY_SUBSCRIPTION,
  POST_CONFIRM_SUBSCRIPTION,
  GET_COMPANY_SUBSCRIPTION,
  UPDATE_FEEDBACK,
  GET_SUBCATEGORIES_SEARCH,
  DELETE_POST_BY_ID,
  GET_POST_BY_ID,
  GET_POST_BY_COMPANY_UID,
  GET_ALL_POSTS,
  GET_USER_FEEDBACK_ID,
  GET_OTHER_PROFILE,
  POST_REQUEST,
  UPDATE_COMPLAINT,
  GET_COMPLAINT,
  DELETE_REPLY,
  POST_REPLY,
  GET_COMPLAINTS_ADDED_MYSELF,
  GET_COMPLAINTS_AGAINST_ME,
  POST_COMPLAINT,
  DELETE_COMPLAINT,
  GET_SPECIFIC_USER_FEEDBACK,
  DELETE_USER_FEEDBACK,
  GET_USER_FEEDBACK,
  POST_FEEDBACK,
  LOGOUT_URL,
  DELETE_COMPANY_MEMBERS_URL,
  GET_All_VIDEO_URL,
  UPDATE_CATALOGUE_URL,
  DELETE_VIDEO,
  GET_ALL_USERS_VIDEO_URL,
  GET_USER_VIDEO_URL,
  POST_VIDEO_URL,
  UPDATE_DOCUMENTS_RESUBMIT_URL,
  POST_USER_PAYMENTS,
  GET_SUBSCRIPTIONS,
  POST_SUB_COMPANY_URL,
  POST_LOCATION_URL,
  SET_PASSWORD,
  PHONE_VERIFY,
  RESET_PASSWORD_MAIL,
  RESET_PASSWORD_OTP,
  RESET_PASSWORD,
  GET_COMPANY_URL,
  UPDATE_LOCATION_URL,
  UPDATE_MEDIA_URL,
  UPDATE_DOCUMENTS_URL,
  UPDATE_ADVANCED_URL,
  POST_ONBOARDING_PRIMARY,
  BASE_URL, 
  LOGIN_URL, 
  SIGNUP_URL, 
  REFRESH_URL, 
  REGISTER_URL,
  CHECK_EMAIL_URL,
  GET_COMPANY_MEMBERS_URL,
  CREATE_MANAGEMENT_MEMBER_URL,
  GET_MEMBER_URL,
  UPDATE_MEMBER_URL,
  DELETE_MEMBER_URL,
  POST_COMPANY_DETAILS,
  POST_MANAGER,
  POST_DOCUMENTS,
  GET_COMPANIES_API_URL,
  GET_VERIFICATION_IMAGE_URL,
  
  UPDATE_COMPANY_NAME_URL,
  POST_LINKEDIN_VERIFICATION,
  CREATE_PRODUCT_URL,
  GET_PRODUCTS_URL,
  GET_USER_BY_ID_URL,
  DELETE_PRODUCT_URL,
  GET_PRODUCT_BY_ID_URL,
  SEND_OTP,
  POST_REPORT,
  POST_MEDIA_IMAGE_URL,
  GET_ALL_MEDIA_IMAGES_URL,
  GET_MEDIA_IMAGE_BY_ID_URL,
  UPDATE_MEDIA_IMAGE_URL,
  DELETE_MEDIA_IMAGE_URL,
  GET_MEDIA_IMAGES_BY_USER_URL,
  ADD_PROFILE_IMAGE,
  ADD_REVIEW,
  GET_COMPANY_REVIEWS_URL,
  GET_ANOTHER_COMPANY,
  DELETE_VERIFICATION_IMAGE_URL,

  CREATE_VERIFICATION_IMAGE_URL,
  GET_SUB_COMPANY_URL,
  GET_COMPANY_DOCUMENTS_URL,
  GET_VERIFICATION,
  DELETE_REVIEW_URL,
  GET_MEMBERS_BY_COMPANY_URL,
  GET_DOCUMENTS_BY_COMPANY_URL,
  GET_VERIFICATION_IMAGE_BY_COMPANY_URL,
  GET_PRODUCTS_BY_COMPANY_URL,
  POST_COMPANY_REPORT,
DELETE_USER_URL,
DELETE_COMPANY_URL,
GET_COMPANY_ABOUT_API,
POST_COMPANY_BOOKMARK_URL,
DELETE_COMPANY_BOOKMARK_URL,
GET_COMPANY_BOOKMARKS_URL,
POST_NOTIFICATION_PREFERENCE_URL,
CHANGE_PASSWORD_URL,
UPDATE_VERIFICATION_IMAGE_URL,
POST_BLOCK_COMPANY_URL,
DELETE_BLOCK_COMPANY_URL,
GET_BLOCKED_COMPANIES_URL,
GET_NOTIFICATIONS_BY_USER_URL,
CHECK_PHONE_URL


  
  
};
