import { check_email } from './auth.js';
import { showToast } from '../View/Components/toast.jsx';

const restrictedDomains = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'icloud.com',
];

const isRestrictedEmailDomain = (email) => {
  const domain = email.split('@')[1];
  return restrictedDomains.includes(domain);
};

const validateEmail = (email) => {
  const parts = email.split('@');
  if (parts.length !== 2) {
    return { isValid: false, message: 'Please enter a business email' };
  }

  const [localPart, domainPart] = parts;
  if (localPart.length === 0) {
    return { isValid: false, message: 'Please enter a business email' };
  }

  if (domainPart.length === 0) {
    return { isValid: false, message: 'Please enter a business email' };
  }

  if (!domainPart.includes('.')) {
    return { isValid: false, message: 'Email domain must be valid' };
  }

  const domainParts = domainPart.split('.');
  if (domainParts.some(part => part.length === 0)) {
    return { isValid: false, message: 'Email domain must be valid' };
  }

  if (isRestrictedEmailDomain(email)) {
    return { isValid: false, message: 'Emails from this domain are not allowed.Enter your business email.' };
  }

  return { isValid: true, message: '' };
};

export const handleSignUp = async (company, email, navigate, setLoading, setShowEmailMessage, setEmailMessage, setShowCompanyMessage, setCompanyMessage) => {
  setLoading(true);
  setShowEmailMessage(false);
  setShowCompanyMessage(false);

  try {
    if (!company.trim() && !email.trim()) {
      showToast('Please provide company name and email', 'error');
      setLoading(false);
      return;
    }

    if (!company.trim()) {
      showToast('Please provide company name', 'error');
      setLoading(false);
      return;
    }

    if (!email.trim()) {
      showToast('Please provide email', 'error');
      setLoading(false);
      return;
    }

    const companyRegex = /^[a-zA-Z0-9 ]+$/;
    if (!companyRegex.test(company)) {
      setShowCompanyMessage(true);
      setCompanyMessage('Company name must not contain special characters');
      setLoading(false);

      setTimeout(() => {
        setShowCompanyMessage(false);
      }, 3000);
      return;
    }

    const { isValid, message } = validateEmail(email);
    if (!isValid) {
      setShowEmailMessage(true);
      setEmailMessage(message);
      setLoading(false);

      setTimeout(() => {
        setShowEmailMessage(false);
      }, 3000); // Hide the message after 30 seconds

      return;
    }

    const response = await check_email(email, company); // Pass both email and company name
    
    if (response.status === 200) {

       // Clear localStorage before saving new data
       localStorage.clear();
       
      localStorage.setItem('company_name', company);
      localStorage.setItem('email', email);
      navigate('/email-verification');
    } else {
      // Handle the case where the email check fails
    }
  } catch (error) {
    // Handle any errors that occur during the sign-up process
  } finally {
    setLoading(false);
  }
};
