
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from '../../Components/main_navbar';
import Steps from '../../Components/company_list_tile';
import StepsCompleted from '../../Components/steps_completed';
import ReportContainer from '../../Components/report';
import Button from '../../Components/button';
import { useNavigate } from 'react-router-dom';

const CompanyDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const stepKeys = [
    'COMPANY_DETAILS_DESCRIPTION_COMPLETED',
    'COMPANY_DETAILS_MANAGER_COMPLETED',
    'COMPANY_DETAILS_DOCUMENTS_COMPLETED'
  ];

  const [completedSteps, setCompletedSteps] = useState([]);
  const [incompleteSteps, setIncompleteSteps] = useState(stepKeys);
  const [loading, setLoading] = useState(false);

  // Navigation blocking setup
  useEffect(() => {
    // Disable back button in browser
    window.location.hash = "no-back-button";
    window.location.hash = "Again-No-back-button"; // Chrome
    window.onhashchange = function() {
      window.location.hash = "no-back-button";
    };

    // Force push new states
    const pushStates = () => {
      window.history.pushState(null, null, window.location.href);
      window.history.pushState(null, null, window.location.href);
      window.history.pushState(null, null, window.location.href);
      window.history.pushState(null, null, window.location.href);
    };
    pushStates();

    // Add aggressive popstate prevention
    const preventBack = () => {
      pushStates();
      window.history.forward();
      window.history.go(1);
    };

    // Multiple event listeners for maximum coverage
    window.addEventListener('popstate', preventBack);
    window.addEventListener('hashchange', preventBack);
    window.addEventListener('beforeunload', pushStates);

    // Set interval to continuously check and prevent back navigation
    const intervalId = setInterval(pushStates, 500);

    return () => {
      window.removeEventListener('popstate', preventBack);
      window.removeEventListener('hashchange', preventBack);
      window.removeEventListener('beforeunload', pushStates);
      clearInterval(intervalId);
    };
  }, []);

  // Local storage functions
  const saveCompletedStepsToLocalStorage = (completedSteps) => {
    localStorage.setItem('completedSteps', JSON.stringify(completedSteps));
  };

  // Initialize steps from localStorage
  useEffect(() => {
    const completedFromStorage = [];

    // Check individual step completions
    stepKeys.forEach((key) => {
      const isStepCompleted = localStorage.getItem(key) === 'true';
      if (isStepCompleted) {
        completedFromStorage.push(key);
      }
    });

    // Get array of completed steps
    const completedArrayFromStorage = JSON.parse(localStorage.getItem('completedSteps')) || [];
    
    // Merge both sources of completed steps
    const allCompletedSteps = [...new Set([...completedFromStorage, ...completedArrayFromStorage])];

    // Update state based on stored data
    const newCompletedSteps = [];
    const newIncompleteSteps = [...stepKeys];

    stepKeys.forEach((key) => {
      if (allCompletedSteps.includes(key)) {
        newCompletedSteps.push(key);
        const index = newIncompleteSteps.indexOf(key);
        if (index > -1) {
          newIncompleteSteps.splice(index, 1);
        }
      }
    });

    setCompletedSteps(newCompletedSteps);
    setIncompleteSteps(newIncompleteSteps);

    return () => {
      setCompletedSteps([]);
      setIncompleteSteps(stepKeys);
    };
  }, [location]);

  // Step completion handler
  const completeStep = (stepKey) => {
    if (completedSteps.includes(stepKey)) return;

    const newCompletedSteps = [...completedSteps, stepKey];
    const newIncompleteSteps = incompleteSteps.filter((step) => step !== stepKey);

    setCompletedSteps(newCompletedSteps);
    setIncompleteSteps(newIncompleteSteps);

    localStorage.setItem(stepKey, 'true');
    saveCompletedStepsToLocalStorage(newCompletedSteps);
  };

  const stepMap = {
    'COMPANY_DETAILS_DESCRIPTION_COMPLETED': { number: '1', text: 'Add Company Description', destination: '/address' },
    'COMPANY_DETAILS_MANAGER_COMPLETED': { number: '2', text: 'Add Account Manager', destination: '/account_manager' },
    'COMPANY_DETAILS_DOCUMENTS_COMPLETED': { number: '3', text: 'Add Company Documents', destination: '/company_docs' }
  };

  // Step enablement logic
  const isStepEnabled = (step, index) => {
    if (index === 0) return true;
    const previousStep = stepKeys[index - 1];
    return completedSteps.includes(previousStep);
  };

  // Navigation handlers
  const handleNextClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/home');
    }, 2000);
  };

  const handleArrowClick = () => {
    if (window.confirm('Are you sure you want to leave? You may lose unsaved changes.')) {
      navigate('/login');
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins font-normal">
      <Sidebar />
      <div className="absolute  left-4 cursor-pointer lg:top-10 top-6" onClick={handleArrowClick}>
        <img src="/Assets/arrow_back.svg" alt="Back" className="w-5 h-5 " />
      </div>
      <div className="flex items-center justify-between lg:w-4/5  4k:w-[88%]  w-full  mt-4 md:mt-[40px] ">
        <div className="flex-grow flex justify-center ml-7 text-xs"> 
          <StepsCompleted completedSteps={completedSteps.length} />
        </div>
        <div className='mr-16 lg:mr-0'>
        <ReportContainer />
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="flex flex-col mx-4 items-center mt-10 lg:w-4/5 4k:w-[88%] lg:ml-7  overflow-auto">
        <p className="text-[#232323] font-semibold text-center text-[14px] ">Complete your profile to get verified.</p>
        <div className="w-full mt-10">
          <p className="text-[#232323] font-semibold text-center text-[12px] lg:text-[14px] ">
            Please keep the following documents ready to complete the onboarding process:
          </p>
          <div className="flex justify-center mt-4 space-x-2">
            <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-md shadow-sm">
              <img src="/Assets/pdf.svg" alt="Business License" className="w-5 h-5" />
              <span className="lg:text-sm text-[10px]">Business License</span>
            </div>
            <div className="flex items-center space-x-2 bg-gray-100 p-1 rounded-md shadow-sm">
              <img src="/Assets/pdf.svg" alt="Tenancy Contract" className="w-5 h-5" />
              <span className="lg:text-sm text-[10px]">Tenancy Contract</span>
            </div>
            <div className="flex items-center space-x-2 bg-gray-100 p-1  rounded-md shadow-sm">
              <img src="/Assets/pdf.svg" alt="Trading Register" className="w-5 h-5" />
              <span className="lg:text-sm text-[10px]">Trading Register</span>
            </div>
          </div>
        </div>

        <div className="max-w-lg w-full mt-10 text-[#232323]">
          {incompleteSteps.map((step, index) => (
            <Steps 
              key={index} 
              {...stepMap[step]} 
              isEnabled={isStepEnabled(step, index)} // Enable based on the completion of the previous step
              onComplete={() => completeStep(step)} // Complete the step
            />
          ))}
          {completedSteps.length > 0 && (
            <div className="mt-4">
              <p className="text-left text-[#232323]">Completed</p>
              {completedSteps.map((step, index) => (
                <div key={index} className="flex justify-between items-center font-normal mt-4">
                  <p className="text-left">{stepMap[step].text}</p>
                  <img src="/Assets/steps_tick.svg" alt="Completed" className="w-6 h-6 ml-2" />
                </div>
              ))}
            </div>
          )}

          {/* Render the "Next" button after all steps are completed */}
          {completedSteps.length === stepKeys.length && (
            <div className="mt-16">
              <Button 
                text="LAUNCH"
                backgroundColor="bg-[#DB0000]"
                textColor="text-white"
                borderColor="border-transparent"
                navigateTo={null} // No navigation on click, just loading
               
                loading={loading} // Pass the loading state
                onClick={handleNextClick} // Handle button click to show loading
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;

