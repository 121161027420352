
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getVerificationImageByCompany, deleteVerificationImage } from '../../Controller/verification';
import DeletePopup from './delete_pop_up';
import { FaSpinner } from 'react-icons/fa'; // Import the spinner

const CompanyPhotos = () => {
  const navigate = useNavigate();
  const { uid } = useParams(); 
  const [photos, setPhotos] = useState([]);

  const [showDeleteOption, setShowDeleteOption] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);
  const [isMyProfile, setIsMyProfile] = useState(false); 
  const [verificationStatus, setVerificationStatus] = useState(''); 
  const [loading, setLoading] = useState(true); // State to handle loading spinner


  useEffect(() => {
    const fetchPhotos = async (companyUid) => {
      try {
        setLoading(true); // Start loading
        const response = await getVerificationImageByCompany(companyUid); 
        console.log('Response:', response);
        
         setPhotos(response.data); 

      } catch (error) {
        console.error('Error fetching photos:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    const checkAndFetchProfilePhotos = async () => {
      const company_uid = localStorage.getItem('company_uid'); 
      const status = localStorage.getItem('verificationStatus'); 
      setVerificationStatus(status); 

      if (uid) {
        console.log(`Fetching photos for another profile (uid: ${uid})`);
        setIsMyProfile(false); 
        fetchPhotos(uid); 
      } else if (company_uid) {
        console.log('Fetching photos for my profile');
        setIsMyProfile(true); 
        fetchPhotos(company_uid); 
      } else {
        console.error('No uid in URL and company_uid not found in localStorage');
        setLoading(false); // Stop loading if there's an error
      }
    };

    checkAndFetchProfilePhotos(); 
  }, [uid]); 

  const handleDotClick = (index) => {
    setShowDeleteOption(index === showDeleteOption ? null : index);
  };

  const handleDeleteClick = (id) => {
    setSelectedPhotoId(id);
    setShowPopup(true);
  };

  // const handleUploadClick = () => {
  //   navigate('/company_photos');
  // };
  const handleUploadClick = () => {
    console.log('Navigating with photos:', photos);
    navigate('/company_photos', {
      state: {
        photos: photos
      }
    });
  };
  const handleConfirmDelete = async () => {
    try {
      const response = await deleteVerificationImage(selectedPhotoId);
      console.log('Delete Response:', response);
      setPhotos((prevPhotos) => prevPhotos.filter(photo => photo.id !== selectedPhotoId));
      setShowPopup(false);
      setShowDeleteOption(null);
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedPhotoId(null);
  };

  const handleClick = (photo) => {
    navigate('/company_detailed_view', { state: { selectedPhoto: photo, photos } });
  };

  return (
    <div className="container mx-auto p-4  h-1/4">
      {/* <div className="flex justify-between items-center space-x-4 mb-4">
        <h2 className="lg:text-lg  text-sm font-poppins font-semibold">Company Photos</h2>
      
        {isMyProfile && verificationStatus !== 'VERIFICATION_PENDING'  &&  verificationStatus !== 'VERIFICATION_REJECTED' && (
         
          <img
            src="/Assets/review.svg"
            alt="Upload"
            className="cursor-pointer h-5 w-5 "
            onClick={handleUploadClick}
          />
         
        )}
      </div> */}
         <div className="flex items-center mb-2">
        <h2 className="lg:text-lg text-sm font-poppins font-semibold">Company Photos</h2>
        
        {isMyProfile && verificationStatus !== 'VERIFICATION_PENDING' && verificationStatus !== 'VERIFICATION_REJECTED' && (
          <img
          src={photos.length >= 4 ? "/Assets/edit.svg" : "/Assets/review.svg"}  // Change icon based on item count
          alt={photos.length >= 4 ? "Edit" : "Upload"}
            className="absolute top-[70px]  lg:top-28 right-4 lg:right-[320px] cursor-pointer h-5 w-5"
            onClick={handleUploadClick}
          />
        )}
      </div>

      {/* Display Spinner when loading */}
      {loading ? (
        <div className="flex justify-center items-center h-64"> {/* Adjust height as needed */}
          <FaSpinner className="animate-spin text-gray-500" size={20} /> {/* Spinner styling */}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-2 lg:w-4/5 w-full " >
          {photos.length > 0 ? (
            photos.map((photo, index) => (
              <div key={index} className="relative lg:h-36 object-fit h-32 w-full">
                <img
                  src={photo.image_url}
                  alt={`Company Photo ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg cursor-pointer"
                  onClick={() => handleClick(photo)}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://via.placeholder.com/400x300';
                  }}
                />
                {isMyProfile && verificationStatus !== 'VERIFICATION_PENDING' &&  verificationStatus !== 'VERIFICATION_REJECTED' && (
                  <>
                    {/* <img
                      src="/Assets/dots.svg"
                      alt="More Options"
                      className="absolute top-2 right-2 w-3 h-3 cursor-pointer"
                      onClick={() => handleDotClick(index)}
                    /> */}
                    {showDeleteOption === index && (
                      <div
                        className="absolute top-8 right-2 bg-white text-red-600 p-2 rounded-lg shadow-md cursor-pointer"
                        onClick={() => handleDeleteClick(photo.id)}
                      >
                        Delete
                      </div>
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <div className="col-span-full flex justify-center items-center lg:h-72 h-48 lg:w-[80vw]">
            <p className="text-gray-500 ">No Photos to show !</p>
          </div>
          )}
        </div>
      )}

      {showPopup && (
        <DeletePopup
          message="Are you sure you want to delete this photo?"
          onConfirm={handleConfirmDelete}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default CompanyPhotos;
