
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import SharePopup from '../../Components/share_alert';
import VerifiedPopup from '../../Components/pop_up';
import Sidebar from '../../Components/main_navbar';
import { get_all_User_video, get_user_video_api } from '../../../Controller/upload_videos';
import { FaSpinner } from 'react-icons/fa';
import { post_company_bookmark, delete_company_bookmark } from '../../../Controller/bookmarks';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../Components/toast';
import { checkIfChatExists, createNewChat } from '../../../services/chatservice';

const Video = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const videoUrl = queryParams.get('video');
    const { state } = location;
    const selectedVideoId = state?.videoId;

    const [videos, setVideos] = useState([]);
    const [skip, setSkip] = useState(0);
    const limit = 5;
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [showVerifiedPopup, setShowVerifiedPopup] = useState(false);
    const [showReportOptions, setShowReportOptions] = useState(false);
    const [reportSelected, setReportSelected] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const videoRefs = useRef([]);
    const videoContainerRef = useRef(null);

    // Fetch the selected video if videoId is present
    useEffect(() => {
        if (id) {
            const fetchSelectedVideo = async () => {
                try {
                    const fetchedVideo = await get_user_video_api(id);
                    console.log('Fetched video:', fetchedVideo);
                    setSelectedVideo(fetchedVideo);
                } catch (error) {
                    console.error('Error fetching the selected video:', error);
                }
            };
            fetchSelectedVideo();
        }
    }, [id]);

    // Fetch videos from API with pagination
    const fetchVideos = async (skipCount) => {
        if (skipCount === 0) {
            setLoading(true);
        } else {
            setLoadingMore(true);
        }

        try {
            const res = await get_all_User_video(skipCount, limit);
            console.log('API Response:', res);
            if (res.status === 200 && res.data.data.length > 0) {
                const newVideos = res.data.data;
                setVideos((prevVideos) => [...prevVideos, ...newVideos]);

                if (newVideos.length < limit) {
                    setHasMore(false);
                }
                setSkip((prevSkip) => prevSkip + limit);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching videos:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    // Initial load
    useEffect(() => {
        fetchVideos(0);
    }, []);

    // Set up the intersection observer for pagination
    const observer = useRef();
    const lastVideoElementRef = useCallback(
        (node) => {
            if (loadingMore || !hasMore) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    fetchVideos(skip);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loadingMore, hasMore, skip]
    );

    // Intersection Observer for auto-playing videos
    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const videoElement = entry.target;
                if (entry.isIntersecting) {
                    videoElement.play();
                } else {
                    videoElement.pause();
                }
            });
        }, observerOptions);

        videoRefs.current.forEach((video) => {
            if (video) observer.observe(video);
        });

        return () => {
            videoRefs.current.forEach((video) => {
                if (video) observer.unobserve(video);
            });
        };
    }, [videos]);

    const handleBookmarkClick = async (video, index) => {
        try {
            if (video.bookmark) {
                const response = await delete_company_bookmark(video.company_uid);
                if (response.status === 202) {
                    updateVideoBookmark(index, false);
                } else {
                    console.error('Failed to delete the bookmark.');
                }
            } else {
                const response = await post_company_bookmark(video.company_uid);
                if (response.status === 201) {
                    updateVideoBookmark(index, true);
                } else {
                    console.error('Failed to create the bookmark.');
                }
            }
        } catch (error) {
            console.error('Error updating bookmark:', error);
        }
    };

    const updateVideoBookmark = (index, isBookmarked) => {
        if (index === 0 && selectedVideo) {
            setSelectedVideo({ ...selectedVideo, bookmark: isBookmarked });
        }

        setVideos((prevVideos) => {
            const updatedVideos = [...prevVideos];
            updatedVideos[index] = { ...updatedVideos[index], bookmark: isBookmarked };
            return updatedVideos;
        });
    };

    const togglePlayPause = (videoRef) => {
        if (videoRef.paused) {
            videoRef.play();
        } else {
            videoRef.pause();
        }
    };

    const toggleDescription = (videoId) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [videoId]: !prevState[videoId],
        }));
    };

    const handleShareClick = async (company_uid, company_name, logo, company_id) => {
        if (!company_uid) {
            console.error("No company UID provided for sharing.");
            return;
        }
        console.log("Handle share click parameters:", company_uid, company_name, logo, company_id);

        try {
            const chatExists = await checkIfChatExists(company_uid);
            if (chatExists) {
                console.log('Navigating to existing chat:', chatExists.chatId);
                navigate(`/direct_message/chat/${chatExists.chatId}`);
            } else {
                const newChatId = await createNewChat(company_uid, company_name, logo, company_id);
                if (newChatId) {
                    console.log('Navigating to new chat:', newChatId);
                    navigate(`/direct_message/chat/${newChatId}`);
                } else {
                    console.error('Failed to create a new chat.');
                }
            }
        } catch (error) {
            console.error('Error handling share click:', error);
        }
    };
    

    const handleCopyUrlToClipboard = (videoId) => {
        const videoUrl = `${window.location.origin}/video/${videoId}`;
        navigator.clipboard.writeText(videoUrl)
            .then(() => showToast('Video URL copied to clipboard!', 'success'))
            .catch((error) => console.error('Failed to copy the URL to clipboard:', error));
    };

    const handleCloseSharePopup = () => setShowSharePopup(false);
    const handleVerifiedClick = () => setShowVerifiedPopup(true);
    const handleCloseVerifiedPopup = () => setShowVerifiedPopup(false);
    const toggleReportOptions = () => setShowReportOptions(!showReportOptions);
    const handleReportClick = () => setReportSelected(!reportSelected);

    const handleProfileClick = (company_id) => {
        if (company_id) {
            navigate(`/profile/${company_id}`);
        } else {
            console.error("No company UID provided for navigation.");
        }
    };

    return (
        <div className="flex w-full h-full bg-black overflow-hidden">
            <Sidebar />
          
            <div className="flex-1 flex flex-col overflow-y-auto" ref={videoContainerRef}>
                <div className="sticky top-0 w-full bg-black z-10">
                <div className="flex items-center p-2">
    <div className="  rounded-md p-1">
        <Link to="/discover-page">
            <img
                src="/discover_new.svg"
                alt="Discover"
                className="lg:w-14 lg:h-14 w-8 h-8"
            />
        </Link>
    </div>
    <h1 className="text-white font-bold lg:text-3xl text-lg ml-4 ml-2 font-gotham">Discover</h1>
</div>

                </div>

                <div className="lg:w-[83%] w-full p-4 overflow-auto">
                    <div className="p-1 flex flex-col items-center space-y-6">
                        {videos.map((video, index) => (
                            <div
    key={index}
    className="relative w-full lg:w-[340px] 4k:w-[30%] h-[85vh]"
    ref={index === videos.length - 1 ? lastVideoElementRef : null}
>

                                <video
                                    src={video.video_url}
                                    className="rounded-lg w-full h-full object-cover"
                                    onClick={(e) => togglePlayPause(e.target)}
                                    ref={(el) => (videoRefs.current[index] = el)}
                                />
                                <div className="absolute inset-x-0 bottom-0 p-3 bg-gradient-to-t from-black to-transparent">
                                    <div className="flex items-start space-x-2">
                                        <img
                                            src={video.logo || './profile.webp'}
                                            alt="ID"
                                            className="w-7 h-7 rounded-full mt-1 cursor-pointer"
                                            onClick={() => handleProfileClick(video.company_id)}
                                        />
                                        <div className="text-white">
                                            <p className="text-lg font-normal">{video.company_name}</p>
                                            <p className="font-bold text-sm">{video.title}</p>
                                            {/* <p className="text-sm text-gray-300">
                                                {expandedDescriptions[video.id]
                                                    ? video.description
                                                    : `${video.description.substring(0, 100)}...`}
                                                <span
                                                    className="text-blue-500 cursor-pointer"
                                                    onClick={() => toggleDescription(video.id)}
                                                >
                                                    {expandedDescriptions[video.id] ? " Show less" : " Show more"}
                                                </span>
                                            </p> */}
                                            <p className="text-sm text-gray-300">
                                        {expandedDescriptions[video.id]
                                            ? video.description
                                            : `${video.description.substring(0, 100)}...`}
                                        {video.description.length > 100 && (
                                            <span
                                                className="text-blue-500 cursor-pointer"
                                                onClick={() => toggleDescription(video.id)}
                                            >
                                                {expandedDescriptions[video.id] ? " Show less" : " Show more"}
                                            </span>
                                        )}
                                    </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="absolute right-3 bottom-20 flex flex-col space-y-6">
                                    <img
                                        src={video.bookmark ? "/Assets/bookmarked_follow.svg" : "/Assets/bookmarked_unfollow.svg"}
                                        alt="Bookmark"
                                        className="w-6 h-6 cursor-pointer"
                                        onClick={() => handleBookmarkClick(video, index)}
                                    />
                                    <img
                                        src="/share.svg"
                                        alt="Share"
                                        className="w-6 h-6 cursor-pointer"
                                        onClick={() =>
                                            handleShareClick(video.company_uid, video.company_name, video.logo, video.company_id)
                                        }
                                    />
                                    <img
                                        src="/follow.svg"
                                        alt="Copy URL"
                                        className="w-6 h-6 cursor-pointer"
                                        onClick={() => handleCopyUrlToClipboard(video.id)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    {loadingMore && (
                        <div className="flex justify-center items-center py-4">
                            <FaSpinner className="animate-spin text-white" size="2em" />
                        </div>
                    )}

                    {showSharePopup && <SharePopup onClose={handleCloseSharePopup} />}
                    {showVerifiedPopup && <VerifiedPopup onClose={handleCloseVerifiedPopup} />}
                </div>
            </div>
        </div>
    );
};

export default Video;
