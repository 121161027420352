

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const categories = [
  { id: 1, key: 'logistics', smallUrl: './logisticsmobile.svg', largeUrl: './new-logistics.svg', displayName: 'Logistics' },
  { id: 2, key: 'legal', smallUrl: './legal_mobile.svg', largeUrl: './new-legal.svg', displayName: 'Legal' },
  { id: 3, key: 'finance', smallUrl: './finance_small.svg', largeUrl: './Finance.svg', displayName: 'Finance' },
  { id: 4, key: 'start_up', smallUrl: './start-up-mobile.svg', largeUrl: './new-start-up.svg', displayName: 'Start Up' },
  { id: 5, key: 'jewelry', smallUrl: './jewelry_small.svg', largeUrl: 'Assets/jewelryy.svg', displayName: 'Jewelry' },
  { id: 6, key: 'seller', smallUrl: './seller-mobile.svg', largeUrl: './new-seller.svg', displayName: 'Seller' },
  { id: 7, key: 'investor', smallUrl: './investor-mobile.svg', largeUrl: './new-investor.svg', displayName: 'Investor' },
  { id: 8, key: 'buyer', smallUrl: './buyermobile35827.svg', largeUrl: './new-buyer.svg', displayName: 'Buyer' },
  { id: 9, key: 'market_place', smallUrl: './marketplacemobile.svg', largeUrl: './new-market.svg', displayName: 'Market Place' },
  { id: 10, key: 'projects', smallUrl: './projects(2).svg', largeUrl: './new-projects.svg', displayName: 'Projects' }
];

const Hexgrid = () => {
  const [imageUrls, setImageUrls] = useState(() => {
    const initialUrls = {};
    categories.forEach(category => {
      initialUrls[category.key] = window.innerWidth <= 768 ? category.smallUrl : category.largeUrl;
    });
    return initialUrls;
  });

  const navigate = useNavigate();
  const location = useLocation();
  // const { completed } = location.state || { completed: [] };
  const { completed, formData } = location.state || { completed: [], formData: {} };

  useEffect(() => {
    const handleResize = () => {
      const newUrls = {};
      categories.forEach(category => {
        newUrls[category.key] = window.innerWidth <= 768 ? category.smallUrl : category.largeUrl;
      });
      setImageUrls(newUrls);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [completed]);

  // const handleNavigation = (id, displayName) => {
  //   localStorage.setItem('categoryId', id);  // Store the ID in localStorage
  //   navigate('/post_request', { state: { selectedTradeRole: displayName, categoryId: id } }); // Pass displayName and ID to the PostRequest page
  // };
  const handleNavigation = (id, displayName) => {
    navigate('/post_request', { state: { selectedTradeRole: displayName, categoryId: id ,formData:formData,   fromHome: false  } }); // Pass categoryId in the state
  };
  
  return (
    <div className="bg-[#FBFCF8] min-h-screen p-4 overflow-y-auto">
      <div className="ml-5 mt-4">
        <h1 className="text-2xl font-bold text-black">Select Trade Role</h1>
      </div>
      <div className="absolute top-5 right-6"></div>
      <div className="p-0 mt-10">
        <div className="hex-grid-container-wrapper">
          <div className="hex-grid-container">
            {categories.map((category) => (
              <div
                key={category.key}
                className={`hex-item ${category.key}`}
                onClick={() => handleNavigation(category.id, category.displayName)}
              >
                <img src={imageUrls[category.key]} alt={category.key} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hexgrid;
