
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../Components/main_navbar";
import Button from "../../Components/button";
import InputBox from '../../Components/input_box';
import TextArea from "../../Components/text_area";
import VideoUpload from '../../Components/video_upload';
import { post_video } from '../../../Controller/upload_videos';
import uploadThumbnailToFirebase from '../../../Controller/firebase/thumbnail_upload';
import { showToast } from '../../Components/toast';
import Modal from '../../Components/pdf_opener';

const UploadVideo = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [videos, setVideos] = useState([]);
  const [thumbnail, setThumbnail] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading status
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const navigate = useNavigate();

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleGenerateThumbnails = async (videoFile) => {
    try {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(videoFile);
      video.width = 320;
      video.height = 240;
      video.autoplay = false;
      video.muted = true;

      const canvas = document.createElement("canvas");
      canvas.width = 320;
      canvas.height = 240;
      const ctx = canvas.getContext("2d");

      await new Promise((resolve) => {
        video.addEventListener("canplaythrough", resolve);
      });
      video.play();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailBase64 = canvas.toDataURL("image/jpeg", 0.8);

      const thumbnailUrl = await uploadThumbnailToFirebase(thumbnailBase64);
      setThumbnail(thumbnailUrl);
    } catch (error) {
      console.error("Error generating thumbnails:", error);
    }
  };

  const handleNextClick = async () => {
    // Trim title and description to remove extra spaces
    const trimmedTitle = title.trim();
    const trimmedDescription = description.trim();
  
    // Check if all fields are filled
    if (videos.length === 0 || !trimmedTitle || !trimmedDescription || !thumbnail) {
      showToast('Please add the video, title, and description.','error');
      return;
    }
  
    // Check if the description has at least 30 characters
    if (trimmedDescription.length < 30) {
      showToast('Description must be at least 30 characters long.','error');
      return;
    }
  
    setLoading(true); // Set loading to true when the button is clicked
    const video_url = videos[0];
    try {
      const thumb_nail_url = thumbnail;
      const res = await post_video(trimmedTitle, trimmedDescription, video_url, thumb_nail_url);
      if (res.status === 201) {
        navigate('/uploaded_video');
      } else {
        console.error('Failed to upload video. Please try again.');
      }
    } catch (error) {
      console.error('Error during video upload:', error);
    } finally {
      setLoading(false); // Set loading to false when the upload is complete
    }
  };
  
  const handlePdfClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="flex flex-col w-full h-full  bg-[#FBFCF8] font-poppins  overflow-auto ">
      <Sidebar />
      {/* <Toast /> */}
      <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5  4k:w-[88%]">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold  sm:text-lg lg:text-xl">Upload Video</h1>
        </div>
        <img
            src="/Assets/info2.svg"
            alt="Upload"
            title='Sop Document'
            className="cursor-pointer h-8 w-8 lg:mt-0  mr-5"
            onClick={handlePdfClick} 
          />
        {/* <ReportContainer /> */}
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div className="lg:w-4/5 4k:w-[88%] md:w-3/4 lg:mt-7 mt-1 p-3">
        <div className="lg:w-2/5 lg:mx-auto md:mx-28 mx-1 rounded-lg">
          <div className="mt-2">
            <VideoUpload
              files={videos}
              setFiles={setVideos}
              onUploadComplete={handleGenerateThumbnails}
              svgImagePath="Assets/grey_plus.svg"
              bgColor=""
              borderRadius="8px"
              width="100px"
              height="140px"
            />
          </div>
          <div className="mt-4">
            <p className="font-semibold text-sm lg:text-lg">Title</p>
            <InputBox
              placeholder="Title"
              borderRadius="2px"
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="mt-4">
            <p className="font-semibold text-sm lg:text-lg">Description</p>
            <TextArea
              placeholder="Description"
              value={description}
              onChange={handleDescriptionChange}
              maxLength={150}
            />
          </div>
          <div className="mt-20">
            <Button
              text="Next"
              borderColor="border-red-600"
              backgroundColor="bg-[#DB0000]"
              textColor="text-white"
              onClick={handleNextClick}
              loading={loading} // Pass the loading state to the Button component
            />
          </div>
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="SOP Document">
        <iframe
          src="https://firebasestorage.googleapis.com/v0/b/eswaf-d4041.appspot.com/o/videoSOP%2FVideo%20-%20SOP%20.pdf?alt=media&token=22e26a0f-83c3-4cd9-9b13-79c8fea45cb0"
          title="SOP Document PDF"
          className="w-full h-full"
          frameBorder="0"
        ></iframe>
      </Modal>
        </div>
      </div>
    </div>
  );
};

export default UploadVideo;

