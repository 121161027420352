import React, { useEffect, useState } from 'react';
import Sidebar from '../Components/main_navbar';
import { getNotificationsByUser } from '../../Controller/notification';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa'; // Import the FaSpinner from react-icons

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true); // Track if more notifications are available
  const [skip, setSkip] = useState(0); // Pagination 'skip' counter
  const limit = 10; // Number of notifications to fetch in each request
  const navigate = useNavigate();

  // Fetch notifications
  const fetchNotifications = async () => {
    try {
      // Get the UID from local storage
      const uid = localStorage.getItem('company_uid');

      if (!uid) {
        console.error('Company UID not found in local storage');
        setLoading(false);
        return;
      }

      // Fetch notifications using the UID
      const res = await getNotificationsByUser(uid, skip, limit);

      console.log("API Response for Notifications:", res);

      // Check if 'data' property exists in response
      if (res?.data?.length > 0) {
        setNotifications((prev) => [...prev, ...res.data]); // Append new notifications
        setSkip((prev) => prev + limit); // Update skip for next batch
      } else {
        setHasMore(false); // No more notifications to fetch
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setLoading(false);
    }
  };

  // Load initial notifications
  useEffect(() => {
    fetchNotifications();
  }, []);

  // Infinite scrolling handler
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 100 && hasMore && !loading) {
      setLoading(true);
      fetchNotifications();
    }
  };

  // Handle navigation based on notification type
  const handleNotificationClick = (notification) => {
    const { type, id } = notification;

    switch (type) {
      case 'complaint':
        navigate(`/complaints-received`); // Navigate to complaints page
        break;
      case 'feedback':
        navigate(`/feedback/owner`); // Navigate to feedback page
        break;
      case 'review':
        navigate(`/reviews_page`); // Navigate to reviews page
        break;
      default:
        console.warn('Unknown notification type:', type);
        break;
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-[#F9F9F9] font-poppins">
      <Sidebar />
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          <h1 className="text-[#171717] font-semibold sm:text-lg lg:text-xl">
            Notifications
          </h1>
        </div>
      </div>
      <div className="border-b border-black mt-4 md:mt-[25px]"></div>
      <div 
        className="lg:w-4/5 4k:w-[88%] md:w-3/4 lg:mt-6 mt-4 p-4 overflow-auto"
        onScroll={handleScroll} // Attach scroll handler
        style={{ height: '80vh' }} // Set a fixed height for scrolling
      >
        <div className='flex justify-center items-center '>
          <div className="flex flex-col space-y-4">
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-4 bg-white shadow-md rounded-md cursor-pointer"
                  onClick={() => handleNotificationClick(notification)} // Handle click based on type
                >
                  <div className="flex items-center">
                    <img
                      src={notification.image_sender} // Display the sender's image
                      alt="Sender Logo"
                      className="w-12 h-12 rounded-full object-cover mr-4"
                    />
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold">
                        {notification.name_sender}
                      </span>
                      <span className="text-xs text-gray-600">
                        {notification.message}
                      </span>
                    </div>
                  </div>
                  <span className="text-xs text-gray-500">
                    {new Date(notification.created_on).toLocaleTimeString()} {/* Adjust time formatting as needed */}
                  </span>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center h-full">
                <p className="text-gray-500">No notifications found.</p>
              </div>
            )}
            {loading && hasMore && (
              <div className="flex justify-center items-center mt-4">
                <FaSpinner className="animate-spin text-gray-500 text-2xl" /> {/* Spinner animation */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
