import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/main_navbar';

import SettingsSidebar from '../../Components/settings';
import { useEffect } from 'react';
import { useState } from 'react';

const TermsPolicies = () => {
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const [showSettingsSidebar, setShowSettingsSidebar] = useState(true);
  const handleCloseSidebar = () => {
    setShowSidebar(false);
  };

  const handleBackClick = () => {
    navigate("/login");
  };

  const handleCloseSettingsSidebar = () => {
    setShowSettingsSidebar(false);
  };
     // Ensure the SettingsSidebar is visible when the component mounts
     useEffect(() => {
      setShowSettingsSidebar(true);
    }, []);


  return (
    <div className="flex flex-col w-full h-full bg-[#FBFCF8]">
              {!showSettingsSidebar && <Sidebar sidebarType="main" />}
              {showSettingsSidebar && <SettingsSidebar closeSettings={handleCloseSettingsSidebar} />}
      <div className="flex lg:ml-7 ml-3 mt-4 md:mt-[40px] w-4/5">
        <div className="w-full flex justify-start items-center space-x-2">
          
          <h1 className="text-[#171717]  sm:text-lg lg:text-xl font-poppins font-semibold">Terms and Conditions</h1>
        </div>
      </div>
      <div className="border-b border-black w-full mt-4 md:mt-[25px]"></div>

      <div className="flex flex-col gap-4 md:gap-[25px] p-6 items-center w-full lg:w-[83%] 4k:w-[88%] overflow-y-auto">
        {/* Section 1: Introduction */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">1. Introduction</h2>
          <p className="text-[#171717] mt-2">
            Welcome to eSwaF by eMartApp. These Terms and Conditions ("Terms") govern your use of our platform, services, and applications (collectively, the "Service"). By accessing or using the Service, you agree to comply with these Terms.
          </p>
        </div>

        {/* Section 2: Eligibility and Account Registration */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">2. Eligibility and Account Registration</h2>
          <h3 className="text-black font-semibold text-md mt-2">2.1. Eligibility</h3>
          <p className="text-[#171717] mt-2">
            You must be at least the age of majority in your jurisdiction and capable of forming a legally binding contract.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">2.2. Account Setup</h3>
          <p className="text-[#171717] mt-2">
            To access certain features, you may need to register an account. You agree to provide accurate, current, and complete information during registration and to update such information as necessary.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">2.3. Account Security</h3>
          <p className="text-[#171717] mt-2">
            You are responsible for maintaining the confidentiality of your login credentials. Notify us immediately if you suspect unauthorized use of your account.
          </p>
        </div>

        {/* Section 3: Subscription Plans and Payments */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">3. Subscription Plans and Payments</h2>
          <h3 className="text-black font-semibold text-md mt-2">3.1. Subscription Tiers</h3>
          <p className="text-[#171717] mt-2">
            We offer various subscription plans, which may include monthly, yearly, or other options. Subscription benefits and features vary depending on the tier selected.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">3.2. Fees and Billing</h3>
          <p className="text-[#171717] mt-2">
            Your subscription will automatically renew at the end of each billing cycle unless canceled in accordance with our cancellation policy. We may change our fees at any time, but any changes will be communicated to you in advance.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">3.3. Payment Methods</h3>
          <p className="text-[#171717] mt-2">
            You agree to provide and maintain accurate payment information. All payments are processed through third-party providers, and their terms may apply.
          </p>
        </div>

        {/* Section 4: User Conduct and Responsibilities */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">4. User Conduct and Responsibilities</h2>
          <h3 className="text-black font-semibold text-md mt-2">4.1. Prohibited Activities</h3>
          <p className="text-[#171717] mt-2">
            You agree not to misuse the Service, including but not limited to spamming, spreading malware, infringing intellectual property rights, or violating any applicable laws.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">4.2. Content Ownership</h3>
          <p className="text-[#171717] mt-2">
            Any content you upload, or share remains yours, but by doing so, you grant us a non-exclusive, worldwide license to use, modify, and display your content within the Service as necessary to operate and promote the platform.
          </p>
        </div>

        {/* Section 5: Data Privacy and Security */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">5. Data Privacy and Security</h2>
          <h3 className="text-black font-semibold text-md mt-2">5.1. Privacy Policy</h3>
          <p className="text-[#171717] mt-2">
            Our Privacy Policy governs how we collect, use, and share your personal data. By using the Service, you consent to the practices outlined in the Privacy Policy.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">5.2. Data Security</h3>
          <p className="text-[#171717] mt-2">
            We implement commercially reasonable measures to protect your data. However, you understand that no system is completely secure, and we cannot guarantee absolute security.
          </p>
        </div>

        {/* Section 6: Cancellations and Refunds */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">6. Cancellations and Refunds</h2>
          <h3 className="text-black font-semibold text-md mt-2">6.1. Cancellation Policy</h3>
          <p className="text-[#171717] mt-2">
            You may cancel your subscription at any time, but cancellations will take effect at the end of your current billing cycle. No prorated refunds are issued unless explicitly stated.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">6.2. Refund Requests</h3>
          <p className="text-[#171717] mt-2">
            In certain cases, refunds may be granted at our discretion, subject to our refund policy, which will be clearly communicated at the time of purchase.
          </p>
        </div>

        {/* Section 7: Intellectual Property */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">7. Intellectual Property</h2>
          <h3 className="text-black font-semibold text-md mt-2">7.1. Platform Ownership</h3>
          <p className="text-[#171717] mt-2">
            All content, software, and trademarks associated with the Service are owned by us or licensed to us. Unauthorized use is prohibited.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">7.2. Third-Party Content</h3>
          <p className="text-[#171717] mt-2">
            The Service may include links or content from third parties. We are not responsible for the availability, accuracy, or content of third-party sites or resources.
          </p>
        </div>

        {/* Section 8: Modifications to the Service or Terms */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">8. Modifications to the Service or Terms</h2>
          <h3 className="text-black font-semibold text-md mt-2">8.1. Service Changes</h3>
          <p className="text-[#171717] mt-2">
            We may add, modify, or remove features of the Service at any time. Where possible, advance notice will be provided.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">8.2. Terms Revisions</h3>
          <p className="text-[#171717] mt-2">
            We may revise these Terms periodically. Continued use of the Service constitutes your acceptance of the revised Terms. If you do not agree to the updated Terms, you must stop using the Service.
          </p>
        </div>

        {/* Section 9: Termination and Suspension */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">9. Termination and Suspension</h2>
          <h3 className="text-black font-semibold text-md mt-2">9.1. Termination by User</h3>
          <p className="text-[#171717] mt-2">
            You may stop using the Service at any time.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">9.2. Termination by Us</h3>
          <p className="text-[#171717] mt-2">
            We may suspend or terminate your access to the Service if you violate these Terms or engage in behavior that harms our platform or community.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">9.3. Effect of Termination</h3>
          <p className="text-[#171717] mt-2">
            Upon termination, your rights to use the Service will cease immediately, but certain provisions (e.g., intellectual property, indemnity, limitation of liability) will survive termination.
          </p>
        </div>

        {/* Section 10: Limitation of Liability */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">10. Limitation of Liability</h2>
          <p className="text-[#171717] mt-2">
            To the fullest extent permitted by law, eSwaF by eMartApp shall not be liable for any indirect, incidental, special, or consequential damages arising out of your use of the Service, including but not limited to data loss, lost profits, or interruptions.
          </p>
        </div>

        {/* Section 11: Governing Law and Dispute Resolution */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">11. Governing Law and Dispute Resolution</h2>
          <p className="text-[#171717] mt-2">
            These Terms are governed by the laws of [Canada]. Any disputes will be resolved through binding arbitration in accordance with [Canada-Arbitration Rules], with exceptions where courts of [Your Jurisdiction] may be involved.
          </p>
        </div>

        {/* Section 12: Contact Information */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">12. Contact Information</h2>
          <p className="text-[#171717] mt-2">
          For questions regarding these Terms or the Service, you can contact us at [support@eSwaF.com] .
          </p>
        </div>

        {/* Addendum to Terms and Conditions */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
          <h2 className="text-black font-normal font-techna text-lg ">Addendum to Terms and Conditions</h2>
          <h3 className="text-black font-semibold text-md mt-2">A. Acceptance of Terms</h3>
          <p className="text-[#171717] mt-2">
            By accessing or using the eSwaF by eMartApp mobile application ("App") or its services, you agree to comply with and be bound by these Terms and Conditions ("Terms"). If you do not agree with these Terms, you must not access or use the App.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">B. Eligibility</h3>
          <p className="text-[#171717] mt-2">
            You must be at least 18 years of age or the age of majority in your jurisdiction to use the App. By using the App, you represent and warrant that you have the legal capacity to enter into these Terms.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">C. Account Registration and Responsibilities</h3>
          <p className="text-[#171717] mt-2">
            To access certain features of the App, you may need to register for an account. You agree to provide accurate, current, and complete information during registration and to update such information as needed. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">D. Subscription and Fees</h3>
          <p className="text-[#171717] mt-2">
            eSwaF by eMartApp offers both monthly and yearly subscription plans. By subscribing, you authorize us to charge the applicable subscription fees ("Fees") to your designated payment method. The Fees and payment terms may be updated from time to time at our discretion.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">E. Free Trial</h3>
          <p className="text-[#171717] mt-2">
            We may offer a free trial period for new users. If you do not cancel your subscription before the end of the trial period, you will be automatically charged the applicable Fees.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">F. Automatic Renewal</h3>
          <p className="text-[#171717] mt-2">
            Your subscription will automatically renew at the end of each subscription period unless you cancel it before the renewal date. If you do not cancel, you authorize us to charge the renewal Fees to your payment method.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">G. Cancellation and Refunds</h3>
          <p className="text-[#171717] mt-2">
            You may cancel your subscription at any time via the App or your account settings. Upon cancellation, you will continue to have access to the App's services until the end of your current billing period. No refunds will be provided for any unused portion of your subscription.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">H. Use of the App</h3>
          <p className="text-[#171717] mt-2">
            You agree to use the App only for lawful purposes and in accordance with these Terms. You are prohibited from using the App to engage in any activity that may harm eSwaF by eMartApp, its users, or its business partners. Unauthorized use of the App may result in suspension or termination of your account.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">I. Intellectual Property Rights</h3>
          <p className="text-[#171717] mt-2">
            All content, features, and functionality on the App, including but not limited to text, graphics, logos, and software, are the intellectual property of eSwaF by eMartApp or its licensors and are protected by intellectual property laws. You are granted a limited, non-exclusive, non-transferable license to use the App for your personal or business use.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">J. Privacy Policy</h3>
          <p className="text-[#171717] mt-2">
            Your use of the App is also governed by our Privacy Policy, which details how we collect, use, and protect your personal information. By using the App, you consent to the collection and use of your information as outlined in the Privacy Policy.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">K. Modifications to the Terms</h3>
          <p className="text-[#171717] mt-2">
            eSwaF by eMartApp reserves the right to modify these Terms at any time. We will notify you of any material changes by posting the updated Terms on the App or through other communication channels. Your continued use of the App after the changes take effect constitutes your acceptance of the revised Terms.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">L. Limitation of Liability</h3>
          <p className="text-[#171717] mt-2">
            To the fullest extent permitted by law, eSwaF by eMartApp, its affiliates, and their respective officers, directors, employees, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits, revenue, or data, arising out of or in connection with your use of the App.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">M. Indemnification</h3>
          <p className="text-[#171717] mt-2">
            You agree to indemnify, defend, and hold harmless eSwaF by eMartApp and its affiliates from and against any and all claims, liabilities, damages, losses, and expenses arising out of or in connection with your use of the App or any breach of these Terms.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">N. Governing Law and Dispute Resolution</h3>
          <p className="text-[#171717] mt-2">
            These Terms are governed by and construed in accordance with the laws of the jurisdiction in which eSwaF by eMartApp is headquartered, without regard to its conflict of law principles. Any disputes arising under these Terms will be resolved through binding arbitration in accordance with the rules of [Canada-Arbitration Body].
          </p>
          <h3 className="text-black font-semibold text-md mt-2">O. Severability</h3>
          <p className="text-[#171717] mt-2">
            If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">P. Entire Agreement</h3>
          <p className="text-[#171717] mt-2">
            These Terms constitute the entire agreement between you and eSwaF by eMartApp regarding your use of the App and supersede any prior agreements or understandings, whether written or oral.
          </p>
          <h3 className="text-black font-semibold text-md mt-2">Q. Contact Information</h3>
          <p className="text-[#171717] mt-2">
          If you have any questions or concerns regarding this Privacy Policy, or if you wish to exercise your privacy rights, please contact us at: Communication & Legal Department, at  [support@eSwaF.com] .
          </p>
        </div>

        {/* Final Statements */}
        <div className="bg-[#F0F0F0] p-3 md:p-[25px] rounded-md shadow-md w-full lg:w-[65%]  4k:w-[70%]">
        <ul className="text-[#171717] mt-2 text-[14px] lg:text-[16px] list-disc list-inside">
            <li>By using our app, you acknowledge that you have read, understood, and agreed to be bound by these Terms and Conditions.</li>
            <li>These Terms and Conditions constitute the entire agreement between you and [eSwaF by eMartApp].</li>
            <li>We reserve the right to modify or update these Terms and Conditions at any time.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsPolicies;
