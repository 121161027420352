import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/main_navbar';
import ComplaintCard from '../../Components/complaint_card';
import TextArea from '../../Components/text_area';
import Button from '../../Components/button';
import InputBox from '../../Components/input_box';
import AddDocument from '../../Components/add_doc';
import ReviewImageUpload from '../../Components/review_image_add';
import LoaderSpinner from '../../Components/loader_spinner'; // Loader Spinner
import { useNavigate } from 'react-router-dom';
import { uploadComplaint, getComplaintsAgainstCompany } from '../../../Controller/complaints';
import { showToast } from '../../Components/toast';
import { Toast } from '../../Components/toast';
import ImagePopup from '../../Components/image_modal';
import uploadImage from '../../../Controller/firebase/firebase_upload_image';
import UploadDocuments from '../../../Controller/firebase/firebase_documents';
import Modal from '../../Components/pdf_opener';
import { useCallback } from 'react';
import { useRef } from 'react';
const AddComplaints = () => {
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');

  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [complaints, setComplaints] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showMobileDetail, setShowMobileDetail] = useState(false);
  const [authorizationFile, setAuthorizationFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalComplaints, setTotalComplaints] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [imagePopupVisible, setImagePopupVisible] = useState(false); // Image popup visibility
  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Image URL for detailed view
  const [reviewImages, setReviewImages] = useState([]); // For images
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  
  const company_uid = localStorage.getItem('uid'); // Retrieve company_uid from local storage
  const complaintsPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchComplaints();
  }, [currentPage]);




  const lastComplaintElementRef = useCallback(node => {
    if (loading || loadingMore) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage(prev => prev + 1);
      }
    }, { threshold: 0.8 });
    
    if (node) observer.current.observe(node);
  }, [loading, loadingMore, hasMore]);

  const fetchComplaints = async () => {
    try {
      // Set appropriate loading state
      if (currentPage === 1) {
        setLoading(true);
        setComplaints([]); // Clear complaints when fetching first page
      } else {
        setLoadingMore(true);
      }
  
      const skip = (currentPage - 1) * complaintsPerPage;
      const res = await getComplaintsAgainstCompany(company_uid, skip, complaintsPerPage, true);  // Set other_profile to true
      
      const complaintsArray = res.data.data;
      if (Array.isArray(complaintsArray)) {
        const mappedComplaints = complaintsArray.map((item) => ({
          complaint_by_name: item.complaint_by_name,
          complaint_by_company_id: item.complaint_by_company_id,
          complaint_to: item.complaint_to,
          product_name: item.product_name,
          title: item.title,
          description: item.description,
          image_url: Array.isArray(item.image_url) ? item.image_url : 
                    item.image_url ? [item.image_url] : [],
          confirmation_document: item.confirmation_document,
          created_on: new Date(item.created_on).toISOString(),
          date: formatDate(new Date(item.created_on).toISOString()),
          complaint_by_profile_image: item.complaint_by_profile_image || '/profile.webp',
        }));
  
        setComplaints(prevComplaints => {
          // If it's the first page, return only new complaints
          if (currentPage === 1) return mappedComplaints;
          
          // For subsequent pages, check for duplicates before adding
          const existingIds = new Set(prevComplaints.map(c => c.created_on));
          const uniqueNewComplaints = mappedComplaints.filter(c => !existingIds.has(c.created_on));
          return [...prevComplaints, ...uniqueNewComplaints];
        });
        
        setHasMore(complaintsArray.length === complaintsPerPage);
        setTotalComplaints(res.data.total || 0);
      } else {
        console.error('Expected an array for complaints, but got:', complaintsArray);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching complaints:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };
  
  const handleProfileClick = (complaint_by_company_id) => {
    navigate(`/profile/${complaint_by_company_id}`);
  };
  
// First, define the formatDate function at the component level
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toString() === 'Invalid Date'
    ? 'Date not available'
    : date.toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};
  const handleViewComplaint = (complaint) => {
    setSelectedComplaint(complaint);
    setShowForm(false);
    if (isMobile) {
      setShowMobileDetail(true);
    }
  };

  const handleReportComplaint = () => {
    setSelectedComplaint(null);
    setShowForm(true);
    if (isMobile) {
      setShowMobileDetail(true);
    }
  };

  const handleFileUpload = (file) => {
    setAuthorizationFile(file);
  };
 

  const handleFormSubmit = async (newComplaint) => {
    try {
      setFormSubmitting(true);
      const complaint_to = company_uid;
  
      // Upload the document if selected
      let documentUrl = '';
      if (authorizationFile) {
        try {
          documentUrl = await UploadDocuments(authorizationFile);
          console.log('Document uploaded:', documentUrl);
        } catch (error) {
          console.error('Error uploading document:', error);
          showToast('Error uploading document.', 'error');
          return;
        }
      }
  
      // Use the reviewImages state directly
      console.log('Images to be submitted:', reviewImages);
  
      // Submit the form with the uploaded URLs
      const res = await uploadComplaint(
        complaint_to,
        newComplaint.productName,
        newComplaint.title,
        newComplaint.description,
        documentUrl,
        reviewImages
      );
  
      if (res.status === 201) {
        const createdComplaint = res.data.data;
        const complaint = {
          complaint_by_name: createdComplaint.complaint_by_name,
          complaint_to_name: createdComplaint.complaint_to_name,
          product_name: createdComplaint.product_name,
          title: createdComplaint.title,
          description: createdComplaint.description,
          image_url: Array.isArray(createdComplaint.image_url) ? 
                    createdComplaint.image_url : 
                    createdComplaint.image_url ? [createdComplaint.image_url] : [],
          confirmation_document: createdComplaint.confirmation_document,
          created_on: new Date().toISOString(), // Ensure proper date format
          date: formatDate(new Date().toISOString()),
          complaint_by_profile_image: createdComplaint.complaint_by_profile_image || '/profile.webp',
          complaint_to_profile_image: createdComplaint.complaint_to_profile_image || '/profile.webp',
        };
  
        setComplaints((prevComplaints) => [complaint, ...prevComplaints]);
        setShowForm(false);
        setShowMobileDetail(false);
        setSelectedComplaint(complaint);

      } else {
        showToast('Error submitting complaint.', 'error');
      }
    } catch (error) {
      console.error('Error submitting complaint:', error);
      showToast('Error submitting complaint.', 'error');
    } finally {
      setFormSubmitting(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleBackClick = () => {
    navigate('/my_subscription');
  };

  const handleImageClick = (imageUrl) => {
    if (imageUrl) {
        setSelectedImageUrl(imageUrl); // Set the image URL for the detailed view
        setImagePopupVisible(true); // Make the image popup visible
    } else {
        showToast('Image not available.', 'error'); // Show a toast if no image is found
    }
};


const handleDocumentView = (documentUrl) => {
  if (documentUrl) {
    setSelectedDocumentUrl(documentUrl);
    setIsDocumentModalOpen(true);
  } else {
    showToast('Document is not available.', 'error');
  }
};



  return (
    <div className="flex flex-col md:flex-row w-full h-full bg-[#FBFCF8] font-poppins overflow-y-auto font-poppins">
      <Toast /> {/* Toast notifications */}
      <Sidebar />
      <div className="flex flex-col w-full">
        <div className="flex ml-7 mt-4 md:mt-[40px] w-4/5">
          <div className="w-full flex justify-start items-center space-x-2">
           
            <h1 className="text-black font-bold text-xl">Complaints</h1>
          </div>
        </div>
        <div className="border-b border-black mt-4 md:mt-[25px]"></div>
        <div className="flex flex-col md:flex-row h-full">
          {/* <div className={`${isMobile ? 'w-full' : 'md:w-1/3'} border-r border-gray-400`}> */}
          <div className={`md:w-1/3 ${isMobile ? '' : 'border-r border-gray-400'} relative h-full overflow-y-auto`}>

          <div className="flex flex-col items-center space-y-2 p-3 md:p-9 w-full h-full overflow-y-auto">
  <Button
    text="Report Your Complaint"
    backgroundColor="bg-white"
    textColor="text-red-600"
    borderColor="border-red-600"
    className="py-4 px-6 mx-0 w-full"
    onClick={handleReportComplaint}
  />

  {loading && currentPage === 1 ? (
    <LoaderSpinner visible={true} />
  ) : (
    <>
      {complaints.length > 0 ? (
        complaints.map((complaint, index) => (
          <div
            key={index}
            ref={index === complaints.length - 1 ? lastComplaintElementRef : null}
            className="w-full"
          >
            <ComplaintCard
              complaint={complaint}
              onView={handleViewComplaint}
            />
          </div>
        ))
      ) : (
        <div className="flex flex-col items-center justify-center h-[60vh] text-center w-full">
          <span className="text-black">There are No Complaints Reported</span>
        </div>
      )}
      {loadingMore && (
        <div className="w-full flex justify-center">
          <LoaderSpinner visible={true} />
        </div>
      )}
    </>
  )}
</div>

          </div>
          {selectedComplaint && !showForm && (!isMobile || (isMobile && showMobileDetail)) && (
            <div className={`${isMobile ? 'fixed inset-0 z-50 bg-[#F2F3F3] overflow-y-auto' : 'md:w-2/3 lg:w-1/3 md:ml-9'} p-7`}>
              {isMobile && (
                <button
                  onClick={() => setShowMobileDetail(false)}
                  className="absolute top-2 right-2 text-2xl"
                >
                  &times;
                </button>
              )}
                  {console.log('Selected Complaint:', selectedComplaint)}

              <div className="bg-gray-200 rounded-lg p-4 shadow-md w-full mb-4">
                <div className="flex">
                  <img
                    src={selectedComplaint.complaint_by_profile_image || selectedComplaint.reply_profile_image}
                    alt="Profile"
                    className="rounded-full w-12 h-12 mr-4"
                    onClick={() => handleProfileClick(selectedComplaint.complaint_by_company_id)}

                  />
                  <div>
                  <div
      className="font-bold cursor-pointer"
      onClick={() => handleProfileClick(selectedComplaint.complaint_by_company_id)}
    ></div>
                    <div className="text-gray-500 text-sm font-medium mt-4">{selectedComplaint.complaint_by_name
                    }</div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="font-bold text-sm">{selectedComplaint.title}</div>
                  <div className="text-gray-600 font-bold mt-2 text-sm">{selectedComplaint.product_name}</div>
                  <div className="mt-2 text-sm">{selectedComplaint.description}</div>
                  {selectedComplaint.image_url && (
      <img
        src={Array.isArray(selectedComplaint.image_url) 
          ? selectedComplaint.image_url[0] 
          : selectedComplaint.image_url}
        alt="Complaint"
        className="w-16 h-16 rounded-lg mt-4 cursor-pointer"
        onClick={() => handleImageClick(Array.isArray(selectedComplaint.image_url) 
          ? selectedComplaint.image_url[0] 
          : selectedComplaint.image_url)}
      />
    )}
    {imagePopupVisible && selectedImageUrl && (
  <ImagePopup
    imageUrl={selectedImageUrl}
    onClose={() => setImagePopupVisible(false)}
  />
)}
                  {selectedComplaint.confirmation_document && (
  <div 
    className="mt-4 flex items-center justify-between bg-gray-300 hover:bg-gray-200 p-6 rounded-lg cursor-pointer"
    onClick={() => handleDocumentView(selectedComplaint.confirmation_document)}
  >
    <div className="flex items-center">
      <img src="/Assets/pdf.svg" alt="PDF Icon" className="w-10 h-10 mr-2" />
      <span className="text-gray-600">Document</span>
    </div>
    <button className="text-red-600 underline">View</button>
  </div>
)}
{isDocumentModalOpen && (
  <Modal isOpen={isDocumentModalOpen} onClose={() => setIsDocumentModalOpen(false)}>
    <iframe
      src={selectedDocumentUrl}
      className="w-full h-full"
      title="Document Viewer"
    />
  </Modal>
)}

             </div>
              </div>
            </div>
          )}
          {showForm && (
  <div className={`${isMobile ? 'fixed inset-0 z-50 bg-[#F2F3F3] overflow-y-auto' : 'md:w-2/3 lg:w-1/3 md:ml-9 h-full'} p-7`}>
    {isMobile && (
      <button
        onClick={() => {
          setShowForm(false);
          setShowMobileDetail(false);
        }}
        className="absolute top-2 right-2 text-xl"
      >
        &times;
      </button>
    )}
 <ComplaintSubmissionForm
      onSubmit={handleFormSubmit}
      handleFileUpload={handleFileUpload}
      formSubmitting={formSubmitting}
      reviewImages={reviewImages}
      setReviewImages={setReviewImages}
      authorizationFile={authorizationFile} // Pass it here
    />

    {imagePopupVisible && selectedImageUrl && (
      <ImagePopup
        imageUrl={selectedImageUrl}
        onClose={() => setImagePopupVisible(false)}
      />
    )}
  </div>
)}

        </div>
      </div>
    </div>
  );
};

const ComplaintSubmissionForm = ({ onSubmit, handleFileUpload, formSubmitting, reviewImages, setReviewImages,authorizationFile  }) => {
  const [productName, setProductName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  
  const validateForm = () => {
  const newErrors = {};

  // Product name validation
  if (!productName.trim()) {
    newErrors.productName = 'Please enter the product or service name!';
  }

  // Complaint subject validation
  if (!title.trim()) {
    newErrors.title = 'Please enter the subject of the complaint!';
  }

  // Description validation - updated to check for a minimum of 120 characters
  if (!description.trim() || description.trim().length < 30) {
    newErrors.description = 'Description must be at least 30 characters!';
  }

  // Document validation
  if (!authorizationFile) {
    newErrors.document = 'Please upload a document!';
  }

  // Image validation
  if (reviewImages.length === 0) {
    newErrors.images = 'Please upload an image!';
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};




  const handleSubmit = async () => {
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        await onSubmit({
          productName,
          title,
          description,
        });

        // Clear form if submission was successful
        setProductName('');
        setTitle('');
        setDescription('');
        handleFileUpload(null); // Clear the file upload
        setReviewImages([]);

      } catch (error) {
        console.error('Error submitting form:', error);
        showToast('Submission failed. Please try again.', 'error');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      showToast('Please provide valid details.', 'warning');
    }
  };

  
  



  return (
    <div className="p-1 space-y-3 ">
    {/* Product Name Field */}
    <div className="mb-1">
        <label className="text-black font-medium block mb-1">Product/Service Name</label>
        <InputBox
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          placeholder="Enter the product or service name"
          maxLength={100}
          disabled={isSubmitting}
        />
        {errors.productName && <p className="text-red-600 text-xs mt-1">{errors.productName}</p>}
      </div>

      <div className="">
        <label className="text-black font-medium block mb-1">Complaint Subject</label>
        <InputBox
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter the subject of the complaint"
          maxLength={120}
          disabled={isSubmitting}
        />
        {errors.title && <p className="text-red-600 text-xs mt-1">{errors.title}</p>}
      </div>

      <div className="">
        <label className="text-black font-medium block mb-1">Description</label>
        <TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Describe the issue that you've faced"
          maxLength={200}
          disabled={isSubmitting}
          className="h-28"
        />
        {errors.description && <p className="text-red-600 text-xs mt-1">{errors.description}</p>}
      </div>

      <div className="">
        <label className="text-black font-medium block mb-1">Document</label>
        <AddDocument 
          label="Document" 
          onFileUpload={handleFileUpload} 
          accept="application/pdf"
          disabled={isSubmitting}
        />
        {errors.document && <p className="text-red-600 text-xs mt-1">{errors.document}</p>}
      </div>

      {/* Image Upload Field */}
      <div className="">
        <ReviewImageUpload
          reviewImages={reviewImages}
          setReviewImages={setReviewImages}
          svgImagePath="/Assets/grey_plus.svg"
          bgColor="#E6E6E6"
          borderRadius="8px"
          width="80px"
          height="90px"
          disabled={isSubmitting}
        />
        {errors.images && <p className="text-red-600 text-xs mt-4">{errors.images}</p>}
      </div>
    <div className='p-1'>
      <Button
        text={isSubmitting ? "" : "Submit"}
        backgroundColor="bg-[#DB0000]"
        textColor="text-white"
        borderColor="border-red-600"
        onClick={handleSubmit}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </div>
    </div>
);
};



export default AddComplaints;